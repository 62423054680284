<template>
  <v-container fluid class="mt-3">
    <a href="#test0" id="test0"></a>
    <h3 class="mb-2 mt-1">Form Asesmen</h3>
    <v-row>
      <v-col cols="12" md="8" lg="8" sm="8">
        <v-tabs
          class="mb-3"
          centered
          show-arrows
          scrollable
          @change="setTab"
          stacked
          v-model="tabKanan"
        >
          <v-tab>Asesmen&nbsp;👨‍⚕️</v-tab>
          <!-- <v-tab>Order Penunjang</v-tab> -->
          <v-tab>Non Racikan 💊</v-tab>
          <v-tab>Resep Racikan 💊💊</v-tab>
          <v-tab>Konsultasi &nbsp;<v-icon small>mdi-file</v-icon></v-tab>
          <v-tab
            >Transfer &nbsp;<v-icon small
              >mdi-invoice-text-arrow-right</v-icon
            ></v-tab
          >
          <!-- <v-tab>History Order Resep</v-tab> -->
          <!-- <v-tab>Tindakan</v-tab> -->
          <!-- <v-tab>Rekam Medis</v-tab> -->
        </v-tabs>
        <v-tabs-items v-model="tabKanan">
          <v-tab-item>
            <asesment-rawat-jalan></asesment-rawat-jalan>
          </v-tab-item>
          <!-- <v-tab-item>
            <kedokteran-gigi></kedokteran-gigi>
          </v-tab-item> -->
          <v-tab-item>
            <non-racikan></non-racikan>
          </v-tab-item>
          <v-tab-item>
            <racikan></racikan>
          </v-tab-item>
          <v-tab-item>
            <LembarKonsultasi />
          </v-tab-item>
          <v-tab-item>
            <FormTransferRawatJalan />
          </v-tab-item>
          <!-- <v-tab-item> <HistoryOrderResep/></v-tab-item> -->
          <!-- <v-tab-item> Rekam Medis</v-tab-item> -->
        </v-tabs-items>
      </v-col>
      <v-col class="pl-3" cols="12" md="4" lg="4" sm="4">
        <a href="#test5" id="test5"></a>

        <div>
          <v-tabs show-arrows scrollable v-model="tabKiri" centered>
            <v-tab>SOAP 📔</v-tab>
            <v-tab>Penunjang 🩸</v-tab>
            <v-tab>All Resep 📑</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tabKiri">
            <v-tab-item>
              <history-soap></history-soap>
            </v-tab-item>
            <v-tab-item>
              <labor></labor>
            </v-tab-item>
            <v-tab-item>
              <list-obat v-if="tabKanan == 1"></list-obat>
              <ListObatRacikan v-if="tabKanan == 2"></ListObatRacikan>
            </v-tab-item>
            <!-- <v-tab-item>
              <Tindakan/>
            </v-tab-item> -->
          </v-tabs-items>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
// import { mapActions } from 'vuex'
// import SmartWidget from '@/components/SmartWidget.vue'
// import SekalaNyeri from '../components/SekalaNyeri.vue'
// import Dialog from '@/components/Dialog'
// import Soap from '@/components/Soap'
// import Master from '../data/asesmen-keperawatan'
import ListLabor from '@/components/ListLabor'
import HistorySoap from '@/components/HistorySoap'
import AsesmentRawatJalan from '@/components/AsesmentRawatJalan'
// import Tindakan from '@/components/Tindakan'

import ListObat from '@/components/ListObat'
import ListObatRacikan from '@/components/ListObatRacikan'
import LembarKonsultasi from './rawat-jalan/LembarKonsultasi.vue'
import FormTransferRawatJalan from './rawat-jalan/FormTransferRawatJalan.vue'
import NonRacikan from '@/components/NonRacikan'
import Racikan from '@/components/Racikan'
// import HistoryOrderResep from '@/components/HistoryOrderResep'
// import KedokteranGigi from '@/components/KedokteranGigi'
// import firebase from '@/firebaseInit'
// import store from '@/store/index.js'

import {
  // eslint-disable-next-line no-unused-vars
  apiKedua,
  // eslint-disable-next-line no-unused-vars
  successMsg,
  // eslint-disable-next-line no-unused-vars
  baseUrl,
  // eslint-disable-next-line no-unused-vars
  baseUrlV2,
  // eslint-disable-next-line no-unused-vars
  errorMsg
} from '../plugins/supports'
// import { use } from 'vue/types/umd'
// import { apiKedua } from '../plugins/supports'
// import { apiKedua } from '../plugins/supports'
// import { isEmpty, jsonPrettier } from '@/plugins/supports'
export default {
  components: {
    // SmartWidget,
    // SekalaNyeri,
    // DialogConfirm: Dialog,
    // Soap,
    Labor: ListLabor,
    HistorySoap,
    ListObat,
    LembarKonsultasi,
    FormTransferRawatJalan,
    // HistoryOrderResep,
    // Tindakan,
    NonRacikan,
    Racikan,
    ListObatRacikan,
    // KedokteranGigi,
    AsesmentRawatJalan
  },
  props: {
    no_rm: {
      require: false,
      type: String,
      default: () => ''
    },
    folio_id: {
      require: false,
      type: [String, Number],
      default: () => ''
    }
  },

  mounted () {},

  data () {
    return {
      user: JSON.parse(localStorage.getItem('user')),
      item_ke: null,
      url_emr: 'http://emr.rs-syafira.com/',
      url: '',
      site: '',
      history: [],
      patient_name: '',
      tabKiri: '',
      tabKanan: '',
      search: '',
      exTekananDarah: '',
      init_json_human_body: '',
      hasil_data_human_body: [],
      human_region_json: '',
      human_region_base64: '',
      searchIcd: '',
      modelicd: '',
      icd10: []
    }
  },
  methods: {
    setTab () {
      console.log(this.tabKanan + ' TAB KE BERAPA')
      if (this.tabKanan === 1 || this.tabKanan === 2) {
        this.tabKiri = 2
      } else {
        this.tabKiri = 0
      }
    }
  }
}
</script>

<style>
.wrap {
  width: 100%;
  height: 690px;
  padding: 0;
  overflow: hidden;
}
.frame {
  width: 1280px;
  height: 926px;
  border: 0;
  -ms-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -o-transform: scale(0.8);
  -webkit-transform: scale(0.8);
  transform: scale(0.8);

  -ms-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}
.float-group {
  position: fixed;
  bottom: 100px;
  right: 40px;
  color: #fff;
  text-align: center;
  z-index: 100;
}

.float {
  position: fixed;
  bottom: 100px;
  right: 40px;
  color: #fff;
  text-align: center;
  z-index: 100;
}

.framclass {
  width: 1280px;
  height: 786px;
  border: 0;
  -ms-transform: scale(0.7);
  -moz-transform: scale(0.7);
  -o-transform: scale(0.7);
  -webkit-transform: scale(0.7);
  transform: scale(0.7);

  -ms-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

.Div1 {
  border: 1px solid #ddd;
  width: 24%;
  background-color: white;
  float: left;
  border: 2px solid #c00;
  margin-right: 5px;
  min-height: 50px;
  position: fixed;
}
</style>
