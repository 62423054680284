<template>
  <div>
    <v-card>
      <v-card-text>
        <div class="text-center">
          <div class="row">
            <div class="col-md-6">
              <button
                @click="prevPage"
                class="btn btn-primary btn-block btn-sm"
                :disabled="currentPage === 1"
              >
                Previous
              </button>
            </div>
            <div class="col-md-6">
              <button
                @click="nextPage"
                class="btn btn-primary btn-block btn-sm"
                :disabled="currentPage === totalPages"
              >
                Next
              </button>
            </div>
          </div>

          <p class="text-center mt-2">
            Page {{ currentPage }} of {{ totalPages }}
          </p>
        </div>
        <div class="list-group">
          <div
            class="list-group-item list-group-item-action flex-column align-items-start mt-1"
            v-for="itemSoap in paginatedData"
            :key="itemSoap.id_resume_rawat_jalan"
          >
            <div class="d-flex w-100 justify-content-between">
              <h6>{{ itemSoap.namaDokter }}</h6>
              <small class="primary--text"
                ><strong>{{ itemSoap.tglDibuat }}</strong></small
              >
            </div>
            <!-- {{ itemSoap.inputan_dokter.anamnesa }} -->
            <p class="mb-1">
              <b class="primary--text">S : </b>
              {{ itemSoap.inputan_dokter.anamnesa }}
            </p>
            <p class="mb-1">
              <b class="primary--text">O : </b>
              {{ itemSoap.inputan_dokter.pemeriksaan_fisik }}
            </p>
            <p class="mb-1" v-if="itemSoap.inputan_dokter">
              <b class="primary--text">A : </b>
              {{ itemSoap.inputan_dokter.diagnosa_utama || " " }}
            </p>
            <p class="mb-1">
              <b class="primary--text">P : </b>
              &nbsp;{{ itemSoap.isian_dokter?.planning }}, &nbsp;{{
                itemSoap.isian_dokter?.planning_racikan
              }}
            </p>
            <p class="mb-1">
              <b class="primary--text">Intruksi Tindak Lanjut : </b>
              {{
                isNaN(itemSoap.isian_dokter?.instruksi_tindak_lanjut?.text)
                  ? itemSoap.isian_dokter?.instruksi_tindak_lanjut?.text
                  : "-"
              }}
              {{ itemSoap.isian_dokter?.instruksi_tindak_lanjut?.value }}
            </p>
            <p class="mb-1">
              <b class="primary--text">Catatan : </b>
              {{
                isNaN(itemSoap.isian_dokter?.noted?.text)
                  ? itemSoap.isian_dokter?.noted?.text
                  : "-"
              }}
              {{ itemSoap.isian_dokter?.noted }}
            </p>
            <v-btn
              class="mt-2 btn-block"
              medium
              color="red lighten-1"
              @click="liatResume(itemSoap.folio_id)"
              dark
              >Liat Resume</v-btn
            >
            <v-btn
              class="btn-block"
              @click="copyResume(itemSoap)"
              dark
              medium
              color="blue"
              >Copy Resume</v-btn
            >
          </div>
        </div>
        <div class="text-center mt-2">
          <div class="row">
            <div class="col-md-6">
              <button
                @click="prevPage"
                class="btn btn-primary btn-block btn-sm"
                :disabled="currentPage === 1"
              >
                Previous
              </button>
            </div>
            <div class="col-md-6">
              <button
                @click="nextPage"
                class="btn btn-primary btn-block btn-sm"
                :disabled="currentPage === totalPages"
              >
                Next
              </button>
            </div>
          </div>

          <p class="text-center mt-2">
            Page {{ currentPage }} of {{ totalPages }}
          </p>
        </div>
      </v-card-text>
    </v-card>

    <v-dialog v-model="showDialogResume" max-width="980">
      <v-card>
        <v-card-title class="headline"> Resume Medis Rawat Jalan </v-card-title>
        <v-card-text>
          <div id="hasil"></div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import {
  // eslint-disable-next-line no-unused-vars
  apiKedua,
  // eslint-disable-next-line no-unused-vars
  successMsg,
  // eslint-disable-next-line no-unused-vars
  baseUrl,
  baseUrlV2,
  // eslint-disable-next-line no-unused-vars
  errorMsg
} from '../plugins/supports'
export default {
  name: 'HistorySoap',
  data () {
    return {
      url_emr: 'http://emr.rs-syafira.com/',
      firebasePasien: '',
      currentPage: 1,
      itemsPerPage: 5, // Sesuaikan jumlah item per halaman
      history: [],
      id_generate: 0,
      searchSoap: '',
      showDialogResume: '',
      permintaans_page: 1,
      permintaans_per_page: 9,
      permintaan_pagination_container: '0-0 of 0',
      permintaans_total: ''
    }
  },
  computed: {
    totalPages () {
      return Math.ceil(this.history.length / this.itemsPerPage)
    },
    paginatedData () {
      const start = (this.currentPage - 1) * this.itemsPerPage
      const end = start + this.itemsPerPage
      return this.history.slice(start, end)
    }
  },
  methods: {
    nextPage () {
      if (this.currentPage < this.totalPages) {
        this.currentPage++
      }
    },
    prevPage () {
      if (this.currentPage > 1) {
        this.currentPage--
      }
    },
    formatDate (inputDate) {
      var date = new Date(inputDate)
      if (!isNaN(date.getTime())) {
        // Months use 0 index.
        var dd = date.getDate()
        var mm = date.getMonth() + 1
        if (dd < 10) dd = '0' + dd
        if (mm < 10) mm = '0' + mm
        return dd + '/' + mm + '/' + date.getFullYear()
      }
    },
    getHistoryResume () {
      // eslint-disable-next-line no-undef
      $.get(
        baseUrlV2 + 'pasien/history-soap?no_rm=' + this.$route.query.no_rm,
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res

          if (con) {
            // this.history = results
            var history = []
            results.forEach((item, index) => {
              // eslint-disable-next-line no-undef, camelcase, no-unused-vars
              var isian_dokter = LZString.decompressFromBase64(
                item.isian_dokter
              )
              var decodeIsiandokter = JSON.parse(isian_dokter)
              // console.log(decodeIsiandokter.subjektif)

              // eslint-disable-next-line no-undef, camelcase
              var inputan_dokter = LZString.decompressFromBase64(
                item.inputan_dokter
              )

              var decodeInputanDokter = JSON.parse(inputan_dokter)

              history.push({
                id_resume_rawat_jalan: item.id_resume_rawat_jalan,
                patient_id: item.patient_id,
                no_rm: item.no_rm,
                unit: item.unit,
                namaDokter: item.namaDokter,
                tglDibuat: item.tglDibuat,
                folio_id: item.folio_id,
                registration_id: item.registration_id,
                isian_dokter: JSON.parse(decodeIsiandokter),
                inputan_dokter: JSON.parse(decodeInputanDokter)
              })

              this.history = history
            })
          } else {
            this.history = []
          }
        },
        'JSON'
      )
      // eslint-disable-next-line no-undef
      // $.post(
      //   baseUrlV2 + 'pasien/list-soap',
      //   {
      //     pg: this.permintaans_page,
      //     ppg: this.permintaans_per_page,
      //     pls: this.searchSoap,
      //     rm: this.$route.query.no_rm
      //   },
      //   (response) => {
      //     // eslint-disable-next-line no-unused-vars
      //     const { con, msg, results } = response
      //     if (con) {
      //       const pagination = results.pagination
      //       this.permintaan_pagination_container = pagination
      //       this.permintaans_total = results.total_pages
      //       this.history = results.list
      //     } else {
      //       errorMsg(msg)
      //     }
      //   },
      //   'JSON'
      // )
    },
    initPermintaanPreviosPage () {
      var currentPage = parseInt(this.permintaans_page)
      if (currentPage > 1) {
        currentPage = currentPage - 1
      } else {
        currentPage = 1
      }
      this.permintaans_page = currentPage
      this.getHistoryResume()
    },
    initPermintaanNextPage () {
      let no = 1
      var currentPage = parseInt(this.permintaans_page)
      var totalPages = parseInt(this.permintaans_total)

      console.log(currentPage)
      console.log(totalPages)
      no = currentPage + 1

      if (currentPage === totalPages) {
        no = currentPage
      }
      // $('#permintaans_page').val(no)
      this.permintaans_page = no
      this.getHistoryResume()
    },
    liatResume (e) {
      // console.log('12321321')
      this.showDialogResume = true
      // eslint-disable-next-line no-undef
      $.ajax({
        url: baseUrlV2 + 'rme/resume-view?folio_id=' + e,
        // eslint-disable-next-line no-undef
        type: 'GET',
        success: (result) => {
          // // console.log(result);
          // eslint-disable-next-line no-undef
          $('#hasil').html(result)
          // // eslint-disable-next-line no-undef
          // $('#mymodal2').modal({ show: true })
        }
      })
    },
    copyResume (RESUME) {
      // console.log(RESUME)
      this.$root.$emit('Resume', RESUME) // like this
    }
  },
  created () {
    if (this.$route.query.no_rm != null) {
      this.getHistoryResume()
    }
  }
}
</script>
<style>
.table-soap {
  border: none;
}
.v-application small {
  color: #9c27b0;
}
</style>
