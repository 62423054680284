<template>
  <v-row justify="center">
    <v-dialog
      :persistent="persistentDialog"
      v-model="showDialogSoap"
      transition="dialog-bottom-transition"
      max-width="950"
    >
      <v-card>
        <v-card-title class="headline pt-8"> {{ title }} &nbsp; </v-card-title>
        <v-card-text>
          <v-list subheader three-line>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Subjektif (Anamnesa)</v-list-item-title>
                <v-list-item-subtitle>{{ subjektif }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Objektif (Pemeriksaan Fisik)</v-list-item-title>
                <v-list-item-subtitle
                  >{{objektif}}</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Asessmen (Diagnosa Kerja)</v-list-item-title>
                <v-list-item-subtitle
                  >{{asessmen}}</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Planning (Rencana/Terapi/Tindakan/Konsul)</v-list-item-title>
                <v-list-item-subtitle
                  >{{planning}}</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            v-if="negativeButton"
            color="red darken-1"
            text
            :disabled="disabledNegativeBtn"
            @click="negativeButton"
          >
            {{ negativeBtnLabel }}
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  name: 'Soap',
  props: {
    showDialogSoap: {
      type: Boolean,
      default: () => {},
      required: false
    },
    title: {
      type: String,
      default: null,
      required: true
    },
    persistentDialog: {
      type: Boolean,
      default: true,
      required: false
    },
    positiveBtnLabel: {
      type: String,
      default: 'Simpan Billing',
      required: false
    },
    negativeBtnLabel: {
      type: String,
      default: 'Tutup',
      required: false
    },
    positiveButton: {
      type: [Function, Boolean],
      default: () => {},
      required: false
    },
    selesaiButton: {
      type: [Function, Boolean],
      default: () => {},
      required: false
    },
    negativeButton: {
      type: [Function, Boolean],
      default: () => {},
      required: false
    },
    disabledNegativeBtn: {
      type: Boolean,
      default: false,
      required: false
    },
    disabledPositiveBtn: {
      type: Boolean,
      default: false,
      required: false
    },
    disabledSelesaiBtn: {
      type: Boolean,
      default: false,
      required: false
    },
    subjektif: {
      type: String,
      default: '',
      required: false
    },
    objektif: {
      type: String,
      default: '',
      required: false
    },
    asessmen: {
      type: String,
      default: '',
      required: false
    },
    planning: {
      type: String,
      default: '',
      required: false
    }
  },
  data () {
    return {}
  }
}
</script>
