<template>
  <div>
    <v-card class="" elevation="8">
      <v-card-text>
        <div>
          <!-- {{ vmodelfisio }} -->
          <div v-if="isRehab">
            <v-alert dense shaped prominent type="warning">
              Pastikan Nama Dokter Rehabilitas Medis Sudah Sesuai dengan Benar
            </v-alert>
            <v-select
              outlined
              return-object
              item-text="text"
              item-id="id"
              v-model="vmodelfisio"
              :items="dokterfisio"
              dense
              @change="changenamadokter(vmodelfisio)"
              label="Nama Dokter Rehabilitas Medis"
            ></v-select>
          </div>

          <v-card color="teal darken-1" dark>
            <v-card-title> </v-card-title>
            <v-card-text>
              <v-alert dense shaped prominent type="error">
                <span
                  >Tanggal Pelayanan : {{ data.demografi.tgl_pelayanan }}</span
                >
              </v-alert>

              <v-row class="mt-1 p-2 mb-0">
                <v-col
                  v-for="(v, i) in master.demografi"
                  :md="v.col ? v.col : 12"
                  :lg="v.col ? v.col : 12"
                  :sm="v.col ? v.col : 12"
                  cols="12"
                  class="mt-1 mb-1 pt-2 pb-1"
                  :key="i"
                >
                  <smart-widget
                    :comp="v.widget"
                    :sync-value="(e) => (data.demografi[i] = e)"
                    :value="data.demografi[i]"
                    vclass="c-text-field"
                    :data="v.data"
                    :label="v.label ? v.label : i.replaceAll('_', ' ')"
                    :rules="[rules.required]"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card v-if="isResepAda" class="mt-2">
            <v-card-subtitle
              ><b>{{ textResep }}</b></v-card-subtitle
            >
            <v-card-text>
              <v-btn
                @click="viewResepDetail(item.folio_id)"
                v-for="(item, index) of resepKedua"
                :key="index"
                large
                color="red lighten-1 m-1"
                dark
                >{{ item.profile }} - <b>LIAT RESEP</b></v-btn
              >
            </v-card-text>
          </v-card>
          <v-card class="mt-2">
            <v-card-text>
              <table class="table tabl-bordered" width="100%">
                <thead>
                  <tr>
                    <th>Nama Anggota Keluarga</th>
                    <th>Hubungan</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(itemKeluarga, indexKeluarga) of dataKeluarga"
                    :key="indexKeluarga"
                  >
                    <td>{{ itemKeluarga.contact_name }}</td>
                    <td>{{ itemKeluarga.relation_name }}</td>
                  </tr>
                </tbody>
              </table>
            </v-card-text>
          </v-card>
          <v-card class="mt-2" v-if="isBpjs && isSepAda">
            <v-card-text>
              <table border="0" class="table table-borderless table-sm">
                <tr>
                  <td width="50%" valign="middle">
                    <img
                      src="../assets/bpjs-logo.png"
                      width="170"
                      height="28"
                      align="center"
                    />
                  </td>
                  <td
                    align="left"
                    width="50%"
                    valign="middle"
                    style="font-size: 14px"
                  >
                    SURAT ELEGIBILITAS PESERTA RS SYAFIRA
                  </td>
                </tr>
              </table>
              <table
                cellpadding="2"
                class="table table-bordeless table-sm"
                style="font-size: 12px"
              >
                <tr>
                  <td width="123px">No. SEP</td>
                  <td width="310px">: &nbsp;{{ bpjs.noSep }}</td>
                  <td width="90px"></td>
                  <td></td>
                </tr>
                <tr>
                  <td>Tgl SEP</td>
                  <td>: &nbsp;{{ bpjs.tglSep | moment("DD MMMM YYYY") }}</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>No. Kartu</td>
                  <td>:&nbsp;{{ bpjs.noKartu }}</td>
                  <td>Jenis Peserta</td>
                  <td>:&nbsp;{{ detailSep?.peserta?.jnsPeserta }}</td>
                </tr>
                <tr>
                  <td>Nama Peserta</td>
                  <td>:&nbsp;{{ bpjs.namaPeserta }}</td>
                  <td>Potensi PRB</td>
                  <td>
                    :&nbsp;<b class="text-danger" style="font-size: 18px">{{
                      kartuAnggota.results.peserta?.informasi.prolanisPRB
                    }}</b>
                  </td>
                </tr>
                <tr>
                  <td>Tgl. Lahir</td>
                  <td>
                    :&nbsp;{{
                      this.data.demografi.tanggal_lahir | moment("DD MMMM YYYY")
                    }}
                  </td>
                  <td>Jns. Rawat</td>
                  <td>:&nbsp;{{ detailSep?.jnsPelayanan }}</td>
                </tr>
                <tr>
                  <td>No. Telepon</td>
                  <td>
                    :&nbsp; {{ kartuAnggota.results.peserta?.mr.noTelepon }}
                  </td>
                  <td>Kls. Rawat</td>
                  <td>
                    :
                    {{ kartuAnggota.results.peserta?.hakKelas.keterangan }}
                  </td>
                </tr>
                <tr>
                  <td>Sub/Spesialis</td>
                  <td>:&nbsp;{{ bpjs.poli }}</td>
                  <td>Penjamin</td>
                  <td>:&nbsp;{{ detailSep?.penjamin }}</td>
                </tr>
                <tr>
                  <td>Faskes Perujuk</td>
                  <td>:&nbsp;-</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td style="vertical-align: top">Diagnosa Awal</td>
                  <td>: &nbsp;{{ bpjs.diagnosa }}</td>
                  <td colspan="2"></td>
                </tr>
                <tr>
                  <td>Catatan</td>
                  <td style="font-size: 11px">:</td>
                </tr>

              </table>
            </v-card-text>
          </v-card>
        </div>
        <div v-if="user.is_dokter == 0">
          <!-- <v-divider class="mt-3 mb-6" /> -->
          <v-row class="mt-0 mb-0">
            <v-col cols="12" md="6">
              <v-row>
                <v-col
                  v-for="(v, i) in master.pemeriksaan.input_pendaftaran[1]"
                  :md="v.col ? v.col : 12"
                  :lg="v.col ? v.col : 12"
                  :sm="v.col ? v.col : 12"
                  cols="12"
                  :class="`mt-0 mb-0 pt-0 pb-3 ${i}`"
                  :key="i"
                >
                  <smart-widget
                    :comp="v.widget"
                    :sync-value="
                      (e) => (data.pemeriksaan.input_pendaftaran[i] = e)
                    "
                    :value="data.pemeriksaan.input_pendaftaran[i]"
                    vclass="c-text-field"
                    :data="v.data"
                    :label="i.replaceAll('_', ' ')"
                    :rules="[rules.required]"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-divider class="mt-3 mb-6" />
          <a href="#test1" id="test1"></a>
          <v-row class="mt-0 mb-0">
            <v-col cols="12" md="6">
              <v-row>
                <v-col
                  v-for="(v, i) in master.pemeriksaan.input_perawat.group_1"
                  :md="v.col ? v.col : 12"
                  :lg="v.col ? v.col : 12"
                  :sm="v.col ? v.col : 12"
                  cols="12"
                  :class="`mt-0 mb-0 pt-0 pb-3 ${i}`"
                  :key="i"
                >
                  <smart-widget
                    :comp="v.widget"
                    :sync-value="(e) => (data.pemeriksaan.input_perawat[i] = e)"
                    :value="data.pemeriksaan.input_perawat[i]"
                    vclass="c-text-field"
                    :data="v.data"
                    :label="v.label ? v.label : i.replaceAll('_', ' ')"
                    :rules="[rules.required]"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="6">
              <v-row>
                <v-col
                  v-for="(v, i) in master.pemeriksaan.input_perawat.group_2"
                  :md="v.col ? v.col : 12"
                  :lg="v.col ? v.col : 12"
                  :sm="v.col ? v.col : 12"
                  cols="12"
                  :class="`mt-0 mb-0 pt-0 pb-3 ${i}`"
                  :key="i"
                >
                  <smart-widget
                    :comp="v.widget"
                    :sync-value="(e) => (data.pemeriksaan.input_perawat[i] = e)"
                    :value="data.pemeriksaan.input_perawat[i]"
                    vclass="c-text-field"
                    :data="v.data"
                    :label="i.replaceAll('_', ' ')"
                    :rules="[rules.required]"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-divider class="mt-3 mb-6" />

          <v-row class="mt-0 mb-0">
            <v-col cols="12" md="8" class="py-0">
              <div class="text-center font-weight-bold mb-4">SEKALA NYERI</div>
              <sekala-nyeri
                :sync-value="
                  (e) => (data.pemeriksaan.input_perawat.skala_nyeri = e)
                "
                :value="data.pemeriksaan.input_perawat.skala_nyeri"
              />
            </v-col>
            <v-col cols="12" md="4" class="my-auto">
              <v-row>
                <v-col
                  v-for="(v, i) in master.pemeriksaan.input_perawat.group_3"
                  :md="v.col ? v.col : 12"
                  :lg="v.col ? v.col : 12"
                  :sm="v.col ? v.col : 12"
                  cols="12"
                  :class="`mt-0 mb-0 pt-0 pb-3 ${i}`"
                  :key="i"
                >
                  <smart-widget
                    :comp="v.widget"
                    :sync-value="(e) => (data.pemeriksaan.input_perawat[i] = e)"
                    :value="data.pemeriksaan.input_perawat[i]"
                    vclass="c-text-field"
                    :data="v.data"
                    :property="v.property"
                    :label="v.label ? v.label : i.replaceAll('_', ' ')"
                    :rules="[rules.required]"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-divider class="mt-3 mb-6" />
          <div class="font-weight-bold mb-4">PENILAIAN NYERI</div>
          <v-row class="mt-0 mb-0">
            <v-col
              v-for="(v, i) in master.pemeriksaan.input_perawat.group_4"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="v.col ? v.col : 12"
              :class="`mt-0 mb-0 pt-0 pb-3 ${i}`"
              :key="i"
              cols="12"
            >
              <smart-widget
                :comp="v.widget"
                :sync-value="(e) => (data.pemeriksaan.input_perawat[i] = e)"
                :value="data.pemeriksaan.input_perawat[i]"
                vclass="c-text-field"
                :data="v.data"
                :label="v.label ? v.label : i.replaceAll('_', ' ')"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
          <v-divider class="mt-3 mb-6" />
          <div class="font-weight-bold">
            Skala FLACC Untuk Anak kurang dari 3 tahun
          </div>
          <div class="mb-4">
            <strong>0</strong> = Nyaman; <strong>1-3</strong> = Kurang nyaman;
            <strong>4-6</strong> = Nyeri sedang; <strong>7-10</strong> = Nyeri
            berat
          </div>
          <v-simple-table class="elevation-1" dense style="font-size: 10px">
            <template v-slot:default>
              <thead>
                <tr>
                  <th style="font-size: 12px" class="text-left">Pengkajian</th>
                  <th style="font-size: 12px" class="text-center">0</th>
                  <th style="font-size: 12px" class="text-center">1</th>
                  <th style="font-size: 12px" class="text-center">2</th>
                  <th style="font-size: 12px" class="text-center">Nilai</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(d, i) in master.pemeriksaan.input_perawat.group_5
                    .data"
                  :key="i"
                >
                  <td
                    class="text-capitalize"
                    style="font-size: 12px"
                    v-bind="
                      typeof d.dataCols === 'undefined'
                        ? {
                            colspan: 4,
                            class: 'text-center font-weight-bold',
                          }
                        : {}
                    "
                  >
                    {{ d.text }}
                  </td>
                  <td
                    class="text-capitalize"
                    style="font-size: 12px"
                    v-for="(dcol, j) in d.dataCols"
                    :key="j"
                  >
                    {{ dcol }}
                  </td>
                  <td>
                    <v-text-field
                      type="number"
                      dense
                      class="py-2 text-center"
                      :rules="[rules.tidakMinus, rules.tidakLebihDari]"
                      :id="'input-skala-flacc-' + i"
                      hide-details="auto"
                      @focus="$event.target.select()"
                      @input="
                        data.pemeriksaan.input_perawat.skala_flacc[d.text] =
                          $event
                      "
                      v-bind="
                        typeof d.dataCols === 'undefined'
                          ? { readonly: true, value: skorSkalaFlacc }
                          : {
                              value:
                                data.pemeriksaan.input_perawat.skala_flacc[
                                  d.text
                                ],
                            }
                      "
                    />
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-row class="mt-6 mb-0">
            <v-col
              v-for="(v, i) in master.pemeriksaan.input_perawat.group_6"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="v.col ? v.col : 12"
              :class="`mt-0 mb-0 pt-0 pb-3 ${i}`"
              :key="i"
              cols="12"
            >
              <smart-widget
                :comp="v.widget"
                :sync-value="(e) => (data.pemeriksaan.input_perawat[i] = e)"
                :value="data.pemeriksaan.input_perawat[i]"
                vclass="c-text-field"
                :data="v.data"
                :label="v.label ? v.label : i.replaceAll('_', ' ')"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
          <v-divider class="mt-3 mb-6" />
          <div class="v-div font-weight-bold mb-2">Gizi</div>
          <v-row class="mt-0 mb-0">
            <v-col
              v-for="(v, i) in master.pemeriksaan.input_perawat.group_7"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="v.col ? v.col : 12"
              :class="`mt-0 mb-0 pt-0 pb-3 ${i}`"
              :key="i"
              cols="12"
            >
              <smart-widget
                :comp="v.widget"
                :sync-value="(e) => (data.pemeriksaan.input_perawat[i] = e)"
                :value="data.pemeriksaan.input_perawat[i]"
                vclass="c-text-field"
                :data="v.data"
                :property="v.property"
                :label="v.label ? v.label : i.replaceAll('_', ' ')"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
          <v-divider class="mt-3 mb-3" />
          <div class="font-weight-bold">Resiko Jatuh (UP and GO)</div>
          <div class="mb-4" />
          <v-row>
            <v-col cols="12" md="8">
              <v-simple-table class="elevation-1">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th style="font-size: 12px" class="text-center"></th>
                      <th style="font-size: 12px" class="text-left">
                        Pengkajian
                      </th>
                      <th style="font-size: 12px" class="text-center">Ya</th>
                      <th style="font-size: 12px" class="text-center">Tidak</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>a.</td>
                      <td class="text-capitalize py-3" style="font-size: 12px">
                        Cara berjalan pasien (salah satu atau Iebih):
                        <br />1. Tidak seimbang/sempoyongan/limbung <br />2.
                        Jalan dengan menggunakan alat bantu/bantuan orang lain
                      </td>
                      <td colspan="2">
                        <v-radio-group
                          v-model="
                            data.pemeriksaan.input_perawat.resiko_jatuh.case_a
                          "
                          mandatory
                        >
                          <v-row>
                            <v-col>
                              <v-radio value="ya" />
                            </v-col>
                            <v-col>
                              <v-radio value="tidak" />
                            </v-col>
                          </v-row>
                        </v-radio-group>
                      </td>
                    </tr>
                    <tr>
                      <td>b.</td>
                      <td class="text-capitalize py-3" style="font-size: 12px">
                        Menopang saat akan duduk : tampak memegang pinggiran
                        kursi atau meja/ benda lain sebagai penopang saat akan
                        duduk
                      </td>
                      <td colspan="2">
                        <v-radio-group
                          v-model="
                            data.pemeriksaan.input_perawat.resiko_jatuh.case_b
                          "
                          mandatory
                        >
                          <v-row>
                            <v-col>
                              <v-radio value="ya" />
                            </v-col>
                            <v-col>
                              <v-radio value="tidak" />
                            </v-col>
                          </v-row>
                        </v-radio-group>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col cols="12" md="4">
              <strong>Keterangan</strong>
              <ul>
                <li>
                  a dan b tidak ditemukan:
                  <strong>Tidak beresiko</strong>
                </li>
                <li>
                  Ditemukan salah satu a atau b:
                  <strong>Resiko rendah</strong>
                </li>
                <li>Ditemukan a dan b: <strong>Resiko tinggi</strong></li>
              </ul>
            </v-col>
          </v-row>
          <v-divider class="mt-6 mb-6" />
          <v-row class="mt-0 mb-0">
            <v-col
              v-for="(v, i) in master.pemeriksaan.input_perawat.group_10"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="v.col ? v.col : 12"
              :class="`mt-0 mb-0 pt-0 pb-3 ${i}`"
              :key="i"
              cols="12"
            >
              <smart-widget
                :comp="v.widget"
                :sync-value="(e) => (data.pemeriksaan.input_perawat[i] = e)"
                :value="data.pemeriksaan.input_perawat[i]"
                vclass="c-text-field"
                :data="v.data"
                :property="v.property"
                :label="v.label ? v.label : i.replaceAll('_', ' ')"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
          <v-divider class="mt-3 mb-3" />
          <div class="font-weight-bold text-center">Masalah Keperawatan</div>
          <v-divider class="mt-3 mb-3" />
          <v-row class="mt-0 mb-0">
            <v-col
              v-for="(v, i) in master.pemeriksaan.input_perawat.group_11"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="v.col ? v.col : 12"
              :class="`mt-0 mb-0 pt-0 pb-3 ${i}`"
              :key="i"
              cols="12"
            >
              <smart-widget
                :comp="v.widget"
                :sync-value="
                  (e) =>
                    (data.pemeriksaan.input_perawat.masalah_keperawatan = e)
                "
                :value="data.pemeriksaan.input_perawat.masalah_keperawatan"
                vclass="c-text-field"
                :data="v.data"
                :property="v.property"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
          <v-divider class="mt-3 mb-3" />
          <div class="font-weight-bold text-center">Rencana Keperawatan</div>
          <v-divider class="mt-3 mb-3" />
          <v-row class="mt-0 mb-0">
            <v-col
              v-for="(v, i) in master.pemeriksaan.input_perawat.group_12"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="v.col ? v.col : 12"
              :class="`mt-0 mb-0 pt-0 pb-3 ${i}`"
              :key="i"
              cols="12"
            >
              <smart-widget
                :comp="v.widget"
                :sync-value="
                  (e) =>
                    (data.pemeriksaan.input_perawat.rencana_keperawatan = e)
                "
                :value="data.pemeriksaan.input_perawat.rencana_keperawatan"
                vclass="c-text-field"
                :data="v.data"
                :property="v.property"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
          <v-divider class="mt-3 mb-3" />
          <div class="font-weight-bold text-center">
            Perencanaan Pulang / Discharge Planning
          </div>
          <v-divider class="mt-3 mb-3" />
          <v-row class="mt-0 mb-0">
            <v-col
              v-for="(v, i) in master.kriteria_discharge_planning"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="v.col ? v.col : 12"
              cols="12"
              :class="`mt-0 mb-0 pt-0 pb-3 ${i}`"
              :key="i"
            >
              <smart-widget
                :comp="v.widget"
                :sync-value="(e) => (data.kriteria_discharge_planning[i] = e)"
                :value="data.kriteria_discharge_planning[i]"
                vclass="c-text-field"
                :data="v.data"
                :property="v.property"
                :label="v.label"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>

          <p class="mb-0 mt-0">
            <strong
              ><i
                >Bila salah satu jawaban "Ya" dari kriteria perencanaan pulang
                diatas, maka akan dilanjutkan dengan perencanaan pulang sebagai
                berikut</i
              ></strong
            >
          </p>
          <v-row class="mt-0 mb-0">
            <v-col
              v-for="(v, i) in master.kriteria_perencanaan_pulang"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="v.col ? v.col : 12"
              :class="`mt-0 mb-0 pt-0 pb-3 ${i}`"
              :key="i"
              cols="12"
            >
              <smart-widget
                :comp="v.widget"
                :sync-value="(e) => (data.kriteria_perencanaan_pulang[i] = e)"
                :value="data.kriteria_perencanaan_pulang[i]"
                vclass="c-text-field"
                :data="v.data"
                :property="v.property"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
          <v-divider class="mt-3 mb-3" />
        </div>
        <!-- // Isian dokter -->

        <div class="font-weight-bold text-center mb-3 pt-4">
          <h2>RESUME MEDIS RAWAT JALAN</h2>
        </div>
        <v-alert dense shaped prominent type="error">
          <span
            ><b>Tanggal Pelayanan :</b> {{ data.demografi.tgl_pelayanan }}</span
          >
        </v-alert>
        <v-btn color="indigo darken-3 m-1" @click="usg" large dark>Hasil USG</v-btn>
        <v-btn color="blue darken-3 m-1" @click="sehatq" large dark
          >REQUEST SEHAT Q</v-btn
        >
        <v-btn color="deep-orange darken-3" large dark>History Rawat Inap</v-btn>

        <v-card v-if="isResepAda" class="mt-2">
          <v-card-subtitle
            ><b>{{ textResep }}</b></v-card-subtitle
          >
          <v-card-text>
            <v-btn
              v-for="(item, index) of resepKedua"
              :key="index"
              large
              color="red lighten-1 m-1"
              dark
              @click="viewResepDetail(item.folio_id)"
              >{{ item.profile }} - <b>LIAT RESEP</b></v-btn
            >
          </v-card-text>
        </v-card>
        <!-- <v-row>
          <v-col cols="12" md="12">
            <v-card dark color="#ff756b">
              <v-card-title>Riwayat Alergi</v-card-title>
              <v-card-text>
                <ul>
                  <li
                    v-for="(ia, indexa) of data.pemeriksaan.input_perawat
                      .riwayat_alergi"
                    :key="indexa"
                  >
                    {{ ia.text }}
                  </li>
                </ul>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row> -->
        <v-divider class="mt-3 mb-3" />
        <!-- <iframe height="680" width="100%" :src="url" frameborder="0"></iframe> -->
        <v-row class="mt-0 mb-0">
          <v-col
            v-for="(v, i) in master.isian_dokter"
            :md="v.col ? v.col : 12"
            :lg="v.col ? v.col : 12"
            :sm="v.col ? v.col : 12"
            cols="12"
            :class="`mt-1 mb-2 pt-0 pb-3 ${i}`"
            :key="i"
          >
            <smart-widget
              :comp="v.widget"
              :sync-value="(e) => (data.isian_dokter[i] = e)"
              :value="data.isian_dokter[i]"
              vclass="c-text-field"
              :data="v.data"
              :property="v.property"
              :label="v.label ? v.label : i.replaceAll('_', ' ')"
              :rules="[rules.required]"
            />
          </v-col>
          <v-col cols="12" sm="12" lg="12" md="12">
            <!-- {{ data }} -->

            <div class="form-group">
              <label><b>Poliklinik</b></label>
              <select id="itemSelectDokter" class="form-control select2-single">
                <option value="0">Pilih Dokter</option>
              </select>
            </div>
            <v-card class="mx-auto mt-2" color="primary" dark elevation="4">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title
                    >Kontrol Ulang Ke RS Syafira :
                    {{ data.wa ? data.wa.unit_name : "" }} dr.
                    {{ data.wa ? data.wa.text : "" }}
                  </v-list-item-title>
                  <v-list-item-subtitle></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
        </v-row>
        <div v-if="user.is_dokter == 0">
          <div class="font-weight-bold text-center">Kebutuahan Edukasi</div>
          <v-divider class="mt-3 mb-3" />
          <v-row class="mt-0 mb-0">
            <v-col
              v-for="(v, i) in master.kebutuhan_edukasi"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="v.col ? v.col : 12"
              cols="12"
              :class="`mt-0 mb-0 pt-0 pb-3 ${i}`"
              :key="i"
            >
              <smart-widget
                :comp="v.widget"
                :sync-value="(e) => (data.kebutuhan_edukasi[i] = e)"
                :value="data.kebutuhan_edukasi[i]"
                vclass="c-text-field"
                :data="v.data"
                :property="v.property"
                :label="v.label ? v.label : i.replaceAll('_', ' ')"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
        </div>
        <div class="py-2"></div>
        <a href="#test4" id="test4"></a>
        <div class="form-group">
          <div
            style="z-index: 999999"
            class="float-group btn-group-vertical"
            role="group"
            v-if="!!regid"
            aria-label="Vertical button group"
          >
            <v-btn
              @click="postSave"
              :disabled="disablebuttonSimpan"
              :color="color"
              medium
              class="btn-block btn-simpan"
            >
              Simpan
            </v-btn>
            <v-btn
              @click="printBackground"
              medium
              color="orange darken-4"
              class="btn btn-block text-white"
            >
              Cetak Resume
            </v-btn>
            <v-btn
              @click="kirimResume"
              medium
              color="indigo darken-4"
              class="btn btn-block text-white"
            >
              Kirim Resume
            </v-btn>
            <button
              @click="tidakAdaResep"
              :disabled="DISABLEDBUTTONLAYANAN"
              class="btn btn-block btn-danger text-white mb-1"
            >
              Layanan Selesai
            </button>
            <v-btn class="btn-block" @click="icare" color="green" dark
              >ICARE
            </v-btn>
            &nbsp;
          </div>
        </div>
        <dialog-confirm
          :show-dialog="dialogShow"
          :negative-button="dialogActionNBtn"
          :positive-button="dialogActionPBtn"
          :disabled-negative-btn="dialogDisableNBtn"
          :disabled-positive-btn="dialogDisablePBtn"
          :progress="dialogProgress"
          :title="dialogTitle"
          :message="dialogMessage"
        />
        <Soap
          :show-dialog="dialogShowSoap"
          :negative-button="dialogActionNBtnSoap"
          :positive-button="dialogActionPBtnSoap"
          :subjektif="historySubjektif"
          :objektif="historyObjektif"
          :asessmen="historyAsessmen"
          :planning="planningAsessmen"
          :disabled-negative-btn="dialogDisableNBtnSoap"
          :disabled-positive-btn="dialogDisablePBtnSoap"
          :title="dialogTitleSoap"
        ></Soap>
        <KirimResume
          :show-dialog="dialogResume"
          :negative-button="dialogActionNBtnLKirimResume"
          :disabled-negative-btn="dialogDisableNBtnKirimResume"
          :itemUse="data"
          ref="resume"
        />
        <ICARE
          ref="icare"
          :show-dialog="dialogICARE"
          :negative-button="dialogActionNBtnLKirimICARE"
          :disabled-negative-btn="dialogDisableNBtnKirimICARE"
          :itemUse="data"
        />
        <RESEPCEK
          ref="resepcek"
          :show-dialog="dialogResep"
          :negative-button="dialogActionNBtnLResep"
          :disabled-negative-btn="dialogDisableNBtnResep"
          :itemUse="data"
        />
        <USG
          :show-dialog="dialogUSG"
          :negative-button="dialogActionNBtnLKirimUsg"
          :disabled-negative-btn="dialogDisableNBtnKirimUSG"
          :itemUse="data"
          ref="hasilusg"
        />

        <v-dialog v-model="showDialogSehatq" max-width="780">
          <v-card>
            <v-card-title class="headline"> Verifikasi </v-card-title>
            <v-card-text>
              <v-btn
                color="blue"
                @click="kirimsehatq"
                dark
                class="btn btn-block btn-primary"
                >KIRIM REQUEST ITEM KE SEHAT Q</v-btn
              >
              <p class="mt-3"><b>LIST LOG ITEM SEHAT Q</b></p>
              <ul class="list-group mt-3">
                <li
                  v-for="(item, index) of listSehatq"
                  :key="index"
                  class="list-group-item"
                >
                  <div v-if="item.status == 200">
                    <span
                      v-for="(item2, index2) of item.log_response.data"
                      :key="index2"
                    >
                      {{ item2.item_code.toUpperCase() }} -
                      <b>{{ item2.note_admin }}</b>
                    </span>
                    <br />
                    <ul
                      v-for="(itemlog, indexlog) of item.log_request.items"
                      :key="indexlog"
                    >
                      <li>{{ itemlog.namaitem }}</li>
                    </ul>
                  </div>

                  <div v-if="item.status == 201">
                    <span
                      v-for="(item3, index3) of item.log_response"
                      :key="index3"
                    >
                      {{ item3 }}
                    </span>

                    <ul
                      v-for="(itemlog2, indexlog2) of item.log_request.items"
                      :key="indexlog2"
                    >
                      <li>
                        {{ itemlog2?.namaitem }} - {{ itemlog2?.item_code }}
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </v-card-text>
          </v-card>
        </v-dialog>
        <!-- <div v-html="jsonPrettier(data)"></div> -->
        <a href="#test3" id="test3"></a>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import SmartWidget from '@/components/SmartWidget.vue'
import SekalaNyeri from '../components/SekalaNyeri.vue'
import Dialog from '@/components/Dialog'
import Soap from '@/components/Soap'
import ICARE from '@/components/ICARE'
import RESEPCEK from '@/components/ResepCek'
import USG from '@/components/HasilUSG'
import KirimResume from '../components/KirimResume'
import Master from '../data/asesmen-keperawatan'
// import ListLabor from '@/components/ListLabor'
// import HistorySoap from '@/components/HistorySoap'
// import ListObat from '@/components/ListObat'
// import NonRacikan from '@/components/NonRacikan'
// import Racikan from '@/components/Racikan'
// import KedokteranGigi from '@/components/KedokteranGigi'
import firebase from '@/firebaseInit'
// import store from '@/store/index.js'

import {
  // eslint-disable-next-line no-unused-vars
  apiKedua,
  // eslint-disable-next-line no-unused-vars
  successMsg,
  // eslint-disable-next-line no-unused-vars
  baseUrl,
  // eslint-disable-next-line no-unused-vars
  baseUrlV2,
  // eslint-disable-next-line no-unused-vars
  errorMsg,
  // eslint-disable-next-line no-unused-vars
  Penunjang,
  // eslint-disable-next-line no-unused-vars
  setBtnLoading,
  // eslint-disable-next-line no-unused-vars
  resetBtnLoading
} from '../plugins/supports'
// import { use } from 'vue/types/umd'
// import { apiKedua } from '../plugins/supports'
// import { apiKedua } from '../plugins/supports'
// import { isEmpty, jsonPrettier } from '@/plugins/supports'
export default {
  components: {
    SmartWidget,
    SekalaNyeri,
    DialogConfirm: Dialog,
    // eslint-disable-next-line vue/no-unused-components
    ICARE,
    // eslint-disable-next-line vue/no-unused-components
    RESEPCEK,
    // eslint-disable-next-line vue/no-unused-components
    USG,
    Soap,
    // eslint-disable-next-line vue/no-unused-components
    KirimResume
    // Labor: ListLabor,
    // HistorySoap,
    // ListObat,
    // NonRacikan,
    // Racikan,
    // KedokteranGigi
  },
  props: {
    no_rm: {
      require: false,
      type: String,
      default: () => ''
    },
    folio_id: {
      require: false,
      type: [String, Number],
      default: () => ''
    }
  },

  data () {
    return {
      user: JSON.parse(localStorage.getItem('user')),
      item_ke: null,
      item_ke_diagnosa_utama: null,
      url_emr: 'http://emr.rs-syafira.com/',
      url: '',
      site: '',
      color: 'primary',
      listSehatq: '',
      dokterfisio: [
        { id: '719', text: 'dr.Akhmad Yahin, Sp.KFR' },
        { id: '1078', text: 'dr.Karina Yudithia Ayuningrum,Sp.KFR' }
      ],
      history: [],
      dataKeluarga: [],
      DISABLEDBUTTONLAYANAN: false,
      patient_name: '',
      isRehab: false,
      dialogResume: false,
      dialogICARE: false,
      dialogResep: false,
      vmodelfisio: [],
      cekResepData: '',
      dialogUSG: false,
      tabKiri: '',
      textResep: '',
      isResepAda: '',
      resepKedua: [],
      tabKanan: '',
      search: '',
      isBpjs: false,
      isCarabayar: false,
      isSepAda: false,
      bpjs: '',
      regid: this.$route.query.registration_id,
      exTekananDarah: '',
      showDialogSehatq: false,
      init_json_human_body: '',
      hasil_data_human_body: [],
      human_region_json: '',
      human_region_base64: '',
      searchIcd: '',
      modelicd: '',
      disablebuttonSimpan: false,
      kontrolKe: {
        value: '-',
        text: '-'
      },
      icd10: [],
      master: Master,
      pegawai: '',
      detailSep: '',
      data: {
        wa: [],
        demografi: {
          nama_pasien: '',
          no_rekam_medis: '',
          tanggal_lahir: '',
          jenis_kelamin: '',
          cara_bayar: '',
          folio_id: '',
          reg_id: '',
          sub_unit_id: '',
          tgl_pelayanan: '',
          umur: '',
          handphone: '',
          kelompok_cara_bayar: ''
        },

        menu_cara_bayar: {
          cara_bayar: ''
        },
        pemeriksaan: {
          input_pendaftaran: {
            tanggal: null,
            jam: null,
            pekerjaan: null,
            penghasilan: null,
            agama: null,
            pendidikan: null,
            bahasa: null,
            klinik: null
          },
          input_perawat: {
            tanggal: null,
            jam: null,
            subjektif: null,
            riwayat_penyakit_sebelumnya: null,
            riwayat_konsumsi_obat_sebelumnya: null,
            riwayat_alergi: null,
            suhu_axila: '',
            suhu_recital: '',
            nadi: '',
            RR: '',
            TD: '',
            skala_nyeri: '',
            penilaian_nyeri: '',
            skala_flacc: {
              wajah: 0,
              kaki: 0,
              aktifitas: 0,
              menangis: 0,
              bersuara: 0,
              'total skor': 0
            },
            // sambungan_hal_2:
            keadaan_umum: null,
            gizi: null,
            status_berat_badan: null,
            resiko_jatuh: {
              case_a: null,
              case_b: null,
              result: null
            },

            status_fungsional: null,
            status_psikologis: null,
            status_sosial: false,
            suku_budaya: null,
            masalah_keperawatan: null,
            rencana_keperawatan: null,
            perencanaan_pulang: null,
            berat_badan: null,
            tinggi_badan: null,
            imt: null
          }
        },
        isian_dokter: {
          subjektif: '',
          objektif: '',
          pemeriksaan_penunjang: '',
          planning: '',
          planning_racikan: '',
          hasil_penunjang: '',
          jam_isian_dokter: '',
          asessmen_diagnosa_kerja: '',
          diagnosa_utama: '',
          noted: '-',
          diagnosa_sekunder: '',
          kode_icd_diagnosa_utama: '',
          kode_icd_diagnosa_sekunder: '-',
          theraphy: '-',
          tindakan_prosedur: '',
          tindakan: '',
          instruksi_tindak_lanjut: '-'
        },
        inputan_dokter: {
          anamnesa: '',
          pemeriksaan_fisik: '',
          hasil_penunjang: '',
          diagnosa_utama: '',
          diagnosa_sekunder: '',
          jawaban: '-',
          planning: '',
          noted: '-',
          planning_racikan: '',
          riwayat_alergi: '-',
          theraphy: '-',
          tindakan_prosedur: '-',
          tindakan: '-',
          kode_icd_diagnosa_utama: '-',
          kode_icd_diagnosa_sekunder: '-',
          tanggal_lahir: '-',
          cara_pulang: '-',
          instruksi_tindak_lanjut: '-'
        },
        kebutuhan_edukasi: {
          hambatan: '',
          sebutkan_hambatan: '',
          rencana_tindak_lanjut: ''
        },
        kriteria_perencanaan_pulang: {
          perawatan_diri: '',
          latihan_fisik: '',
          bantuan_medis: '',
          pemantauan_obat: '',
          perawatan_luka: '',
          pendampingan_tenaga_khusus: '',
          bantuan_melakukan: ''
        },
        kriteria_discharge_planning: {
          keterbatasan_mobilitas: '',
          perawatan_atau_pengobatan_lanjutan: '',
          bantuan_untuk_melakukan_aktifitas_sehari_sehari: '',
          umur_diatas_65_tahun: ''
        }
      },
      rules: {
        required: (value) => {
          return true
        },
        tidakMinus: (value) => {
          return true
        },
        tidakLebihDari: (value) => {
          // return true
          if (!isNaN(value)) {
            const t = parseInt(value)
            if (t <= 10) {
              return true
            } else {
              return 'Max Nilai 2'
            }
          }
          return 'Harus Angka'
        }
      },
      dialogShowSoap: false,
      dialogTitleSoap: 'SOAP',
      historySubjektif: '',
      historyObjektif: '',
      historyAsessmen: '',
      planningAsessmen: '',
      kartuAnggota: '',
      listReseponse: '',
      dialogActionNBtnSoap: () => {
        this.dialogShowSoap = false
      },
      dialogActionPBtnSoap: () => {},

      dialogDisableNBtnSoap: false,
      dialogDisableNBtnKirimResume: false,
      dialogDisableNBtnKirimICARE: false,
      dialogDisableNBtnResep: false,
      dialogDisableNBtnKirimUSG: false,

      dialogActionNBtnLKirimResume: () => {
        this.dialogResume = false
      },
      dialogActionNBtnLKirimICARE: () => {
        this.dialogICARE = false
      },
      dialogActionNBtnLResep: () => {
        this.dialogResep = false
      },
      dialogActionNBtnLKirimUsg: () => {
        this.dialogUSG = false
      },
      dialogDisablePBtnSoap: false,

      dialogShow: false,
      dialogTitle: 'Simpan',
      dialogMessage: 'Simpan Form Sekarang?',
      dialogProgress: false,
      dialogDisableNBtn: false,
      dialogDisablePBtn: false,
      dialogActionNBtn: () => {
        this.dialogShow = false
      },

      dialogActionPBtn: () => this.postSave()
    }
  },

  created () {
    this.pegawai = JSON.parse(localStorage.getItem('user'))
  },
  mounted () {
    if (this.$route.query.no_rm != null) {
      this.getAssemen()
      this.getDataPasien(this.$route.query.no_rm)
      this.checkPanggilan()
      this.checkResep()
      this.listkirimsehatq()
      this.cekunitfisio()
      setTimeout(() => {
        this.getDataBpjsSep()
      }, 1000)
      this.anggotaKeluarga(this.$route.query.no_rm)
    }

    this.selectDokter()
    // this.init()
    this.$root.$on('AsesmentRawatJalan', (item) => {
      // console.log(item + 'Dari Component Lain')
      this.addContactTerapi(item)
      // setTimeout(() => {
      //   this.postSaveBackground()
      // }, 1000)
    })
    this.$root.$on('Resume', (item) => {
      console.log(item.isian_dokter + 'Dari Component Lain')
      this.CopyResume(item)
      // this.addContactTerapi(item)
      // setTimeout(() => {
      //   this.postSaveBackground()
      // }, 1000)
    })

    this.$root.$on('COPYRESEPNONRACIKAN', (item) => {
      this.copyResepNonRacikan(item)
    })
    this.$root.$on('COPYRESEPRACIKAN', (item) => {
      this.copyResepRacikan(item)
    })

    window.anu = this
  },
  methods: {
    changenamadokter (RESULTS) {
      console.info(RESULTS)
      // eslint-disable-next-line no-undef
      Swal.fire({
        title: 'Perhatian! Apakah Anda Yakin?',
        text: 'Ingin Merubah Nama Dokter Rehabalis Medis.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya, Simpan Rubah',
        cancelButtonText: 'Tidak Jadi Batal',
        html: ''
      }).then((result) => {
        if (result.value) {
          // eslint-disable-next-line no-undef
          $.get(
            baseUrlV2 + 'pasien/rubah',
            { id: RESULTS.id, folio_id: this.$route.query.folio_id },
            (res) => {
              // eslint-disable-next-line no-unused-vars
              const { con, msg, results } = res
              if (con) {
                successMsg(msg)
              } else {
                errorMsg(msg)
              }
            },
            'JSON'
          )
        } else {
          // this.disablebuttonSimpan = false
          // this.color = 'primary'
          // resetBtnLoading(btn, html)
          errorMsg('Nggak Jadi Deh !!!')
        }
      })
    },
    cekunitfisio () {
      // eslint-disable-next-line no-unused-vars
      var unitfisio = ['646', '663', '1065']

      var subUnitID = this.$route.query.sub_unit_id
      // console.log(subUnitID + 'sb')

      // console.log(unitfisio.includes(subUnitID) + 'nilai')
      if (unitfisio.includes(subUnitID)) {
        this.isRehab = true
        // eslint-disable-next-line no-undef
        $.get(
          baseUrlV2 + 'pasien/cek-folio-ini',
          { folio_id: this.$route.query.folio_id },
          (res) => {
            // eslint-disable-next-line no-unused-vars
            const { con, msg, results } = res
            if (con) {
              setTimeout(() => {
                this.vmodelfisio = {
                  id: results.id,
                  text: results.text
                }
              }, 100)
            }
          },
          'json'
        )
      }
    },
    parseJsonLogResponse (r) {
      this.listReseponse = r
    },

    listkirimsehatq () {
      // eslint-disable-next-line no-undef
      // $.get(
      //   baseUrlV2 + 'rme/get-list-request-tindakan',
      //   { reg_id: this.$route.query.registration_id },
      //   (res) => {
      //     // eslint-disable-next-line no-unused-vars
      //     const { con, msg, results } = res
      //     if (con) {
      //       // successMsg(msg)
      //       // this.listSehatq = results
      //       var listSehatq = []
      //       results.forEach((item, index) => {
      //         // console.log(item + ' =>>')
      //         listSehatq.push({
      //           regid: item.reg_id,
      //           no_klaim: item.no_klaim,
      //           log_request: JSON.parse(item.log_request),
      //           log_response: JSON.parse(item.log_response),
      //           status: item.status
      //         })
      //       })
      //       this.listSehatq = listSehatq
      //     } else {
      //       errorMsg(msg)
      //     }
      //   },
      //   'JSON'
      // )
    },
    kirimsehatq () {
      // eslint-disable-next-line no-undef
      $.get(
        baseUrlV2 + 'rme/kirimsehatq',
        { reg_id: this.$route.query.registration_id },
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            successMsg(msg)
            this.listkirimsehatq()
          } else {
            errorMsg(msg)
            this.listkirimsehatq()
          }
        },
        'JSON'
      )
    },
    copyResepRacikan (items) {
      var idcArray = []
      for (const item of items) {
        const icd =
          ' Nama Obat : ' + item.obat_name + ' - Signa : ' + item.dosis
        idcArray.push(icd)
      }
      var diagnosa = idcArray.join(',')
      // console.log(diagnosa)
      // this.data.isian_dokter.planning = '12312321'
      var data = this.data.isian_dokter.planning_racikan
      if (typeof data !== 'undefined') {
        var brackets = data.match(/\[[^\]]*]/g)
        var appendKeSubjektifDokter = 'Racikan : ' + '[' + diagnosa + ']'
        this.item_ke = null
        if (brackets !== null) {
          brackets.forEach((item, index) => {
            if (item.includes('[')) {
              this.item_ke = index
            }
          })
          if (this.item_ke != null) {
            this.data.isian_dokter.planning_racikan =
              this.data.isian_dokter.planning_racikan.replace(
                brackets[this.item_ke],
                appendKeSubjektifDokter
              )
          } else {
            this.data.isian_dokter.planning_racikan = this.appendTechnique(
              this.data.isian_dokter.planning_racikan,
              appendKeSubjektifDokter
            )
          }
        } else {
          this.data.isian_dokter.planning_racikan = this.appendTechnique(
            this.data.isian_dokter.planning_racikan,
            appendKeSubjektifDokter
          )
        }
      } else {
        var appendKeSubjektifDokters = '[' + diagnosa + ']'

        this.data.isian_dokter.planning_racikan = appendKeSubjektifDokters
      }
      setTimeout(() => {
        this.postSaveBackground()
      }, 150)
    },
    copyResepNonRacikan (items) {
      // console.log(JSON.stringify(item) + ' RESEP RACIKAN')
      var idcArray = []
      for (const item of items) {
        const icd =
          ' Nama Obat : ' +
          item.obat_name +
          ' - Signa : ' +
          item.dosis +
          ' - Waktu Pemberian : ' +
          item.signa +
          ' - Catatan : ' +
          item.catatanKhusus
        idcArray.push(icd)
      }
      var diagnosa = idcArray.join(',')
      // console.log(diagnosa)
      // this.data.isian_dokter.planning = '12312321'
      var data = this.data.isian_dokter.planning
      if (typeof data !== 'undefined') {
        var brackets = data.match(/\[[^\]]*]/g)
        var appendKeSubjektifDokter = 'Non Racikan : ' + '[' + diagnosa + ']'
        this.item_ke = null
        if (brackets !== null) {
          brackets.forEach((item, index) => {
            if (item.includes('[')) {
              this.item_ke = index
            }
          })
          if (this.item_ke != null) {
            this.data.isian_dokter.planning =
              this.data.isian_dokter.planning.replace(
                brackets[this.item_ke],
                appendKeSubjektifDokter
              )
          } else {
            this.data.isian_dokter.planning = this.appendTechnique(
              this.data.isian_dokter.planning,
              appendKeSubjektifDokter
            )
          }
        } else {
          this.data.isian_dokter.planning = this.appendTechnique(
            this.data.isian_dokter.planning,
            appendKeSubjektifDokter
          )
        }
      } else {
        var appendKeSubjektifDokters = '[' + diagnosa + ']'

        this.data.isian_dokter.planning = appendKeSubjektifDokters
      }
      setTimeout(() => {
        this.postSaveBackground()
      }, 150)
    },
    usg () {
      this.dialogUSG = true
      this.$refs.hasilusg.getHasilUsg()
    },
    CopyResume (item) {
      console.log(item.inputan_dokter)

      this.data.inputan_dokter.anamnesa = item.inputan_dokter?.anamnesa

      this.data.isian_dokter.subjektif = item.inputan_dokter?.anamnesa

      this.data.inputan_dokter.pemeriksaan_fisik =
        item.inputan_dokter?.pemeriksaan_fisik
      this.data.isian_dokter.objektif = item.inputan_dokter?.pemeriksaan_fisik

      this.data.inputan_dokter.diagnosa_utama =
        item.inputan_dokter?.diagnosa_utama
      this.data.isian_dokter.diagnosa_utama =
        item.inputan_dokter?.diagnosa_utama

      this.data.inputan_dokter.diagnosa_utama =
        item.inputan_dokter?.diagnosa_utama
      this.data.isian_dokter.diagnosa_utama =
        item.inputan_dokter?.diagnosa_utama

      this.data.inputan_dokter.diagnosa_sekunder =
        item?.inputan_dokter?.diagnosa_sekunder
      this.data.isian_dokter.diagnosa_sekunder =
        item?.inputan_dokter?.diagnosa_sekunder
      // console.log(item.pemeriksaan)
      // setTimeout(() => {
      //   // console.log(item + 'DARI')
      //   JSON.stringify(item + 'ITEM')
      //   // this.postSaveBackground()
      // }, 1000)
    },
    addContactTerapi (item) {
      this.data.isian_dokter.planning = String(
        this.data.isian_dokter.planning
      ).concat(item)
    },
    selectDokter () {
      // eslint-disable-next-line no-undef, no-unused-vars
      var self = this
      // eslint-disable-next-line no-undef
      $('#itemSelectDokter')
        .select2({
          ajax: {
            url: baseUrlV2 + 'poliklinik/dokter',
            type: 'post',
            dataType: 'json',
            delay: 250,
            data: function (params) {
              return {
                searchTerm: params.term // search term
              }
            },
            processResults: function (response) {
              return {
                results: response
              }
            },
            cache: true
          }
        })
        .on('select2:select', function (evt) {
          // eslint-disable-next-line no-undef
          var data = $('#itemSelectDokter option:selected').text()
          // console.log(data)
          self.kontrolKe = {
            value: data,
            text: data
          }

          self.data.wa = evt.params.data

          // console.log(evt + 'data dokter')

          if (
            self.data.isian_dokter.instruksi_tindak_lanjut.text ===
            'Kontrol Ke RS Syafira, Poliklinik:'
          ) {
            self.data.isian_dokter.instruksi_tindak_lanjut.value =
              evt.params.data.unit_name
            // self.data.inputan_dokter.instruksi_tindak_lanjut.value =
            //   evt.params.data.unit_name
          }

          // eslint-disable-next-line no-undef
          // var data = $('#historyResep option:selected').text()
          // alert('Data yang dipilih adalah ' + evt)
          // eslint-disable-next-line no-undef
          // self.id_order_resep = evt.params.data.id_order_resep
          // self.isCetak = true
          // self.addObatByHistoryOrder(evt.params.data.detail)

          // console.log(evt.params.data.detail)
        })
    },
    // setIcd () {
    //   console.log(this.modelicd)
    //   // this.diaglog_icd = false
    //   const icdygDipilih = this.modelicd
    //   var idcArray = []
    //   for (const item of icdygDipilih) {
    //     idcArray.push(item.text)
    //   }

    //   // console.log(idcArray)
    //   this.data.isian_dokter.kode_icd_diagnosa_utama = idcArray.join(', ')
    //   this.data.inputan_dokter.kode_icd_diagnosa_utama = idcArray.join(', ')
    // },
    getDataPasien (v) {
      // eslint-disable-next-line camelcase
      const reg_id = this.$route.query.registration_id
      apiKedua
        // eslint-disable-next-line camelcase
        .get('emr/detail-pasien?rm=' + v + '&reg_id=' + reg_id, {})
        .then((response) => {
          if (response.data.con) {
            this.dataPatient = response.data.results
            this.data.demografi = this.dataPatient
            this.data.demografi.jenis_kelamin = {
              value: this.dataPatient.jenis_kelamin,
              text: this.dataPatient.jenis_kelamin
            }
            this.data.demografi.folio_id = this.$route.query.folio_id
            this.data.demografi.reg_id = this.$route.query.registration_id
            this.data.demografi.sub_unit_id = this.$route.query.sub_unit_id

            this.patient_name = response.data.results.nama_pasien
            const date = new Date(response.data.results.tanggal_lahir)

            const day = date.toLocaleString('default', { day: '2-digit' })
            const month = date.toLocaleString('default', { month: 'short' })
            const year = date.toLocaleString('default', { year: 'numeric' })
            // console.log(response.data.results.tanggal_lahir)
            const tglLahir = day + '/' + month + '/' + year
            this.data.demografi.umur = this.umur(tglLahir)
          }
          // localStorage.setItem('pasien', JSON.stringify(this.dataPatient))

          // this.getResumeRawatJalan(this.folioYangDipakai)
          // this.data.demografi.sub_unit_id = this.pegawai.sub_unit_id
        })
        // eslint-disable-next-line handle-callback-err
        .catch(function (error) {
          // console.log(error)
        })
    },
    umur (tglLahir) {
      var dob = new Date(tglLahir)
      var dobYear = dob.getYear()
      var dobMonth = dob.getMonth()
      var dobDate = dob.getDate()
      var yearAge = ''
      // get the current date from the system
      var now = new Date()
      // extract the year, month, and date from current date
      var currentYear = now.getYear()
      var currentMonth = now.getMonth()
      var currentDate = now.getDate()

      // declare a variable to collect the age in year, month, and days
      var age = {}
      var ageString = ''

      // get years
      yearAge = currentYear - dobYear

      // get months
      if (currentMonth >= dobMonth) {
        // get months when current month is greater
        var monthAge = currentMonth - dobMonth
      } else {
        yearAge--
        // eslint-disable-next-line no-redeclare
        var monthAge = 12 + currentMonth - dobMonth
      }

      // get days
      if (currentDate >= dobDate) {
        // get days when the current date is greater
        var dateAge = currentDate - dobDate
      } else {
        monthAge--
        // eslint-disable-next-line no-redeclare
        var dateAge = 31 + currentDate - dobDate

        if (monthAge < 0) {
          monthAge = 11
          yearAge--
        }
      }
      // group the age in a single variable
      age = {
        years: yearAge,
        months: monthAge,
        days: dateAge
      }

      if (age.years > 0 && age.months > 0 && age.days > 0) {
        ageString =
          age.years +
          ' Tahun, ' +
          age.months +
          ' Bulan, dan ' +
          age.days +
          ' Hari.'
      } else if (age.years === 0 && age.months === 0 && age.days > 0) {
        ageString = ' ' + age.days + ' Hari'
        // eslint-disable-next-line brace-style
      }
      // when current month and date is same as birth date and month
      else if (age.years > 0 && age.months === 0 && age.days === 0) {
        ageString = age.years + ' Tahun. Happy Birthday!!'
      } else if (age.years > 0 && age.months > 0 && age.days === 0) {
        ageString = age.years + ' Tahun dan ' + age.months + ' Bulan.'
      } else if (age.years === 0 && age.months > 0 && age.days > 0) {
        ageString = age.months + ' Bulan dan ' + age.days + ' Hari.'
      } else if (age.years > 0 && age.months === 0 && age.days > 0) {
        ageString = age.years + ' Tahun, dan' + age.days + ' Hari.'
      } else if (age.years === 0 && age.months > 0 && age.days === 0) {
        ageString = age.months + ' Bulan.'
        // eslint-disable-next-line brace-style
      }
      // when current date is same as dob(date of birth)
      else {
        ageString =
          age.years +
          ' Tahun, ' +
          age.months +
          ' Bulan, dan ' +
          age.days +
          ' Hari.'
      }

      // console.log(ageString)
      return ageString
    },
    icare () {
      this.dialogICARE = true

      this.$refs.icare.cariRiwayatIcare()
    },
    kirimResume () {
      this.$refs.resume.getDataLogInternal()
      this.$refs.resume.listLog()
      this.dialogResume = true
    },
    printBackground () {
      // eslint-disable-next-line no-unused-vars
      const url =
        this.url_emr +
        'cetak-with-pdf-emr-other/cetak-resume-medis-base64-print?view=base64_pdf&reg_id=' +
        this.$route.query.registration_id +
        '&folio_id=' +
        this.$route.query.folio_id
      const result = window.btoa(url)

      // eslint-disable-next-line no-undef
      $.get(
        baseUrl +
          `cronjob-background-printing/send-print-medis-by-employee-mapping?employee_id=${this.pegawai.employee_id}=&url=` +
          result,
        (response) => {
          // console.log(response)
          const { con, msg } = response
          if (con) {
            successMsg(msg)
          } else {
            errorMsg(msg)
          }
        },
        'JSON'
      )
      // console.log(result)
    },
    liatHistory (v) {
      this.dialogShowSoap = true

      // console.log(v.pemeriksaan.isian_dokter)
      this.historySubjektif = v.pemeriksaan.isian_dokter.subjektif
      this.historyObjektif = v.pemeriksaan.isian_dokter.objektif
      this.historyAsessmen = v.pemeriksaan.isian_dokter.asessmen_diagnosa_kerja
      this.planningAsessmen = v.pemeriksaan.isian_dokter.planning
    },
    cetaknya (view) {
      // console.log(this.folioYangDipakai)
      window
        .open(
          this.url_emr +
            `cetak-word-emr/cetak-asesmen?no_rm=${this.$route.query.no_rm}&folio_id=${this.$route.query.folio_id}&registration_id=${this.$route.query.registration_id}&view=` +
            view,
          '_blank'
        )
        .focus()
    },

    cetakResume (view) {
      // console.log(this.folioYangDipakai)
      window
        .open(
          this.url_emr +
            `cetak-word-emr/cetak-resume-medis?no_rm=${this.$route.query.no_rm}&folio_id=${this.$route.query.folio_id}&registration_id=${this.$route.query.registration_id}&view=` +
            view,
          '_blank'
        )
        .focus()
    },

    cetaknyaHistory (view) {
      // console.log(view)
      window
        .open(
          this.url_emr +
            `cetak-word-emr/cetak-asesmen?no_rm=${view.no_rm}&folio_id=${view.folio_id}&registration_id=${view.registration_id}&view=` +
            '',
          '_blank'
        )
        .focus()
    },
    kirimResep () {
      var data = {
        folio_id: this.$route.query.folio_id
      }
      // eslint-disable-next-line no-undef
      $.post(
        baseUrlV2 + 'pasien/pilih-resep',
        data,
        (res) => {
          // eslint-disable-next-line no-undef, no-unused-vars
          const { con, msg, results } = res

          if (con) {
            console.log(msg)
            successMsg(msg)
          } else {
            // console.log(results)
            errorMsg(msg)
          }
        },
        'JSON'
      )
    },
    cetaknyaHistoryDownload (view) {
      // console.log(view)
      window
        .open(
          this.url_emr +
            `cetak-word-emr/cetak-asesmen?no_rm=${view.no_rm}&folio_id=${view.folio_id}&registration_id=${view.registration_id}&view=` +
            'download',
          '_blank'
        )
        .focus()
    },
    hitungImt (tinggi, berat) {
      // alert('asdasda')

      // console.log(tinggi)
      // console.log(berat)

      var tb = parseFloat(tinggi / 100)
      var bb = berat
      var imt = bb / (tb * tb)
      imt = imt.toFixed(2)
      imt = isNaN(imt) ? '-' : imt

      if (imt < 17.0) {
        // Kekurangan Berat Badan Tingkat Berat
        this.data.pemeriksaan.input_perawat.status_berat_badan = {
          text: 'BB Kurang',
          value: 'BB Kurang',
          float: true
        }
      } else if (imt > 18.0 && imt < 18.5) {
        this.data.pemeriksaan.input_perawat.status_berat_badan = {
          text: 'BB Normal',
          value: 'BB Normal',
          float: true
        }
        // Kekurangan Berat Badan Tingkat Ringan
      } else if (imt > 18.5 && imt <= 25.0) {
        // Normal
        this.data.pemeriksaan.input_perawat.status_berat_badan = {
          text: 'BB Lebih',
          value: 'BB Lebih',
          float: true
        }
      } else if (imt > 25.1 && imt < 27.0) {
        // Kelebihan Berat Badan Tingkat Ringan
        this.data.pemeriksaan.input_perawat.status_berat_badan = {
          text: 'BB Lebih',
          value: 'BB Lebih',
          float: true
        }
      } else if (imt > 27.0) {
        this.data.pemeriksaan.input_perawat.status_berat_badan = {
          text: 'BB Lebih',
          value: 'BB Lebih',
          float: true
        }
        // Kelebihan Berat Badan Tingkat Berat
      } else {
        this.data.pemeriksaan.input_perawat.status_berat_badan = {
          text: '',
          value: '',
          float: true
        }
      }

      if (berat !== null && tinggi !== null) {
        // var data = 'somestring[a=0]what[b-c=twelve]----[def=one-2]test'
        var data = this.data.isian_dokter.objektif
        var brackets = data.match(/\[[^\]]*]/g)
        // console.log(brackets)
        var appendKeSubjektifDokter =
          '[ BB:' +
          berat +
          'Kg, TB:' +
          tinggi +
          'cm, IMT:' +
          imt +
          ' Status Berat Badan : ' +
          this.data.pemeriksaan.input_perawat.status_berat_badan?.value +
          ' ]'
        this.item_ke = null
        if (brackets !== null) {
          brackets.forEach((item, index) => {
            if (item.includes('[ BB')) {
              this.item_ke = index
            }
          })
          if (this.item_ke != null) {
            this.data.isian_dokter.objektif =
              this.data.isian_dokter.objektif.replace(
                brackets[this.item_ke],
                appendKeSubjektifDokter
              )
          } else {
            this.data.isian_dokter.objektif = this.appendTechnique(
              this.data.isian_dokter.objektif,
              appendKeSubjektifDokter
            )
          }
        } else {
          this.data.isian_dokter.objektif = this.appendTechnique(
            this.data.isian_dokter.objektif,
            appendKeSubjektifDokter
          )
        }
      }

      this.data.pemeriksaan.input_perawat.imt = imt.toString()
    },
    hitungTekananDarah (v) {
      // console.log(v)
      // const tekananDarah = v.split('/')
      // console.log(tekananDarah)

      if (v) {
        const tekananDarah = v.split('/')
        var sistolik = tekananDarah[0]
        var diastolik = tekananDarah[1]

        // let hasil = null
        if (sistolik < 90 || diastolik < 60) {
          this.exTekananDarah = 'Hipertensi'
        } else if (
          (sistolik >= 90 && sistolik <= 120) ||
          (diastolik >= 60 && diastolik <= 79)
        ) {
          this.exTekananDarah = 'Normal'
        } else if (
          sistolik >= 121 &&
          sistolik <= 139 &&
          diastolik >= 80 &&
          diastolik <= 89
        ) {
          this.exTekananDarah = 'Prahipertensi'
        } else if (
          (sistolik >= 121 && sistolik <= 139) ||
          (diastolik >= 80 && diastolik <= 89)
        ) {
          this.exTekananDarah = 'Hipertensi Tahap 1'
        } else if (
          (sistolik >= 160 && sistolik <= 179) ||
          (diastolik >= 100 && diastolik <= 119)
        ) {
          this.exTekananDarah = 'Hipertensi Tahap 2'
        } else if (sistolik >= 180 || diastolik >= 120) {
          this.exTekananDarah = 'Krisis Hipertensi'
        }
        var data = this.data.isian_dokter.objektif
        // console.log('asdasdas' + sistolik)
        if (typeof data !== 'undefined') {
          var brackets = data.match(/\[[^\]]*]/g)

          var appendKeSubjektifDokter =
            '[ Sistolik:' +
            sistolik +
            ', Diastolik:' +
            diastolik +
            ', Status :' +
            this.exTekananDarah +
            ', Suhu Axila :' +
            this.data.pemeriksaan.input_perawat.suhu_axila +
            ' °C' +
            ', Suhu Recital :' +
            this.data.pemeriksaan.input_perawat.suhu_axila +
            ' °C' +
            ', Nadi :' +
            this.data.pemeriksaan.input_perawat.nadi +
            ' x / mnt' +
            ', RR :' +
            this.data.pemeriksaan.input_perawat.RR +
            ' x / mnt' +
            ' ]'
          this.item_ke = null
          if (brackets !== null) {
            brackets.forEach((item, index) => {
              if (item.includes('[ Sistolik')) {
                this.item_ke = index
              }
            })
            if (this.item_ke != null) {
              this.data.isian_dokter.objektif =
                this.data.isian_dokter.objektif.replace(
                  brackets[this.item_ke],
                  appendKeSubjektifDokter
                )
            } else {
              this.data.isian_dokter.objektif = this.appendTechnique(
                this.data.isian_dokter.objektif,
                appendKeSubjektifDokter
              )
            }
          } else {
            this.data.isian_dokter.objektif = this.appendTechnique(
              this.data.isian_dokter.objektif,
              appendKeSubjektifDokter
            )
          }
          // console.log(this.exTekananDarah)
        } else {
          var appendKeSubjektifDokters =
            '[ Sistolik:' +
            sistolik +
            ', Diastolik:' +
            diastolik +
            ', Status :' +
            this.exTekananDarah +
            ', Suhu Axila :' +
            this.data.pemeriksaan.input_perawat.suhu_axila +
            ' °C' +
            ', Suhu Recital :' +
            this.data.pemeriksaan.input_perawat.suhu_axila +
            ' °C' +
            ', Nadi :' +
            this.data.pemeriksaan.input_perawat.nadi +
            ' x / mnt' +
            ', RR :' +
            this.data.pemeriksaan.input_perawat.RR +
            ' x / mnt' +
            ' ]'
          this.data.isian_dokter.objektif = appendKeSubjektifDokters
        }
      }
    },
    // jsonPrettier,
    // ...mapMutations(['set_contents']),
    ...mapActions(['postFormRawatJalan']),
    convertToPlus (val) {
      if (!isNaN(val)) {
        const t = parseInt(val)
        if (t >= 0) {
          return t
        }
      }
      return 0
    },
    appendTechnique (satu, dua) {
      // var a = 'how you are'
      if (satu.indexOf(dua) > -1) {
        // jika sudah ada / contained
        return satu
      }
      if (satu !== '' && satu !== dua) {
        // jika tidak kosong dan tidak inputan yang sama dgn skrng
        // alert('sadads')
        return dua + '\n' + satu // append
      } else {
        return dua
      }
    },
    postSaveBackground () {
      this.disablebuttonSimpan = true

      // eslint-disable-next-line no-undef
      Swal.fire({
        title: 'Perhatian! Apakah Anda Yakin?',
        text: 'Ingin Menyimpan Resume Ini.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya, Simpan Resume',
        cancelButtonText: 'Tidak Jadi Batal',
        html: ''
      }).then((result) => {
        if (result.value) {
          this.data.inputan_dokter.anamnesa = this.data.isian_dokter.subjektif
          // this.data.isian_dokter.diagnosa_utama =
          //   this.data.isian_dokter.asessmen_diagnosa_kerja
          this.data.inputan_dokter.pemeriksaan_fisik =
            this.data.isian_dokter.objektif
          this.data.inputan_dokter.kode_icd_diagnosa_utama =
            this.data.isian_dokter.kode_icd_diagnosa_utama
          // this.data.inputan_dokter.kode_icd_diagnosa_utama =
          //   this.data.isian_dokter.asessmen_diagnosa_kerja
          this.data.inputan_dokter.instruksi_tindak_lanjut =
            this.data.isian_dokter.instruksi_tindak_lanjut
          this.data.inputan_dokter.diagnosa_sekunder =
            this.data.isian_dokter.diagnosa_sekunder
          this.data.inputan_dokter.tindakan_prosedur =
            this.data.isian_dokter.tindakan_prosedur
          this.data.inputan_dokter.kode_icd_diagnosa_sekunder =
            this.data.isian_dokter.kode_icd_diagnosa_sekunder
          this.data.inputan_dokter.theraphy = this.data.isian_dokter.planning
          this.data.inputan_dokter.tanggal_lahir =
            this.data.isian_dokter.tanggal_lahir
          this.data.inputan_dokter.cara_pulang =
            this.data.isian_dokter.cara_pulang
          //   thi  .kontrolKe
          this.data.inputan_dokter.diagnosa_utama =
            this.data.isian_dokter.diagnosa_utama

          const dataForm = {
            data: this.data,
            human_region_json: this.human_region_json,
            human_region_base64: this.human_region_base64,
            created_by: this.user.employee_id
          }

          // console.log(dataForm)
          // var url = baseUrl +
          //     `emr/simpan-asesmen-keperawatan?norm=${this.$route.query.no_rm}&folio_id=${this.$route.query.folio_id}&reg_id=${this.$route.query.registration_id}&sub_unit_id=${this.$route.query.sub_unit_id}`

          // eslint-disable-next-line no-undef
          $.post(
            baseUrl +
              `emr/simpan-asesmen-keperawatan?norm=${this.$route.query.no_rm}&folio_id=${this.$route.query.folio_id}&reg_id=${this.$route.query.registration_id}&sub_unit_id=${this.$route.query.sub_unit_id}`,
            dataForm,
            (res) => {
              // console.log(res)
              // eslint-disable-next-line no-unused-vars
              const { con, msg, results } = res

              if (con) {
                this.disablebuttonSimpan = false
                this.color = 'primary'
                successMsg(msg)
                // setTimeout(() => {
                //   // this.sip()
                // }, 100)
              } else {
                this.disablebuttonSimpan = false
                this.color = 'primary'
                errorMsg(msg)
              }
            },
            'JSON'
          )
          // resetBtnLoading(btn, html)
        } else {
          this.disablebuttonSimpan = false
          this.color = 'primary'
          // resetBtnLoading(btn, html)
          // errorMsg('Nggak Jadi Deh !!!')
        }
      })
    },

    postSave () {
      // console.log('11')
      // setBtnLoading(btn, 'Menyimpan')

      if (this.user.is_dokter === '1') {
        if (this.data.isian_dokter.subjektif === '') {
          errorMsg('Asesmen Tidak Boleh Kosong!')
          return
        }
        if (this.data.isian_dokter.diagnosa_utama === '') {
          errorMsg('Diagnosa Utama Tidak Boleh Kosong!')
          return
        }
      }

      // console.log('sadad')

      // this.disablebuttonSimpan = true

      // eslint-disable-next-line no-undef
      Swal.fire({
        title: 'Perhatian! Apakah Anda Yakin?',
        text: 'Ingin Menyimpan Resume Ini.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya, Simpan Resume',
        cancelButtonText: 'Tidak Jadi Batal',
        html: ''
      }).then((result) => {
        if (result.value) {
          this.data.inputan_dokter.anamnesa = this.data.isian_dokter.subjektif
          // this.data.isian_dokter.diagnosa_utama =
          //   this.data.isian_dokter.asessmen_diagnosa_kerja
          this.data.inputan_dokter.pemeriksaan_fisik =
            this.data.isian_dokter.objektif
          this.data.inputan_dokter.kode_icd_diagnosa_utama =
            this.data.isian_dokter.kode_icd_diagnosa_utama
          // this.data.inputan_dokter.kode_icd_diagnosa_utama =
          //   this.data.isian_dokter.asessmen_diagnosa_kerja
          this.data.inputan_dokter.instruksi_tindak_lanjut =
            this.data.isian_dokter.instruksi_tindak_lanjut
          this.data.inputan_dokter.diagnosa_sekunder =
            this.data.isian_dokter.diagnosa_sekunder
          this.data.inputan_dokter.tindakan_prosedur =
            this.data.isian_dokter.tindakan_prosedur
          this.data.inputan_dokter.kode_icd_diagnosa_sekunder =
            this.data.isian_dokter.kode_icd_diagnosa_sekunder
          this.data.inputan_dokter.theraphy = this.data.isian_dokter.planning
          this.data.inputan_dokter.tanggal_lahir =
            this.data.isian_dokter.tanggal_lahir
          this.data.inputan_dokter.cara_pulang =
            this.data.isian_dokter.cara_pulang
          //   thi  .kontrolKe
          this.data.inputan_dokter.diagnosa_utama =
            this.data.isian_dokter.diagnosa_utama

          const dataForm = {
            data: this.data,
            human_region_json: this.human_region_json,
            human_region_base64: this.human_region_base64,
            created_by: this.user.employee_id
          }

          // console.log(dataForm)

          // eslint-disable-next-line no-undef
          $.post(
            baseUrl +
              `emr/simpan-asesmen-keperawatan?norm=${this.$route.query.no_rm}&folio_id=${this.$route.query.folio_id}&reg_id=${this.$route.query.registration_id}&sub_unit_id=${this.$route.query.sub_unit_id}`,
            dataForm,
            (res) => {
              // console.log(res)
              // eslint-disable-next-line no-unused-vars
              const { con, msg, results } = res

              if (con) {
                this.disablebuttonSimpan = false
                this.color = 'primary'
                successMsg(msg)
                // setTimeout(() => {
                //   // this.sip()
                // }, 100)
              } else {
                this.disablebuttonSimpan = false
                this.color = 'primary'
                errorMsg(msg)
              }
            },
            'JSON'
          )
          // resetBtnLoading(btn, html)
        } else {
          this.disablebuttonSimpan = false
          this.color = 'primary'
          // resetBtnLoading(btn, html)
          // errorMsg('Nggak Jadi Deh !!!')
        }
      })
    },

    getHistoryResume (v) {
      apiKedua
        .get('emr/get-history-asesmen?no_rm=' + this.$route.query.no_rm)
        .then((res) => {
          const history = []
          const result = res.data
          if (result.con) {
            for (const item of result.results) {
              history.push({
                id: item.id,
                nama_dokter: item.namaDokter,
                tglDibuat: item.tglDibuat,
                folio_id: item.folio_id,
                no_rm: item.no_rm,
                registration_id: item.registration_id,
                unit: item.unit,
                pemeriksaan: item.pemeriksaan
              })
            }
          }
          this.history = history
        })
    },
    getAssemen (v) {
      this.disablebuttonSimpan = true
      // eslint-disable-next-line no-undef
      $.get(
        baseUrl +
          'emr/get-asesmen-keperawatan?folio_id=' +
          this.$route.query.folio_id +
          '&reg_id=' +
          this.$route.query.registration_id +
          '&sub_unit_id=' +
          this.$route.query.sub_unit_id,
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res

          if (con) {
            this.disablebuttonSimpan = false

            // eslint-disable-next-line no-undef
            var datalz = LZString.decompressFromBase64(results.data)
            var dataDec = JSON.parse(datalz)
            var data = dataDec
            this.data.wa = data?.wa

            this.data.pemeriksaan = data.pemeriksaan
            this.data.isian_dokter = data.isian_dokter
            this.data.inputan_dokter = data.inputan_dokter
            this.data.kebutuhan_edukasi = data.kebutuhan_edukasi
            this.data.kriteria_perencanaan_pulang =
              data.kriteria_perencanaan_pulang
            this.data.kriteria_discharge_planning =
              data.kriteria_discharge_planning
            // console.log(data)

            var today = new Date()

            var dd = String(today.getDate()).padStart(2, '0')
            var mm = String(today.getMonth() + 1).padStart(2, '0') // January is 0!
            var yyyy = today.getFullYear()
            var hours = today.getHours()
            var getMinutes = today.getMinutes()
            var getSeconds = today.getSeconds()
            today = yyyy + '-' + mm + '-' + dd
            // this.data.pemeriksaan.input_pendaftaran.jam = today
            this.data.isian_dokter.jam_isian_dokter =
              today + ' ' + hours + ':' + getMinutes + ':' + getSeconds
            this.data.pemeriksaan.input_perawat.tanggal = today
            this.data.pemeriksaan.input_perawat.jam =
              hours + ':' + getMinutes + ':' + getSeconds
            this.human_region_json = data.human_region_json

            this.init_json_human_body = this.human_region_json // Jika baru

            this.human_region_json = results.human_region_json

            this.init_json_human_body = this.human_region_json // Jika baru
          } else {
            this.disablebuttonSimpan = false
            // eslint-disable-next-line no-undef
            var datalzz = LZString.decompressFromBase64(results.data)
            var dataDecz = JSON.parse(datalzz)
            // var data = dataDec
            var dataz = dataDecz
            // this.data.wa = data.wa
            this.data.menu_cara_bayar = dataz.menu_cara_bayar
            this.data.menu_cara_bayar = dataz.menu_cara_bayar
            this.data.pemeriksaan = dataz.pemeriksaan
            this.data.isian_dokter = dataz.isian_dokter
            this.data.inputan_dokter = dataz.inputan_dokter
            this.data.kebutuhan_edukasi = dataz.kebutuhan_edukasi
            this.data.kriteria_perencanaan_pulang =
              dataz.kriteria_perencanaan_pulang
            this.data.kriteria_discharge_planning =
              dataz.kriteria_discharge_planning
            errorMsg(msg)
          }
        },
        'json'
      )
      // console.table(v)
      // $.get(baseUrl + 'emr/get-asesmen-keperawatan?folio_id=' +
      //       this.$route.query.folio_id

      //   .(res) => {
      //     // console.log(res)
      //     var response = res.data.results
      //     // console.log(response)
      //     var data = JSON.parse(response)
      //     if (response.data) {
      //       // console.info(v + 'daata icd')
      //       this.data.wa = data.wa
      //       // // eslint-disable-next-line no-undef
      //       // this.data.pemeriksaan = response.data.pemeriksaan
      //       // this.data.pemeriksaan.input_perawat.riwayat_alergi =
      //       //   response.data.pemeriksaan.input_perawat.riwayat_alergi
      //       // this.data.isian_dokter = response.data.isian_dokter
      //       // this.data.kebutuhan_edukasi = response.data.kebutuhan_edukasi
      //       // this.data.kriteria_discharge_planning =
      //       //   response.data.kriteria_discharge_planning
      //       // this.data.kriteria_perencanaan_pulang =
      //       //   response.data.kriteria_perencanaan_pulang
      //       // this.data.inputan_dokter = response.data.isian_dokter
      //       this.data = data.data
      //     } else {
      //       // console.log(response)
      //       // this.data.inputan_dokter.instruksi_tindak_lanjut = this.kontrolKe
      //     }

      //     // console.log(response)
      //   })
    },
    pushIframe (datanya) {
      // alert('bab1')
      this.hasil_data_human_body = datanya // .data_json
      // console.log(this.hasil_data_human_body)
      this.human_region_json = this.hasil_data_human_body.data_json // simpan jsonnya
      this.human_region_base64 = this.hasil_data_human_body.data_svg // simpan base64 gambar nya
    },
    subjectifAppend (v) {
      // console.log('SUBJECT' + v)
      // // eslint-disable-next-line no-unused-vars
      if (this.$route.query.sub_unit_id !== '853') {
        var dataSubjectif = v

        var data = this.data.isian_dokter.subjektif
        if (typeof data !== 'undefined') {
          var brackets = data.match(/\[[^\]]*]/g)
          var appendKeSubjektifDokter = '[' + dataSubjectif + ']'
          this.item_ke = null
          if (brackets !== null) {
            brackets.forEach((item, index) => {
              if (item.includes('[')) {
                this.item_ke = index
              }
            })
            if (this.item_ke != null) {
              this.data.isian_dokter.subjektif =
                this.data.isian_dokter.subjektif.replace(
                  brackets[this.item_ke],
                  appendKeSubjektifDokter
                )
            } else {
              this.data.isian_dokter.subjektif = this.appendTechnique(
                this.data.isian_dokter.subjektif,
                appendKeSubjektifDokter
              )
            }
          } else {
            this.data.isian_dokter.subjektif = this.appendTechnique(
              this.data.isian_dokter.subjektif,
              appendKeSubjektifDokter
            )
          }
        } else {
          var appendKeSubjektifDokters = '[' + dataSubjectif + ']'

          this.data.isian_dokter.subjektif = appendKeSubjektifDokters
        }
      }
    },

    getDataBpjsSep () {
      // eslint-disable-next-line no-undef
      $.get(
        baseUrlV2 + 'pasien/cek-sep',
        { reg_id: this.$route.query.registration_id },
        (res) => {
          // eslint-disable-next-line no-undef, no-unused-vars
          const { con, msg, results } = res
          if (con) {
            this.bpjs = results.sep
            this.kartuAnggota = results.kartuAnggota
            this.detailSep = results.detailSep
            this.isBpjs = true
            this.isSepAda = results.isSepAda
          } else {
            this.bpjs = ''
            this.isBpjs = false
            this.isSepAda = false
          }
        },
        'JSON'
      )
    },
    checkPanggilan () {
      // WATCHER______________________________________________

      // console.log(firebase)
      // EVENT INTERUPT FIREBASE
      // var authRef_interupsi = firebase.database().ref('interupt') // listen to array post
      var isNewLoad = true
      // eslint-disable-next-line camelcase
      var authRef_interupsi = firebase.database().ref('antrian_poli') // listen to array post

      var ininya = this
      // eslint-disable-next-line camelcase
      authRef_interupsi.on('value', function (snapshot_intrp) {
        if (isNewLoad === false) {
          // if (true) {
          if (snapshot_intrp.val() != null) {
            const value = snapshot_intrp.val()

            var pegawai = JSON.parse(localStorage.getItem('user'))
            if (pegawai.employee_id === value.employee_id) {
              ininya.trueDipanggil = true
              ininya.patient_name = value.patient_name
              ininya.no_rm = String(value.no_rm)
              ininya.folio_id = String(value?.folio_id)
              ininya.reg_id = String(value.reg_id)
              // ininya.getDataPasien(value.no_rm)
            }
          }
        } else {
          isNewLoad = false
          console.log('[ignore Interupt] First Load')
        }
      })
    },
    tidakAdaResep () {
      var data = {
        folio_id: this.$route.query.folio_id,
        pegawai: this.pegawai
      }
      // eslint-disable-next-line no-undef
      $.post(
        baseUrlV2 +
          'pasien/selesaikan-layanan?folio_id=' +
          this.$route.query.folio_id,
        data,
        (res) => {
          // eslint-disable-next-line no-undef
          const { con, msg } = res

          if (con) {
            // console.log(msg)
            successMsg(msg)
            this.DISABLEDBUTTONLAYANAN = true
          } else {
            // console.log(results)
            errorMsg(msg)
            this.DISABLEDBUTTONLAYANAN = false
          }
        },
        'JSON'
      )
    },
    checkResep () {
      // eslint-disable-next-line no-undef
      $.get(
        baseUrlV2 + 'pasien/selesaikan-layanan',
        { folio_id: this.$route.query.folio_id },
        (res) => {
          // eslint-disable-next-line no-undef, no-unused-vars
          const { con, msg } = res
          if (con) {
            // console.log(msg)
            this.DISABLEDBUTTONLAYANAN = true
          } else {
            this.DISABLEDBUTTONLAYANAN = false
          }
        },
        'JSON'
      )
    },
    anggotaKeluarga (v) {
      // eslint-disable-next-line no-undef
      $.get(
        baseUrlV2 + 'pasien/get-anggota-keluarga?patient_id=' + v,
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            this.dataKeluarga = results
          } else {
            this.dataKeluarga = []
          }
        },
        'JSON'
      )
    },
    cekResep () {
      // eslint-disable-next-line no-unused-vars
      const regid = this.$route.query.registration_id
      // eslint-disable-next-line no-unused-vars
      const folioid = this.$route.query.folio_id
      // eslint-disable-next-line no-undef
      $.get(
        baseUrlV2 + 'pasien/cek-resep',
        { reg_id: regid, folio_id: folioid },
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            this.isResepAda = true
            this.textResep =
              'Pasien Ini Mendapatkan Pelayanan Dari Dokter dan Diberikan Resep!'
            this.resepKedua = results
          } else {
          }
        },
        'JSON'
      )
    },
    viewResepDetail (folioid) {
      this.cekResepData = folioid
      this.dialogResep = true
      this.$refs.resepcek.cariResep()

      // console.info(this.cekResepData + 'data')
    },
    sehatq () {
      console.error('1')
      this.showDialogSehatq = true
      // eslint-disable-next-line no-unused-vars
      // const reg = this.$route.query.registration_id
      // // eslint-disable-next-line no-undef
      // $.get(Penunjang + 'sehatq/kirim-request', { reg_id: reg }, (res) => {
      //   // eslint-disable-next-line no-undef
      //   $('#hasil').html(res)
      // })
    }
  },
  computed: {
    skorSkalaFlacc () {
      const tmp = this.data.pemeriksaan.input_perawat.skala_flacc
      let totalSkor = 0
      for (const key in tmp) {
        if (key !== 'total skor') {
          if (!isNaN(tmp[key])) {
            let t = parseInt(tmp[key])
            if (t >= 0) {
              if (t > 2) {
                t = 2
              }
              tmp[key] = t
              // console.log('t = ', t)
              totalSkor += t
            } else {
              tmp[key] = t * -1
              totalSkor += t * -1
              // console.log('tmp[key] = ', tmp[key])
            }
          }
        }
      }
      tmp['total skor'] = totalSkor
      return totalSkor
    },

    getIcd10s () {
      return this.icd10.map((v) => ({
        text: v.text,
        value: v.id
      }))
    },

    subjectif () {
      return [this.data.pemeriksaan.input_perawat.subjektif]
    },
    imtProperty () {
      return [
        this.data.pemeriksaan.input_perawat.tinggi_badan,
        this.data.pemeriksaan.input_perawat.berat_badan
      ]
    },
    tekananDarahProperty () {
      return [
        this.data.pemeriksaan.input_perawat.TD,
        this.data.pemeriksaan.input_perawat.suhu_axila,
        this.data.pemeriksaan.input_perawat.suhu_recital,
        this.data.pemeriksaan.input_perawat.nadi,
        this.data.pemeriksaan.input_perawat.RR,
        this.data.pemeriksaan.input_perawat.skala_nyeri
      ]
    },
    filteredAndSofrted () {
      const compare = (a, b) => {
        if (a.name < b.name) return -1
        if (a.name > b.name) return 1
        return 0
      }

      return this.history
        .filter((history) => {
          return history.nama_dokter
            .toLowerCase()
            .includes(this.search.toLowerCase())
        })
        .sort(compare)
    },
    diagnosaUtama () {
      return this.data.isian_dokter.kode_icd_diagnosa_utama
    }
  },
  watch: {
    imtProperty (newVal) {
      this.hitungImt(newVal[0], newVal[1])
    },
    tekananDarahProperty (v) {
      this.hitungTekananDarah(v[0])
    },
    subjectif (v) {
      this.subjectifAppend(v)
    },
    searchIcd (val) {
      if (val.length >= 2) {
        apiKedua
          .get('emr/icd?q=' + val, {})
          .then((response) => (this.icd10 = response.data.results))
          .catch(function (error) {
            // handle error
            console.log(error)
          })
          .then(function () {
            // always executed
          })
      }
    },
    // diagnosaUtama (v) {
    //   this.diagnosaUtamaAppend(v)
    // },
    'data.isian_dokter.kode_icd_diagnosa_utama' (a, b) {
      // console.log(a + 'hasur')
      // eslint-disable-next-line no-unused-vars
      const icdygDipilih = a
      if (typeof a !== 'undefined') {
        var idcArray = []
        for (const item of a) {
          const text = item.text.split('/')
          const icd = text[1]
          idcArray.push(icd)
        }
        // console.log(idcArray)
        // eslint-disable-next-line no-unused-vars
        var diagnosa = idcArray.join(',')
        // this.data.isian_dokter.diagnosa_utama =
        //   this.data.isian_dokter.diagnosa_utama + '-' + diagnosa
        var data = this.data.isian_dokter.diagnosa_utama
        if (typeof data !== 'undefined') {
          var brackets = data.match(/\[[^\]]*]/g)
          var appendKeSubjektifDokter = '[' + diagnosa + ']'
          this.item_ke = null
          if (brackets !== null) {
            brackets.forEach((item, index) => {
              if (item.includes('[')) {
                this.item_ke = index
              }
            })
            if (this.item_ke != null) {
              this.data.isian_dokter.diagnosa_utama =
                this.data.isian_dokter.diagnosa_utama.replace(
                  brackets[this.item_ke],
                  appendKeSubjektifDokter
                )
            } else {
              this.data.isian_dokter.diagnosa_utama = this.appendTechnique(
                this.data.isian_dokter.diagnosa_utama,
                appendKeSubjektifDokter
              )
            }
          } else {
            this.data.isian_dokter.diagnosa_utama = this.appendTechnique(
              this.data.isian_dokter.diagnosa_utama,
              appendKeSubjektifDokter
            )
          }
        } else {
          var appendKeSubjektifDokters = '[' + diagnosa + ']'

          this.data.isian_dokter.diagnosa_utama = appendKeSubjektifDokters
        }
      }
    },
    'data.isian_dokter.kode_icd_diagnosa_sekunder' (a, b) {
      // console.log(a)
      // const icdygDipilih = a
      if (typeof a !== 'undefined') {
        var idcArray = []
        for (const item of a) {
          const text = item.text.split('/')
          const icd = text[1]
          idcArray.push(icd)
        }

        var diagnosa = idcArray.join(',')
        // this.data.isian_dokter.diagnosa_utama =
        //   this.data.isian_dokter.diagnosa_utama + '-' + diagnosa
        var data = this.data.isian_dokter.diagnosa_sekunder
        if (typeof data !== 'undefined') {
          var brackets = data.match(/\[[^\]]*]/g)
          var appendKeSubjektifDokter = '[' + diagnosa + ']'
          this.item_ke = null
          if (brackets !== null) {
            brackets.forEach((item, index) => {
              if (item.includes('[')) {
                this.item_ke = index
              }
            })
            if (this.item_ke != null) {
              this.data.isian_dokter.diagnosa_sekunder =
                this.data.isian_dokter.diagnosa_sekunder.replace(
                  brackets[this.item_ke],
                  appendKeSubjektifDokter
                )
            } else {
              this.data.isian_dokter.diagnosa_sekunder = this.appendTechnique(
                this.data.isian_dokter.diagnosa_sekunder,
                appendKeSubjektifDokter
              )
            }
          } else {
            this.data.isian_dokter.diagnosa_sekunder = this.appendTechnique(
              this.data.isian_dokter.diagnosa_sekunder,
              appendKeSubjektifDokter
            )
          }
        } else {
          var appendKeSubjektifDokters = '[' + diagnosa + ']'

          this.data.isian_dokter.diagnosa_sekunder = appendKeSubjektifDokters
        }
      }
    }
  }
}
</script>

<style>
.frame {
  width: 1280px;
  height: 926px;
  border: 0;
  -ms-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -o-transform: scale(0.8);
  -webkit-transform: scale(0.8);
  transform: scale(0.8);

  -ms-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

.float-group {
  position: fixed;
  bottom: 160px;
  right: 40px;
  color: #fff;
  text-align: center;
  z-index: 100;
}

.float {
  position: fixed;
  bottom: 160px;
  right: 40px;
  color: #fff;
  text-align: center;
  z-index: 100;
}

.framclass {
  width: 1280px;
  height: 786px;
  border: 0;
  -ms-transform: scale(0.7);
  -moz-transform: scale(0.7);
  -o-transform: scale(0.7);
  -webkit-transform: scale(0.7);
  transform: scale(0.7);

  -ms-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

.Div1 {
  border: 1px solid #ddd;
  width: 24%;
  background-color: white;
  float: left;
  border: 2px solid #c00;
  margin-right: 5px;
  min-height: 50px;
  position: fixed;
}
</style>
