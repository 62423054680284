<template>
  <div class="m-2">
    <v-alert dense shaped prominent type="error">
      Catatan Adm : {{ data.demografi.notes }}
    </v-alert>
    <v-card color="teal darken-1" dark>
      <v-card-text>
        <v-row class="mt-1 p-2 mb-0">
          <v-col
            v-for="(v, i) in master.demografi"
            :md="v.col ? v.col : 12"
            :lg="v.col ? v.col : 12"
            :sm="v.col ? v.col : 12"
            cols="12"
            class="mt-1 mb-1 pt-1 pb-1"
            :key="i"
          >
            <smart-widget
              :comp="v.widget"
              :sync-value="(e) => (data.demografi[i] = e)"
              :value="data.demografi[i]"
              vclass="c-text-field"
              :data="v.data"
              :label="v.label ? v.label : i.replaceAll('_', ' ')"
              :rules="[rules.required]"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card dense class="mt-2" v-if="isBpjs && isSepAda">
      <v-card-text>
        <table border="0" class="table table-borderless table-sm">
          <tr>
            <td width="50%" valign="middle">
              <img
                src="../assets/bpjs-logo.png"
                width="170"
                height="28"
                align="center"
              />
            </td>
            <td
              align="left"
              width="50%"
              valign="middle"
              style="font-size: 14px"
            >
              SURAT ELEGIBILITAS PESERTA RS SYAFIRA
            </td>
          </tr>
        </table>
        <table
          cellpadding="2"
          class="table table-bordeless table-sm"
          style="font-size: 12px"
        >
          <tr>
            <td width="123px">No. SEP</td>
            <td width="310px">: &nbsp;{{ bpjs.noSep }}</td>
            <td width="90px"></td>
            <td></td>
          </tr>
          <tr>
            <td>Nama Peserta</td>
            <td>:&nbsp;{{ bpjs.namaPeserta }}</td>
            <td>Potensi PRB</td>
            <td>
              :&nbsp;<b class="text-danger" style="font-size: 18px">{{
                kartuAnggota.results.peserta?.informasi.prolanisPRB
              }}</b>
            </td>
          </tr>
          <tr>
            <td>Tgl. Lahir</td>
            <td>
              :&nbsp;{{
                this.data.demografi.tanggal_lahir | moment("DD MMMM YYYY")
              }}
            </td>
            <td>Jns. Rawat</td>
            <td>:&nbsp;{{ detailSep?.jnsPelayanan }}</td>
          </tr>
          <tr>
            <td>No. Telepon</td>
            <td>:&nbsp;{{ kartuAnggota.results.peserta?.mr.noTelepon }}</td>
            <td>Kls. Rawat</td>
            <td>
              :
              {{ kartuAnggota.results.peserta?.hakKelas.keterangan }}
            </td>
          </tr>
          <tr>
            <td>Sub/Spesialis</td>
            <td>:&nbsp;{{ bpjs.poli }}</td>
            <td>Penjamin</td>
            <td>:&nbsp;{{ detailSep?.penjamin }}</td>
          </tr>

          <tr>
            <td style="vertical-align: top">Diagnosa Awal</td>
            <td>: &nbsp;{{ bpjs.diagnosa }}</td>
            <td colspan="2"></td>
          </tr>
        </table>
      </v-card-text>
    </v-card>
    <v-card class="mt-2">
      <v-card-text>
        <v-alert dense shaped prominent type="error">
          <span
            ><b>Tanggal Pelayanan :</b> {{ data.demografi.tgl_pelayanan }}</span
          >
        </v-alert>
        <form class="form-horizontal mt-5" id="form">
          <div class="form-group row">
            <label for="inputEmail3" class="col-sm-2 col-form-label"
              ><b>Obat</b></label
            >
            <div class="col-sm-10">
              <select id="itemObat" class="form-control form-control-sm">
                <option value="0"></option>
              </select>
            </div>
          </div>
          <div class="form-group row">
            <label for="inputEmail3" class="col-sm-2 col-form-label"
              ><b>Jumlah Obat</b></label
            >
            <div class="col-sm-10">
              <v-text-field
                v-model="jumlahObat"
                dense
                :disabled="isDisabled"
                outlined
                type="number"
                placeholder="Masukkan Jumlah Obat"
              ></v-text-field>
            </div>
          </div>
          <div class="form-group row">
            <label for="inputPassword3" class="col-sm-2 col-form-label"
              ><b>Signa/Waktu Pemberian</b></label
            >
            <div class="col-sm-4">
              <v-text-field
                v-model="signa"
                dense
                outlined
                :disabled="isDisabled"
                :items="listSigna"
                placeholder="Signa"
              ></v-text-field>
            </div>

            <div class="col-sm-6">
              <v-text-field
                v-model="dosis"
                dense
                outlined
                :disabled="isDisabled"
                :items="listDosis"
                placeholder="Waktu Pemberian"
              ></v-text-field>
            </div>
          </div>

          <div class="form-group row">
            <label for="inputEmail3" class="col-sm-2 col-form-label"
              ><b>Keterangan</b></label
            >
            <div class="col-sm-10">
              <v-textarea
                name="input-7-1"
                rows="5"
                outlined
                :disabled="isDisabled"
                v-model="catatanKhusus"
                hint="Keterangan"
              ></v-textarea>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <v-btn
                class="btn btn-block btn-sm mt-1 text-white"
                dark
                :disabled="isDisabled"
                color="indigo"
                @click="addObat"
              >
                Add obat <span class="fa fa-plus"></span>
              </v-btn>
            </div>
            <div class="col-md-12">
              <label
                ><h3 class="text-danger">History Order Resep Dokter</h3></label
              >
              <select id="historyResep" class="form-control input-lg">
                <option value="0"></option>
              </select>
              <div v-if="isCetak">
                <v-btn
                  @click="cetakResep"
                  type="button"
                  color="primary"
                  class="btn btn-sm btn-block mt-5"
                  dark
                >
                  Liat Resep
                </v-btn>
                &nbsp;
              </div>
            </div>
          </div>
        </form>
      </v-card-text>
    </v-card>
    <!-- <input type="text" v-model="id_order_resep"> -->
    <v-card class="mt-2" v-if="isOrder">
      <v-card-text class="text-center" elevation="3">
        <h1 class="text-danger">~Orderan Sudah Dibuat~</h1>
      </v-card-text>
    </v-card>
    <table class="table table-bordered table-sm mt-2" style="font-size: 13px">
      <thead class="bg-success text-white">
        <tr>
          <th width="6%" class="text-center">Aksi</th>
          <th class="text-center" width="25.3%">Nama Obat</th>
          <th class="text-center" width="30.3%">Jumlah/Sign/Dosis</th>
          <th class="text-center" width="23.3%">Keterangan</th>
        </tr>
      </thead>
      <tbody v-for="(item, i) in listObat" :key="i">
        <tr>
          <td style="vertical-align: middle; height: 100px; text-align: center">
            <v-btn fab x-small color="red" dark @click="deleteObat(i)"
              ><v-icon>mdi-close</v-icon></v-btn
            >
            <!-- {{item}} -->
          </td>
          <td class="text-center" style="vertical-align: middle">
            <v-text-field
              disabled
              v-model="item.obat_name"
              outlined
              label="Nama Obat"
            >
            </v-text-field>
            <v-text-field
              type="text"
              outlined
              :disabled="isDisabled"
              label="Waktu Pemberian"
              v-model="item.dosis"
            />
          </td>
          <td style="vertical-align: middle">
            <v-text-field
              type="text"
              label="Signa"
              outlined
              :disabled="isDisabled"
              v-model="item.signa"
            />
            <v-text-field
              type="number"
              outlined
              :disabled="isDisabled"
              label="Jumlat Obat"
              v-model="item.jumlahObat"
            />
          </td>
          <td style="vertical-align: middle">
            <v-textarea
              v-model="item.catatanKhusus"
              label="Catatan Khusus"
              rows="2"
              :disabled="isDisabled"
              outlined
            ></v-textarea>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="form-group">
      <div
        style="z-index: 999999"
        class="float-group btn-group-vertical"
        role="group"
        aria-label="Vertical button group"
      >
        <div class="row">
          <div class="col-md-12">
            <div v-if="listObat.length">
              <button
                @click="simpanObat"
                :disabled="isDisabled"
                class="btn btn-danger text-white btn-md"
                type="button"
              >
                Simpan Resep
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import signa from '@/data/pemakaian.js'
// eslint-disable-next-line no-unused-vars
import dosis from '@/data/takaran.js'
import SmartWidget from '@/components/SmartWidget.vue'

import {
  // eslint-disable-next-line no-unused-vars
  apiKedua,
  // eslint-disable-next-line no-unused-vars
  successMsg,
  // eslint-disable-next-line no-unused-vars
  baseUrl,
  // eslint-disable-next-line no-unused-vars
  baseUrlV2,
  // eslint-disable-next-line no-unused-vars
  errorMsg,
  // eslint-disable-next-line no-unused-vars
  baseUrlEmr
} from '../plugins/supports'
export default {
  name: 'NonRacikan',
  components: {
    SmartWidget
  },
  data () {
    return {
      listObatDipilih: '',
      listObat: [],
      obat: '',
      kartuAnggota: '',
      detailSep: '',
      isSepAda: '',
      isBpjs: false,
      signa: '',
      listSigna: '',
      listDosis: '',
      hari1: '',
      isDisabled: false,
      isCetak: false,
      hari2: '',
      jumlahObat: '',
      pegawai: '',
      id_order_resep: '',
      catatanKhusus: '',
      hari: '',
      lastResepChoice: '',
      dosis: '',
      isOrder: false,
      url_emr: 'http://emr.rs-syafira.com/',
      master: {
        demografi: {
          nama_pasien: {
            widget: 'wtext',
            data: null,
            col: 3,
            label: 'Nama Pasien'
          },
          no_rekam_medis: {
            widget: 'wtext',
            data: null,
            col: 2,
            label: 'No. RM'
          },
          tanggal_lahir: {
            widget: 'wdate',
            data: null,
            col: 3,
            label: 'Tanggal Lahir'
          },
          cara_bayar: {
            label: 'Cara Bayar',
            widget: 'wtext',
            data: null,
            col: 4
          },
          folio_id: {
            widget: 'wtext',
            data: null,
            col: 2,
            label: 'No Folio'
          },
          sub_unit_id: {
            widget: 'wtext',
            data: null,
            col: 2,
            label: 'Sub Unit Dokter'
          },
          reg_id: {
            widget: 'wtext',
            data: null,
            label: 'No Reg',
            col: 2
          },
          umur: {
            widget: 'wtext',
            data: null,
            col: 6,
            label: 'Umur'
          },
          jenis_kelamin: {
            widget: 'wradio',
            data: [
              { text: 'Laki-Laki', value: 'Laki-Laki' },
              { text: 'Perempuan', value: 'Perempuan' }
            ],
            col: 6
          },
          notes: '',
          kelompok_cara_bayar: ''
        }
      },
      data: {
        demografi: {
          nama_pasien: '',
          no_rekam_medis: '',
          tanggal_lahir: '',
          jenis_kelamin: '',
          cara_bayar: '',
          folio_id: '',
          reg_id: '',
          sub_unit_id: '',
          umur: '',
          handphone: ''
        }
      },
      rules: {
        required: (value) => {
          return true
        }
      }
    }
  },
  created () {
    this.pegawai = JSON.parse(localStorage.getItem('user'))
    this.listSigna = signa
    this.listDosis = dosis
    if (localStorage.getItem('firebasePasien')) {
      setTimeout(() => {
        this.pegawai = JSON.parse(localStorage.getItem('user'))

        // this.getHistoryResume()
      }, 1000)
    }
    if (this.$route.query.no_rm != null) {
      this.getDataPasien(this.$route.query.no_rm)
    }
  },
  mounted () {
    this.select2()
    this.select3()
    this.$root.$on('NonRacikan', (item) => {
      this.addObatOtherComponent(item)
      console.log(item)
    })
    if (this.$route.query.no_rm != null) {
      this.getDataPasien(this.$route.query.no_rm)
      this.getDataObat()
      this.getDataBpjsSep()
    }

    // this.init()
    window.anu = this
  },
  methods: {
    addObatByHistoryOrder (itemObatByOrder) {
      const listObat = []
      // console.log(itemObatByOrder)
      for (const itemByOrder of itemObatByOrder.detail.data) {
        var self = this

        listObat.push({
          // eslint-disable-next-line no-undef
          obat_id: itemByOrder.obat_id,
          // eslint-disable-next-line no-undef
          obat_name: itemByOrder.obat_name,
          signa: itemByOrder.signa,
          jumlahObat: itemByOrder.jumlahObat,
          dosis: itemByOrder.dosis,
          catatanKhusus: itemByOrder.catatanKhusus
        })
        listObat.forEach((val, index) => {
          // eslint-disable-next-line no-undef
          if (val.obat_id === itemByOrder.obat_id) {
            self.listObat.splice(index, 1)
            // errorMsg('Item Obat Sudah')
            // console.log(val)
            return false
          }
        })
      }

      this.listObat = listObat
    },
    addObatOtherComponent (itemObat) {
      console.log('Obat Ditambahkan')
      const listObat = []
      const notItem = ['4436', '4440']
      const unique = [...new Map(itemObat.map((m) => [m.item_id, m])).values()]
      for (const item of unique) {
        var self = this
        listObat.forEach((val, index) => {
          // eslint-disable-next-line no-undef
          if (val.item_id === item.item_id) {
            self.listObat.splice(index, 1)
            // errorMsg('Item Obat Sudah')
            // console.log(val)
            return false
          }
        })
        if (!notItem.includes(item.item_id)) {
          listObat.push({
            // eslint-disable-next-line no-undef
            obat_id: item.item_id,
            // eslint-disable-next-line no-undef
            obat_name: item.item.item_name,
            signa: item.dosage,
            hari2: '',
            hari: '',
            jumlahObat: item.quantity,
            dosis: item.remark,
            unit_price: item.unit_price,
            self_amount: item.self_amount,
            pay_by_amount: item.pay_by_amount,
            tax1_amount: item.tax1_amount,
            uom_conversion: item.uom_conversion,
            uom: item.uom,
            catatanKhusus: ''
          })
        }
      }

      this.listObat = listObat
    },
    select2 () {
      var self = this

      // eslint-disable-next-line no-undef
      $('#itemObat')
        .select2({
          containerCssClass: ':all:',

          width: 'resolve', // need to override the changed default
          theme: 'classic',

          ajax: {
            url: baseUrlV2 + 'pasien/get-data-obat',
            type: 'post',
            dataType: 'json',
            delay: 250,
            data: function (params) {
              return {
                searchTerm: params.term, // search term
                reg_id: self.$route.query.registration_id
              }
            },
            processResults: function (response) {
              return {
                results: response
              }
            },
            cache: true
          }
        })
        .on('select2:select', function (evt) {
          console.log('hasil SELECT ' + JSON.stringify(evt.params.data))

          self.listObatDipilih = evt.params.data
        })
    },
    copyKeResep () {
      var Terapi = []

      for (const item of this.lastResepChoice.detail.data) {
        // const text = item.text.split('/')
        // const icd = text[1]
        const Obat =
          item.obat_name + ' (' + item.signa + ') Dosis (' + item.dosis + ')'
        console.log(item)
        Terapi.push(Obat)
      }

      // const TerapiResults = '\n Non Racikan : \n' + Terapi.join(', ') + '\n '
      // console.log(TerapiResults)
      // this.$root.$emit('AsesmentRawatJalan', TerapiResults) // like this

      // console.log(JSON.stringify(this.lastResepChoice.detail.data) + ' Resep Yang Mau Dicpy')
    },
    select3 () {
      var self = this

      // eslint-disable-next-line no-undef
      $('#historyResep')
        .select2({
          width: 'resolve', // need to override the changed default
          theme: 'classic',
          // allowClear: true, // Show the clear button
          ajax: {
            url: baseUrlV2 + 'pasien/history-resep?type=0',
            type: 'post',
            dataType: 'json',
            delay: 150,
            data: function (params) {
              return {
                searchTerm: params.term, // search term,
                no_rm: self.$route.query.no_rm,
                sub: self.$route.query.sub_unit_id
              }
            },
            processResults: function (response) {
              console.log(response)
              // this.addObatByHistoryOrder(response.detail)
              return {
                results: response
                // hasi
              }
            },
            cache: true
          }
        })
        .on('select2:select', function (evt) {
          self.id_order_resep = evt.params.data.id_order_resep
          self.isCetak = true
          self.addObatByHistoryOrder(evt.params.data)
          self.lastResepChoice = evt.params.data

          // console.log(evt.params.data.detail)
        })
    },
    deleteObat (v) {
      this.listObat.splice(v, 1)
    },
    simpanObat () {
      // eslint-disable-next-line no-unused-vars
      const firebasePasien = {
        no_rm: this.$route.query.no_rm,
        folio_id: this.$route.query.folio_id,
        reg_id: this.$route.query.registration_id,
        sub_unit_id: this.$route.query.sub_unit_id,
        id_order_resep: this.id_order_resep
      }

      var idcArray = []
      for (const item of this.listObat) {
        // const text = item.text.split('/')
        // const icd = text[1]
        const icd =
          '<b>Nama Obat : </b><span class="text-danger">' +
          item.obat_name +
          ' - </span><b>Dosis : </b><span class="text-danger">' +
          item.dosis +
          ' -</span><b>Signa : </b><span class="text-danger">' +
          item.signa +
          '</span></br>'
        idcArray.push(icd)
      }

      // eslint-disable-next-line no-unused-vars
      const html = '<br> <b>Ingin Membuat Resep Dengan Item Di Atas?</b>'
      // console.log(idcArray.join(','))
      // console.log(this.listObat)
      // eslint-disable-next-line no-undef
      Swal.fire({
        title: `Perhatian! Apakah Anda Yakin Ingin Membuat Resep No. Racikan Dengan No.RM ${this.$route.query.no_rm} Dengan  Nama Pasien ${this.data.demografi.nama_pasien} ?`,
        text: 'Ingin Membuat Resep Non Racikan Ini Dengan No. Rekam Medis :',
        icon: 'warning',
        width: 980,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya, Buat Resep!',
        cancelButtonText: 'Tidak Jadi Batal',
        html: idcArray.join(' ') + html
      }).then((result) => {
        if (result.value) {
          const data = {
            identitas: firebasePasien,
            data: this.listObat,
            pegawai: this.pegawai
          }

          // console.log(JSON.stringify(data) + ' Data NON RACIKAN')
          // eslint-disable-next-line no-undef
          $.post(
            baseUrlV2 + 'pasien/simpan-resep-obat',
            data,
            (response) => {
              // eslint-disable-next-line no-unused-vars
              const { con, msg, results } = response
              if (con) {
                successMsg(msg)
                this.$root.$emit('COPYRESEPNONRACIKAN', this.listObat) // like this

                // this.copyKeResep()
                this.getDataObat()
              } else {
                errorMsg(msg + ' ' + JSON.parse(results))
              }
            },
            'JSON'
          )
        } else {
          errorMsg('Nggak Jadi Deh !!!')
        }
      })
    },
    cetakResep () {
      console.log(this.id_order_resep)

      window
        .open(
          baseUrlEmr +
            'laporan-medis/cetak-resep?id_resep=' +
            this.id_order_resep,
          '_blank'
        )
        .focus()
    },
    cetakResepBackground () {
      console.log(this.id_order_resep)

      const url =
        this.url_emr +
        'cetak-with-pdf-emr-other/cetak-resep?id_resep=' +
        this.id_order_resep +
        '&view=base64_pdf'
      const result = window.btoa(url)

      // eslint-disable-next-line no-undef
      $.get(
        baseUrl +
          `cronjob-background-printing/send-print-medis-by-employee-mapping?employee_id=${this.pegawai.employee_id}=&url=` +
          result,
        (response) => {
          // console.log(response)
          const { con, msg } = response
          if (con) {
            successMsg(msg)
          } else {
            errorMsg(msg)
          }
        },
        'JSON'
      )
    },
    normalisasi (angka) {
      var temp = angka.replace(/[^,\d]/g, '').toString()
      const text = temp
      let result = text.replace(',', '.')
      result = parseFloat(result)
      // console.log(result);
      return result
    },
    addObat () {
      // eslint-disable-next-line no-undef
      //   console.log($('#itemObat').val())
      // eslint-disable-next-line no-unused-vars, no-undef
      this.obat_id = $('#itemObat').val()
      if (!this.signa) {
        errorMsg('Signa Mohon Diisi')
        return false
      }

      if (!this.jumlahObat) {
        errorMsg('Jumlah Obat Mohon Diisi')
        return false
      }
      var self = this

      this.listObat.forEach((val, index) => {
        // eslint-disable-next-line no-undef
        if (val.obat_id === this.obat_id) {
          self.listObat.splice(index, 1)
          errorMsg('Item Obat Sudah')
          console.log(val)
          return false
        }
      })
      console.log(this.signa)
      var total =
        this.normalisasi(this.listObatDipilih.jumlah) * this.jumlahObat
      // eslint-disable-next-line no-unused-vars
      var pajak =
        (this.normalisasi(this.listObatDipilih.jumlah) * this.jumlahObat * 11) /
        100

      var totalSendiri = 0
      var totalDitanggung = 0
      if (this.listObatDipilih.jenis) {
        totalSendiri = 0
        totalDitanggung = total + pajak
      } else {
        totalSendiri = total + pajak
        totalDitanggung = 0
      }
      this.listObat.push({
        // eslint-disable-next-line no-undef
        obat_id: this.obat_id,
        // eslint-disable-next-line no-undef
        obat_name: $('#itemObat option:selected').text(),
        signa: this.signa,
        jumlahObat: this.jumlahObat,
        dosis: this.dosis,
        unit_price: this.listObatDipilih.jumlah,
        self_amount: totalSendiri,
        pay_by_amount: totalDitanggung,
        catatanKhusus: this.catatanKhusus,
        tax1_amount: pajak,
        uom_id: this.listObatDipilih.uom_id,
        uom: this.listObatDipilih.uom,
        uom_conversion: this.listObatDipilih.uom_conversion
      })

      // localStorage.setItem('listObat', JSON.stringify(this.listObat))
      // eslint-disable-next-line no-undef
      $('#itemObat').empty()
      // this.hari1 = ''
      // this.hari2 = ''
      this.signa = ''
      this.jumlahObat = ''
      this.dosis = ''
      this.catatanKhusus = ''
    },
    umur (tglLahir) {
      var dob = new Date(tglLahir)
      var dobYear = dob.getYear()
      var dobMonth = dob.getMonth()
      var dobDate = dob.getDate()
      var yearAge = ''
      // get the current date from the system
      var now = new Date()
      // extract the year, month, and date from current date
      var currentYear = now.getYear()
      var currentMonth = now.getMonth()
      var currentDate = now.getDate()

      // declare a variable to collect the age in year, month, and days
      var age = {}
      var ageString = ''

      // get years
      yearAge = currentYear - dobYear

      // get months
      if (currentMonth >= dobMonth) {
        // get months when current month is greater
        var monthAge = currentMonth - dobMonth
      } else {
        yearAge--
        // eslint-disable-next-line no-redeclare
        var monthAge = 12 + currentMonth - dobMonth
      }

      // get days
      if (currentDate >= dobDate) {
        // get days when the current date is greater
        var dateAge = currentDate - dobDate
      } else {
        monthAge--
        // eslint-disable-next-line no-redeclare
        var dateAge = 31 + currentDate - dobDate

        if (monthAge < 0) {
          monthAge = 11
          yearAge--
        }
      }
      // group the age in a single variable
      age = {
        years: yearAge,
        months: monthAge,
        days: dateAge
      }

      if (age.years > 0 && age.months > 0 && age.days > 0) {
        ageString =
          age.years +
          ' Tahun, ' +
          age.months +
          ' Bulan, dan ' +
          age.days +
          ' Hari.'
      } else if (age.years === 0 && age.months === 0 && age.days > 0) {
        ageString = ' ' + age.days + ' Hari'
        // eslint-disable-next-line brace-style
      }
      // when current month and date is same as birth date and month
      else if (age.years > 0 && age.months === 0 && age.days === 0) {
        ageString = age.years + ' Tahun. Happy Birthday!!'
      } else if (age.years > 0 && age.months > 0 && age.days === 0) {
        ageString = age.years + ' Tahun dan ' + age.months + ' Bulan.'
      } else if (age.years === 0 && age.months > 0 && age.days > 0) {
        ageString = age.months + ' Bulan dan ' + age.days + ' Hari.'
      } else if (age.years > 0 && age.months === 0 && age.days > 0) {
        ageString = age.years + ' Tahun, dan' + age.days + ' Hari.'
      } else if (age.years === 0 && age.months > 0 && age.days === 0) {
        ageString = age.months + ' Bulan.'
        // eslint-disable-next-line brace-style
      }
      // when current date is same as dob(date of birth)
      else {
        ageString =
          age.years +
          ' Tahun, ' +
          age.months +
          ' Bulan, dan ' +
          age.days +
          ' Hari.'
      }

      // console.log(ageString)
      return ageString
    },

    getDataBpjsSep () {
      // eslint-disable-next-line no-undef
      $.get(
        baseUrlV2 + 'pasien/cek-sep',
        { reg_id: this.$route.query.registration_id },
        (res) => {
          // eslint-disable-next-line no-undef, no-unused-vars
          const { con, msg, results } = res
          if (con) {
            this.bpjs = results.sep
            this.kartuAnggota = results.kartuAnggota
            this.detailSep = results.detailSep
            this.isBpjs = true
            this.isSepAda = results.isSepAda
          } else {
            this.bpjs = ''
            this.isBpjs = false
            this.isSepAda = false
          }
        },
        'JSON'
      )
    },
    getDataObat () {
      // eslint-disable-next-line no-undef
      $.get(
        baseUrlV2 +
          'pasien/get-data-resep-non-racikan?folio_id=' +
          this.$route.query.folio_id,
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res

          if (con) {
            this.isOrder = true

            this.listObat = results.item_order.data
            if (results.status !== 'ORDER') {
              this.isDisabled = true
            }
          } else {
            this.listObat = []
            this.isDisabled = false
            this.isOrder = false
          }
        },
        'JSON'
      )
    },
    getDataPasien (v) {
      // eslint-disable-next-line camelcase
      const reg_id = this.$route.query.registration_id
      apiKedua
        // eslint-disable-next-line camelcase
        .get('emr/detail-pasien?rm=' + v + '&reg_id=' + reg_id, {})
        .then((response) => {
          if (response.data.con) {
            this.dataPatient = response.data.results
            this.data.demografi = this.dataPatient
            this.data.demografi.jenis_kelamin = {
              value: this.dataPatient.jenis_kelamin,
              text: this.dataPatient.jenis_kelamin
            }
            this.data.demografi.folio_id = this.$route.query.folio_id
            this.data.demografi.reg_id = this.$route.query.registration_id
            this.data.demografi.sub_unit_id = this.$route.query.sub_unit_id

            this.patient_name = response.data.results.nama_pasien
            const date = new Date(response.data.results.tanggal_lahir)

            const day = date.toLocaleString('default', { day: '2-digit' })
            const month = date.toLocaleString('default', { month: 'short' })
            const year = date.toLocaleString('default', { year: 'numeric' })
            // console.log(response.data.results.tanggal_lahir)
            const tglLahir = day + '/' + month + '/' + year
            this.data.demografi.umur = this.umur(tglLahir)
          }
          // localStorage.setItem('pasien', JSON.stringify(this.dataPatient))

          // this.getResumeRawatJalan(this.folioYangDipakai)
          // this.data.demografi.sub_unit_id = this.pegawai.sub_unit_id
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  },
  filters: {
    ifInArray: function (value) {
      return this.listObat.indexOf(value) > -1
    }
  }
}
</script>
<style>
form .col-form-label {
  font-size: small;
}
.float-group {
  position: fixed;
  bottom: 90px;
  right: 40px;
  color: #fff;
  text-align: center;
  z-index: 100;
}

.float {
  position: fixed;
  bottom: 40px;
  right: 40px;
  color: #fff;
  text-align: center;
  z-index: 100;
}
.borderless td,
.borderless th {
  border: none;
}
.select2-selection__clear {
  color: red; /* Change the color of the clear button */
  font-size: 14px; /* Adjust the font size */
}
</style>
