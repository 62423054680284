<template>
  <div class="mt-4 hantu">
    <table class="table table-bordered table-sm" style="font-size: 15px">
      <thead class="table-primary">
        <tr>
          <th class="text-center" width="85%">Rincian Obat</th>
          <th class="text-center" width="15%">Aksi</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, i) of historyObat" :key="i">
          <td>
            <b>Nama Pasien</b> :<br /> {{ item.patient_name }} <br />
            <b>Nama Dokter/Poliklinik/Rawat Inap</b> :
            {{ item.staff_name }} <br />
            <b>No. Registrasi</b> :<br /> {{ item.registration_no }}<br />
            <b>No. Folio</b> : {{ item.folio_no }}<br />
            <b>Tanggal Resep</b> :<br /> {{
              item.service_date | moment("DD MMMM YYYY")
            }} <br />
            <b>Status Resep</b> :<br /> &nbsp;<b class="badge badge-danger">{{
              item.med_status_name
            }}</b
            ><br />
          </td>
          <td style="text-align: center; padding-top: 20px">
            <v-btn
              @click="liatObat(item)"
              x-small
              dense
              color="red"
              dark
              title="Liat Obat"
              class="my-1"
              fab
              ><v-icon>mdi-list-box</v-icon></v-btn
            >
            <!-- <v-btn
              @click="copyObat(item)"
              x-small
              dense
              color="primary"
              title="Copy Resep"
              class="my-1"
              fab
              ><v-icon>mdi-pencil-box</v-icon></v-btn
            > -->
            <v-btn
              x-small
              dense
              color="purple"
              title="Print Resep"
              dark
              class="my-1"
              fab
              ><v-icon>mdi-printer</v-icon></v-btn
            >
          </td>
        </tr>
      </tbody>
    </table>
    <v-dialog v-model="showDialogListDetailObat" max-width="650">
      <v-card>
        <v-card-title class="headline"> Resep </v-card-title>
        <v-card-text>
          <table class="table table-bordered table-striped table-sm">
            <thead>
              <tr>
                <th class="text-center" width="45%">Nama Obat</th>
                <th class="text-center" width="10%">QTY</th>
                <th class="text-center" width="20%">Satuan</th>
                <th class="text-center" width="30%">Catatan/Dosis</th>
              </tr>
            </thead>
            <tbody v-for="(detailResep, z) of detailResepObat.item" :key="z">
              <tr v-if="detailResep.quantity >= 0  && detailResep.item_id != 4436 && detailResep.item_id != 4440">
                <td>{{ detailResep.item.item_name }}</td>
                <td class="text-center">{{ detailResep.quantity }}</td>
                <td class="text-center">{{ detailResep.uom }}</td>
                <td class="text-center">{{ detailResep.dosage }}</td>
              </tr>
            </tbody>
          </table>
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="copyObat(detailResepObat.item)"
            text
            color="deep-purple accent-4"
          >
            Copy Resep
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import {
  // eslint-disable-next-line no-unused-vars
  apiKedua,
  // eslint-disable-next-line no-unused-vars
  successMsg,
  // eslint-disable-next-line no-unused-vars
  baseUrl,
  // eslint-disable-next-line no-unused-vars
  baseUrlV2,
  // eslint-disable-next-line no-unused-vars
  errorMsg
} from '../plugins/supports'
export default {
  name: 'ListObatRacikan',
  data () {
    return {
      firebasePasien: '',
      historyObat: [],
      detailResepObat: [],
      showDialogListDetailObat: ''
    }
  },
  methods: {
    getHistoryObat (rm) {
      //   console.log('RMNYA INI ASU ' + rm)
      // eslint-disable-next-line no-undef
      $.get(
        baseUrlV2 + 'pasien/last-resep-v2?rm=' + this.$route.query.no_rm,
        (response) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = response
          if (con) {
            this.historyObat = results
            successMsg('Data Resep Obat Ditemukan')
          } else {
            errorMsg('Data Resep Obat Tidak Ada')
          }
        },
        'JSON'
      )
    },
    copyObat (item) {
      this.$root.$emit('NonRacikan', item) // like this
    },
    liatObat (item) {
      console.log(item)
      this.showDialogListDetailObat = true
      //   // eslint-disable-next-line no-unused-vars, no-undef
      this.detailResepObat = item
    }
  },
  created () {
    if (this.$route.query.no_rm != null) {
      this.getHistoryObat()
    }
  }
}
</script>
<style>
.hantu {
  padding: 2px;
  height: 660px;
  width: 100%;
  overflow: auto;
  scrollbar-width: thin !important;
}
</style>
