const data = [
  '1X1 sd makan (15ml)',
  '1X1 sd teh (5ml)',
  '1X1 Tablet ',
  '1X1 Kapsul',
  '1X1 Bungkus',
  '1X1 Unit',
  '1X1 Tetes',
  '1X1 Tetes per menit',
  '1X2 sd makan (15ml)',
  '1X2 sd teh (5ml)',
  '1X2 Tablet ',
  '1X2 Kapsul',
  '1X2 Bungkus',
  '1X2 Unit',
  '1X2 Tetes',
  '1X2 Tetes per menit',
  '1X3 sd makan (15ml)',
  '1X3 sd teh (5ml)',
  '1X3 Tablet ',
  '1X3 Kapsul',
  '1X3 Bungkus',
  '1X3 Unit',
  '1X3 Tetes',
  '1X3 Tetes per menit',
  '2X1 sd makan (15ml)',
  '2X1 sd teh (5ml)',
  '2X1 Tablet ',
  '2X1 Kapsul',
  '2X1 Bungkus',
  '2X1 Unit',
  '2X1 Tetes',
  '2X1 Tetes per menit',
  '2X2 sd makan (15ml)',
  '2X2 sd teh (5ml)',
  '2X2 Tablet ',
  '2X2 Kapsul',
  '2X2 Bungkus',
  '2X2 Unit',
  '2X2 Tetes',
  '2X2 Tetes per menit',
  '2X3 sd makan (15ml)',
  '2X3 sd teh (5ml)',
  '2X3 Tablet ',
  '2X3 Kapsul',
  '2X3 Bungkus',
  '2X3 Unit',
  '2X3 Tetes',
  '2X3 Tetes per menit',
  '3X1 sd makan (15ml)',
  '3X1 sd teh (5ml)',
  '3X1 Tablet ',
  '3X1 Kapsul',
  '3X1 Bungkus',
  '3X1 Unit',
  '3X1 Tetes',
  '3X1 Tetes per menit',
  '3X2 sd makan (15ml)',
  '3X2 sd teh (5ml)',
  '3X2Tablet ',
  '3X2 Kapsul',
  '3X2 Bungkus',
  '3X2 Unit',
  '3X2 Tetes',
  '3X2 Tetes per menit',
  '3X3 sd makan (15ml)',
  '3X3 sd teh (5ml)',
  '3X3 Tablet ',
  '3X3 Kapsul',
  '3X3 Bungkus',
  '3X3 Unit',
  '3X3 Tetes',
  '3X3 Tetes per menit',
  '4X1 sd makan (15ml)',
  '4X1 sd teh (5ml)',
  '4X1 Tablet ',
  '4X1 Kapsul',
  '4X1 Bungkus',
  '4X1 Unit',
  '4X1 Tetes',
  '4X1 Tetes per menit',
  '4X2 sd makan (15ml)',
  '4X2 sd teh (5ml)',
  '4X2Tablet ',
  '4X2 Kapsul',
  '4X2 Bungkus',
  '4X2 Unit',
  '4X2 Tetes',
  '4X2 Tetes per menit',
  '4X3 sd makan (15ml)',
  '4X3 sd teh (5ml)',
  '4X3 Tablet ',
  '4X3 Kapsul',
  '4X3 Bungkus',
  '4X3 Unit',
  '4X3 Tetes',
  '4X3 Tetes per menit',
  '5X1 sd makan (15ml)',
  '5X1 sd teh (5ml)',
  '5X1 Tablet ',
  '5X1 Kapsul',
  '5X1 Bungkus',
  '5X1 Unit',
  '5X1 Tetes',
  '5X1 Tetes per menit',
  '5X2 sd makan (15ml)',
  '5X2 sd teh (5ml)',
  '5X2Tablet ',
  '5X2 Kapsul',
  '5X2 Bungkus',
  '5X2 Unit',
  '5X2 Tetes',
  '5X2 Tetes per menit',
  '5X3 sd makan (15ml)',
  '5X3 sd teh (5ml)',
  '5X3 Tablet ',
  '5X3 Kapsul',
  '5X3 Bungkus',
  '5X3 Unit',
  '5X3 Tetes',
  '5X3 Tetes per menit',
  '6X1 sd makan (15ml)',
  '6X1 sd teh (5ml)',
  '6X1 Tablet ',
  '6X1 Kapsul',
  '6X1 Bungkus',
  '6X1 Unit',
  '6X1 Tetes',
  '6X1 Tetes per menit',
  '6X2 sd makan (15ml)',
  '6X2 sd teh (5ml)',
  '6X2Tablet ',
  '6X2 Kapsul',
  '6X2 Bungkus',
  '6X2 Unit',
  '6X2 Tetes',
  '6X2 Tetes per menit',
  '6X3 sd makan (15ml)',
  '6X3 sd teh (5ml)',
  '6X3 Tablet ',
  '6X3 Kapsul',
  '6X3 Bungkus',
  '6X3 Unit',
  '6X3 Tetes',
  '6X3 Tetes per menit',
  '1X1',
  '1X2',
  '1X3',
  '2X1',
  '2X2',
  '2X3',
  '3X1',
  '3X2',
  '3X3',
  '1X1 Sesudah Makan',
  '1X2 Sesudah Makan',
  '1X3 Sesudah Makan',
  '2X1 Sesudah Makan',
  '2X2 Sesudah Makan',
  '2X3 Sesudah Makan',
  '3X1 Sesudah Makan',
  '3X2 Sesudah Makan',
  '3X3 Sesudah Makan',
  '1X1 Suppos',
  '2X1 Suppos',
  '3X1 Suppos',
  'OK'
]

export default data
