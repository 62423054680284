<template>
  <div class="m-2">
    <div class="mt-1" v-if="data.demografi.notes">
      <v-alert dense shaped prominent type="error">
        Catatan Adm : {{ data.demografi.notes }}
      </v-alert>
    </div>
    <v-card color="teal darken-1" dark>
      <v-card-text>
        <v-row class="mt-1 p-1 mb-0">
          <v-col
            v-for="(v, i) in master.demografi"
            :md="v.col ? v.col : 12"
            :lg="v.col ? v.col : 12"
            :sm="v.col ? v.col : 12"
            cols="12"
            class="mt-1 mb-1 pt-1 pb-1"
            :key="i"
          >
            <smart-widget
              :comp="v.widget"
              :sync-value="(e) => (data.demografi[i] = e)"
              :value="data.demografi[i]"
              vclass="c-text-field"
              :data="v.data"
              :label="v.label ? v.label : i.replaceAll('_', ' ')"
              :rules="[rules.required]"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card dense class="mt-2" v-if="isBpjs && isSepAda">
      <v-card-text>
        <table border="0" class="table table-borderless table-sm">
          <tr>
            <td width="50%" valign="middle">
              <img
                src="../assets/bpjs-logo.png"
                width="170"
                height="28"
                align="center"
              />
            </td>
            <td
              align="left"
              width="50%"
              valign="middle"
              style="font-size: 14px"
            >
              SURAT ELEGIBILITAS PESERTA RS SYAFIRA
            </td>
          </tr>
        </table>
        <table
          cellpadding="2"
          class="table table-bordeless table-sm"
          style="font-size: 12px"
        >
          <tr>
            <td width="123px">No. SEP</td>
            <td width="310px">: &nbsp;{{ bpjs.noSep }}</td>
            <td width="90px"></td>
            <td></td>
          </tr>
          <tr>
            <td>Nama Peserta</td>
            <td>:&nbsp;{{ bpjs.namaPeserta }}</td>
            <td>Potensi PRB</td>
            <td>
              :&nbsp;<b class="text-danger" style="font-size: 18px">{{
                kartuAnggota.results.peserta?.informasi.prolanisPRB
              }}</b>
            </td>
          </tr>
          <tr>
            <td>Tgl. Lahir</td>
            <td>
              :&nbsp;{{
                this.data.demografi.tanggal_lahir | moment("DD MMMM YYYY")
              }}
            </td>
            <td>Jns. Rawat</td>
            <td>:&nbsp;{{ detailSep?.jnsPelayanan }}</td>
          </tr>
          <tr>
            <td>No. Telepon</td>
            <td>:&nbsp;{{ kartuAnggota.results.peserta?.mr.noTelepon }}</td>
            <td>Kls. Rawat</td>
            <td>
              :
              {{ kartuAnggota.results.peserta?.hakKelas.keterangan }}
            </td>
          </tr>
          <tr>
            <td>Sub/Spesialis</td>
            <td>:&nbsp;{{ bpjs.poli }}</td>
            <td>Penjamin</td>
            <td>:&nbsp;{{ detailSep?.penjamin }}</td>
          </tr>

          <tr>
            <td style="vertical-align: top">Diagnosa Awal</td>
            <td>: &nbsp;{{ bpjs.diagnosa }}</td>
            <td colspan="2"></td>
          </tr>
        </table>
      </v-card-text>
    </v-card>
    <v-card class="mt-2">
      <v-card-text>
        <v-alert dense shaped prominent type="error">
          <span
            ><b>Tanggal Pelayanan :</b> {{ data.demografi.tgl_pelayanan }}</span
          >
        </v-alert>
        <form class="form-horizontal mt-5" id="form">
          <div class="form-group row">
            <label for="inputEmail3" class="col-sm-2 col-form-label"
              ><b>Racikan</b></label
            >
            <div class="col-sm-10">
              <select
                id="racikan"
                @change="addRacikan"
                v-model="selectedRacikan"
                class="form-control form-control-sm"
              >
                <option value="0"></option>
                <option v-for="(item, i) in listRacikan" :key="i">
                  {{ item }}
                </option>
              </select>
            </div>
          </div>

          <div class="form-group row">
            <label for="inputEmail3" class="col-sm-2 col-form-label"
              ><b>Obat</b></label
            >
            <div class="col-sm-10">
              <select id="itemObatRacikan" class="form-control form-control-sm">
                <option value="0"></option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div v-if="selectedRacikan !== '0'">
                <button
                  class="btn btn-primary btn-block btn-sm mt-1 text-white"
                  type="button"
                  :disabled="isDisabled"
                  @click="addObat"
                >
                  Add obat <span class="fa fa-plus"></span>
                </button>
              </div>
            </div>
            <div class="col-md-6">
              <label><b>History Order Resep</b></label>
              <select
                id="historyResepRacikan"
                class="form-control form-control-sm"
              >
                <option value="0"></option>
              </select>
              <div v-if="isCetak">
                <v-btn
                  @click="cetakResep"
                  type="button"
                  color="primary"
                  class="btn btn-sm btn-block mt-5"
                  dark
                >
                  Liat Resep
                </v-btn>
              </div>
            </div>
          </div>
        </form>
      </v-card-text>
    </v-card>
    <v-card class="mt-2" v-if="isOrder">
      <v-card-text class="text-center" elevation="3">
        <h1 class="text-danger">~Orderan Sudah Dibuat~</h1>
      </v-card-text>
    </v-card>
    <div v-for="(itemRacikan, r) in banyakRacikan" :key="r">
      <table class="table table-bordered table-md mt-4" style="font-size: 13px">
        <thead class="bg-success text-white">
          <tr>
            <th colspan="2">
              Nama Racikan :
              <b
                ><i>{{ itemRacikan.id_racikan }}</i></b
              >
            </th>
            <th
              colspan="1"
              @click="deleteRacikan(r)"
              style="text-align: center"
            >
              <v-btn dense :disabled="isDisabled" fab x-small dark color="red"
                ><v-icon>mdi-close</v-icon></v-btn
              >
            </th>
          </tr>

          <tr>
            <th width="6%" class="text-center">Aksi</th>
            <th class="text-center" width="53.3%">Nama Obat</th>
            <th class="text-center" width="23.3%">Keterangan</th>
          </tr>
        </thead>
        <tbody v-for="(itemObat, o) in listObat" :key="o">
          <!-- <div v-if="selectedRacikan"></div> -->
          <tr v-if="itemObat.id_racikan === itemRacikan.id_racikan">
            <td style="vertical-align: middle; text-align: center">
              <v-btn
                :disabled="isDisabled"
                @click="deleteObat(o)"
                dense
                dark
                fab
                x-small
                color="red"
                ><v-icon>mdi-close</v-icon></v-btn
              >
            </td>
            <td class="text-center" style="vertical-align: middle">
              <!-- <b>{{  }}</b> <br /> -->
              <v-row>
                <v-col cols="12" lg="12" sm="12" md="12">
                  <!-- itemObat.obat_name -->
                  <v-text-field
                    v-model="itemObat.obat_name"
                    label="Nama Obat"
                    disabled
                    outlined
                    required
                  ></v-text-field>
                  <v-text-field
                    v-model="itemObat.dosis"
                    label="Dosis Obat"
                    required
                    :disabled="isDisabled"
                    hint="Dosis Obat Satuannya Dituliskan Contoh : 100 mg/ atau 1/2 tab"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" lg="3" sm="3" md="3" style="display: none">
                  <v-text-field
                    v-model="itemObat.uom"
                    label="Satuan Obat"
                    :disabled="isDisabled"
                    outlined
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </td>
            <td style="vertical-align: middle">
              <v-textarea
                v-model="itemObat.catatanKhusus"
                rows="5"
                class="mt-1"
                :disabled="isDisabled"
                outlined
                placeholder="Keterangan Obat"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <table class="table borderless table-sm" style="font-size: 11px">
        <tr>
          <td style="vertical-align: middle">
            <v-text-field
              type="number"
              label="Jumlah Racikan"
              class="mt-1"
              outlined
              :disabled="isDisabled"
              v-model="itemRacikan.jumlahObat"
            />

            <!-- <v-text-field
              type="text"
              label="Dosis"
              class="mt-1"
              v-model="itemRacikan.dosis"
            /> -->
          </td>
          <td style="vertical-align: middle">
            <!-- <v-text-field
              type="text"
              label="Signa"
              class="mt-1"
              v-model="itemRacikan.signa"
            /> -->
            <v-autocomplete
              v-model="itemRacikan.dosis"
              :items="listDosis"
              label="Signa"
              persistent-hint
              outlined
              :disabled="isDisabled"
              prepend-icon="mdi-city"
            >
            </v-autocomplete>
          </td>
          <td style="vertical-align: middle">
            <v-autocomplete
              v-model="itemRacikan.signa"
              :items="listSigna"
              label="Waktu Pemberian"
              persistent-hint
              outlined
              :disabled="isDisabled"
              prepend-icon="mdi-city"
            >
            </v-autocomplete>
          </td>
        </tr>
        <tr>
          <td colspan="3" style="vertical-align: middle">
            <v-textarea
              v-model="itemRacikan.catataKhususRacikan"
              rows="2"
              :disabled="isDisabled"
              outlined
              label="Keterangan Racikan"
            ></v-textarea>
          </td>
        </tr>
      </table>
    </div>
    <div class="form-group">
      <div
        style="z-index: 999999"
        class="float-group btn-group-vertical"
        role="group"
        aria-label="Vertical button group"
      >
        <div class="row">
          <div class="col-md-12">
            <div v-if="listObat.length">
              <button
                @click="simpanObat"
                class="btn btn-danger text-white btn-md"
                type="button"
                :disabled="isDisabled"
              >
                Simpan Resep Racikan
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SmartWidget from '@/components/SmartWidget.vue'

// eslint-disable-next-line no-unused-vars
import signa from '@/data/pemakaian.js'
// eslint-disable-next-line no-unused-vars
import dosis from '@/data/takaran.js'
import {
  // eslint-disable-next-line no-unused-vars
  apiKedua,
  // eslint-disable-next-line no-unused-vars
  successMsg,
  // eslint-disable-next-line no-unused-vars
  baseUrl,
  // eslint-disable-next-line no-unused-vars
  baseUrlV2,
  // eslint-disable-next-line no-unused-vars
  errorMsg,
  // eslint-disable-next-line no-unused-vars
  baseUrlEmr
} from '../plugins/supports'
export default {
  name: 'NonRacikan',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    SmartWidget
  },
  data () {
    return {
      listObat: [],
      banyakRacikan: [],
      kartuAnggota: '',
      detailSep: '',
      isSepAda: '',
      isBpjs: false,
      listRacikan: [
        'Racikan 1',
        'Racikan 2',
        'Racikan 3',
        'Racikan 4',
        'Racikan 5'
      ],
      master: {
        demografi: {
          nama_pasien: {
            widget: 'wtext',
            data: null,
            col: 3,
            label: 'Nama Pasien'
          },
          no_rekam_medis: {
            widget: 'wtext',
            data: null,
            col: 2,
            label: 'No. RM'
          },
          tanggal_lahir: {
            widget: 'wdate',
            data: null,
            col: 3,
            label: 'Tanggal Lahir'
          },
          cara_bayar: {
            label: 'Cara Bayar',
            widget: 'wtext',
            data: null,
            col: 4
          },
          folio_id: {
            widget: 'wtext',
            data: null,
            col: 2,
            label: 'No Folio'
          },
          sub_unit_id: {
            widget: 'wtext',
            data: null,
            col: 2,
            label: 'Sub Unit Dokter'
          },
          reg_id: {
            widget: 'wtext',
            data: null,
            label: 'No Reg',
            col: 2
          },
          umur: {
            widget: 'wtext',
            data: null,
            col: 6,
            label: 'Umur'
          },
          jenis_kelamin: {
            widget: 'wradio',
            data: [
              { text: 'Laki-Laki', value: 'Laki-Laki' },
              { text: 'Perempuan', value: 'Perempuan' }
            ],
            col: 6
          },
          notes: '',
          kelompok_cara_bayar: ''
        }
      },
      selectedRacikan: '0',
      optionRacikan: '',
      obat: '',
      signa: '',
      hari1: '',
      listObatDipilih: '',
      isCetak: false,
      hari2: '',
      isOrder: false,
      jumlahObat: '',
      isDisabled: false,
      pegawai: '',
      id_order_resep: '',
      catatanKhusus: '',
      hari: '',
      dosis: '',
      listSigna: '',
      listDosis: '',
      url_emr: 'http://emr.rs-syafira.com/',
      data: {
        demografi: {
          nama_pasien: '',
          no_rekam_medis: '',
          tanggal_lahir: '',
          jenis_kelamin: '',
          cara_bayar: '',
          folio_id: '',
          reg_id: '',
          sub_unit_id: '',
          umur: '',
          handphone: ''
        }
      },
      rules: {
        required: (value) => {
          return true
        }
      }
    }
  },
  created () {
    this.pegawai = JSON.parse(localStorage.getItem('user'))
    this.listSigna = signa
    this.listDosis = dosis
    if (localStorage.getItem('firebasePasien')) {
      setTimeout(() => {
        this.firebasePasien = JSON.parse(
          localStorage.getItem('firebasePasien')
        )
        this.pegawai = JSON.parse(localStorage.getItem('user'))

        // this.getHistoryResume()
      }, 1000)
    }
    if (this.$route.query.no_rm != null) {
      this.getDataPasien(this.$route.query.no_rm)
      this.getDataObat()
    }
  },
  mounted () {
    this.select2()
    this.select3()
    this.$root.$on('Racikan', (item) => {
      // console.log(item + ' KIRIMAN')
      this.addObatByComponent(item)
    })
    if (this.$route.query.no_rm != null) {
      this.getDataPasien(this.$route.query.no_rm)
      this.getDataBpjsSep()
    }
  },
  methods: {
    select2 () {
      // eslint-disable-next-line no-undef, no-unused-vars
      var self = this

      // eslint-disable-next-line no-undef
      $('#itemObatRacikan')
        .select2({
          ajax: {
            url: baseUrlV2 + 'pasien/get-data-obat',
            type: 'post',
            dataType: 'json',
            delay: 250,
            data: function (params) {
              return {
                searchTerm: params.term, // search term
                reg_id: self.$route.query.registration_id
              }
            },
            processResults: function (response) {
              return {
                results: response
              }
            },
            cache: true
          }
        })
        .on('select2:select', function (evt) {
          console.log('hasil SELECT ' + JSON.stringify(evt.params.data))

          self.listObatDipilih = evt.params.data
        })
    },
    addObatByComponent (item) {
      // console.log(item + 'Jenis Item')
      // eslint-disable-next-line no-unused-vars
      const listObat = []
      // eslint-disable-next-line no-unused-vars
      // eslint-disable-next-line no-unused-vars
      var idRacikan = ''
      // eslint-disable-next-line no-unused-vars
      var dataRacikan = []
      // eslint-disable-next-line no-unused-vars
      const dataRacikanDecode = item.dataResep
      // eslint-disable-next-line no-unused-vars
      var self = this

      this.banyakRacikan.forEach((val, index) => {
        // eslint-disable-next-line no-undef
        if (val.id_racikan === item.namaResep) {
          self.banyakRacikan.splice(index, 1)
          // errorMsg('Item Obat Sudah')
          // console.log(val)
          return false
        }
      })
      this.banyakRacikan.push({
        id_racikan: item.namaResep,
        catataKhususRacikan: this.catatanKhusus,
        signa: '',
        jumlahObat: '',
        dosis: ''
      })

      for (const itemByOrderObat of dataRacikanDecode) {
        this.listObat.push({
          id_racikan: item.namaResep,
          obat_id: itemByOrderObat.material_item_id,
          unit_price: itemByOrderObat.unit_price,
          uom: itemByOrderObat.uom,
          qty: itemByOrderObat.quantity, // eslint-disable-next-line no-undef
          dosis: itemByOrderObat.quantity, // eslint-disable-next-line no-undef
          obat_name: itemByOrderObat.materialItem.item_name,
          catatanKhusus: ''
        })
      }
      // this.listObat = listObat
    },
    addObatByHistoryOrder (itemObatByOrder) {
      // eslint-disable-next-line no-unused-vars
      const listObat = []
      // eslint-disable-next-line no-unused-vars
      const banyakRacikan = []

      if (!itemObatByOrder.dataRacikan) {
        errorMsg('Racikan Tidak Ditemukan :(')
        return
      }
      // console.log(itemObatByOrder.dataRacikan)
      for (const itemByOrder of itemObatByOrder.dataRacikan) {
        banyakRacikan.push({
          // eslint-disable-next-line no-undef
          id_racikan: itemByOrder.id_racikan,
          catataKhususRacikan: itemByOrder.catataKhususRacikan,
          signa: itemByOrder.signa,
          jumlahObat: itemByOrder.jumlahObat,
          dosis: itemByOrder.dosis
        })
      }
      for (const itemByOrderObat of itemObatByOrder.data) {
        listObat.push({
          id_racikan: itemByOrderObat.id_racikan,
          obat_id: itemByOrderObat.obat_id,
          dosis: itemByOrderObat.dosis,
          uom: itemByOrderObat.uom, // eslint-disable-next-line no-undef

          // eslint-disable-next-line no-undef
          obat_name: itemByOrderObat.obat_name,
          catatanKhusus: ''
        })
      }

      this.banyakRacikan = banyakRacikan
      this.listObat = listObat
    },
    select3 () {
      var self = this

      // eslint-disable-next-line no-undef
      $('#historyResepRacikan')
        .select2({
          ajax: {
            url: baseUrlV2 + 'pasien/history-resep?type=1',
            type: 'post',
            dataType: 'json',
            delay: 250,
            data: function (params) {
              return {
                searchTerm: params.term, // search term,
                no_rm: self.$route.query.no_rm,
                sub: self.$route.query.sub_unit_id
              }
            },
            processResults: function (response) {
              console.log(response)
              // this.addObatByHistoryOrder(response.detail)
              return {
                results: response
                // hasi
              }
            },
            cache: true
          }
        })
        .on('select2:select', function (evt) {
          // eslint-disable-next-line no-undef
          // var data = $('#historyResep option:selected').text()
          // alert('Data yang dipilih adalah ' + evt)
          // eslint-disable-next-line no-undef
          self.id_order_resep = evt.params.data.id_order_resep
          self.isCetak = true
          self.addObatByHistoryOrder(evt.params.data.detailRacikan)

          // console.log(evt.params.data.detail)
        })
    },
    getDataBpjsSep () {
      // eslint-disable-next-line no-undef
      $.get(
        baseUrlV2 + 'pasien/cek-sep',
        { reg_id: this.$route.query.registration_id },
        (res) => {
          // eslint-disable-next-line no-undef, no-unused-vars
          const { con, msg, results } = res
          if (con) {
            this.bpjs = results.sep
            this.kartuAnggota = results.kartuAnggota
            this.detailSep = results.detailSep
            this.isBpjs = true
            this.isSepAda = results.isSepAda
          } else {
            this.bpjs = ''
            this.isBpjs = false
            this.isSepAda = false
          }
        },
        'JSON'
      )
    },
    addObat () {
      // eslint-disable-next-line no-undef
      this.obat_id = $('#itemObatRacikan').val()

      this.listObat.push({
        id_racikan: this.selectedRacikan,
        obat_id: this.obat_id,
        // signa: '',
        // jumlahObat: '',
        uom_id: this.listObatDipilih.uom_id,
        unit_price: this.listObatDipilih.jumlah,
        qty: 0,
        uom: this.listObatDipilih.uom,
        dosis: '',
        // eslint-disable-next-line no-undef
        obat_name: $('#itemObatRacikan option:selected').text(),
        catatanKhusus: ''
      })
      // eslint-disable-next-line no-undef
      $('#itemObatRacikan').empty()
    },
    addRacikan () {
      // eslint-disable-next-line no-undef
      //   this.optionRacikan = $('#racikan').val()
      if (this.selectedRacikan !== '0') {
        var self = this

        this.banyakRacikan.forEach((val, index) => {
          // eslint-disable-next-line no-undef
          if (val.id_racikan === this.selectedRacikan) {
            self.banyakRacikan.splice(index, 1)
            // errorMsg('Item Obat Sudah')
            // console.log(val)
            return false
          }
        })
        this.banyakRacikan.push({
          id_racikan: this.selectedRacikan,
          catataKhususRacikan: this.catatanKhusus,
          signa: '',
          jumlahObat: '',
          dosis: ''
        })
      } else {
        this.banyakRacikan = []
      }

      //   console.log(this.banyakRacikan)
    },
    deleteRacikan (index) {
      this.banyakRacikan.splice(index, 1)
    },
    deleteObat (index) {
      this.listObat.splice(index, 1)
    },
    cetakResep () {
      console.log(this.id_order_resep)

      window
        .open(
          baseUrlEmr +
            'laporan-medis/cetak-resep?id_resep=' +
            this.id_order_resep,
          '_blank'
        )
        .focus()
    },
    getDataPasien (v) {
      // eslint-disable-next-line camelcase
      const reg_id = this.$route.query.registration_id
      apiKedua
        // eslint-disable-next-line camelcase
        .get('emr/detail-pasien?rm=' + v + '&reg_id=' + reg_id, {})
        .then((response) => {
          if (response.data.con) {
            this.dataPatient = response.data.results
            this.data.demografi = this.dataPatient
            this.data.demografi.jenis_kelamin = {
              value: this.dataPatient.jenis_kelamin,
              text: this.dataPatient.jenis_kelamin
            }
            this.data.demografi.folio_id = this.$route.query.folio_id
            this.data.demografi.reg_id = this.$route.query.registration_id
            this.data.demografi.sub_unit_id = this.$route.query.sub_unit_id

            this.patient_name = response.data.results.nama_pasien
            const date = new Date(response.data.results.tanggal_lahir)

            const day = date.toLocaleString('default', { day: '2-digit' })
            const month = date.toLocaleString('default', { month: 'short' })
            const year = date.toLocaleString('default', { year: 'numeric' })
            // console.log(response.data.results.tanggal_lahir)
            const tglLahir = day + '/' + month + '/' + year
            this.data.demografi.umur = this.umur(tglLahir)
          }
          // localStorage.setItem('pasien', JSON.stringify(this.dataPatient))

          // this.getResumeRawatJalan(this.folioYangDipakai)
          // this.data.demografi.sub_unit_id = this.pegawai.sub_unit_id
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    getDataObat () {
      // eslint-disable-next-line no-undef
      $.get(
        baseUrlV2 +
          'pasien/get-data-resep-racikan?folio_id=' +
          this.$route.query.folio_id,
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res

          if (con) {
            this.banyakRacikan = results?.item_order_racikan?.dataRacikan
            this.listObat = results.item_order_racikan.data
            //   this.listObat = results.item_order.data
            this.isOrder = true

            if (results.status !== 'ORDER') {
              this.isDisabled = true
            }
          } else {
            this.listObat = []
            this.isOrder = false
            this.isDisabled = false
          }
        },
        'JSON'
      )
    },
    umur (tglLahir) {
      var dob = new Date(tglLahir)
      var dobYear = dob.getYear()
      var dobMonth = dob.getMonth()
      var dobDate = dob.getDate()
      var yearAge = ''
      // get the current date from the system
      var now = new Date()
      // extract the year, month, and date from current date
      var currentYear = now.getYear()
      var currentMonth = now.getMonth()
      var currentDate = now.getDate()

      // declare a variable to collect the age in year, month, and days
      var age = {}
      var ageString = ''

      // get years
      yearAge = currentYear - dobYear

      // get months
      if (currentMonth >= dobMonth) {
        // get months when current month is greater
        var monthAge = currentMonth - dobMonth
      } else {
        yearAge--
        // eslint-disable-next-line no-redeclare
        var monthAge = 12 + currentMonth - dobMonth
      }

      // get days
      if (currentDate >= dobDate) {
        // get days when the current date is greater
        var dateAge = currentDate - dobDate
      } else {
        monthAge--
        // eslint-disable-next-line no-redeclare
        var dateAge = 31 + currentDate - dobDate

        if (monthAge < 0) {
          monthAge = 11
          yearAge--
        }
      }
      // group the age in a single variable
      age = {
        years: yearAge,
        months: monthAge,
        days: dateAge
      }

      if (age.years > 0 && age.months > 0 && age.days > 0) {
        ageString =
          age.years +
          ' Tahun, ' +
          age.months +
          ' Bulan, dan ' +
          age.days +
          ' Hari.'
      } else if (age.years === 0 && age.months === 0 && age.days > 0) {
        ageString = ' ' + age.days + ' Hari'
        // eslint-disable-next-line brace-style
      }
      // when current month and date is same as birth date and month
      else if (age.years > 0 && age.months === 0 && age.days === 0) {
        ageString = age.years + ' Tahun. Happy Birthday!!'
      } else if (age.years > 0 && age.months > 0 && age.days === 0) {
        ageString = age.years + ' Tahun dan ' + age.months + ' Bulan.'
      } else if (age.years === 0 && age.months > 0 && age.days > 0) {
        ageString = age.months + ' Bulan dan ' + age.days + ' Hari.'
      } else if (age.years > 0 && age.months === 0 && age.days > 0) {
        ageString = age.years + ' Tahun, dan' + age.days + ' Hari.'
      } else if (age.years === 0 && age.months > 0 && age.days === 0) {
        ageString = age.months + ' Bulan.'
        // eslint-disable-next-line brace-style
      }
      // when current date is same as dob(date of birth)
      else {
        ageString =
          age.years +
          ' Tahun, ' +
          age.months +
          ' Bulan, dan ' +
          age.days +
          ' Hari.'
      }

      // console.log(ageString)
      return ageString
    },
    simpanObat () {
      // console.log(this.banyakRacikan)
      // console.log(this.listObat)

      // eslint-disable-next-line no-unused-vars
      const firebasePasien = {
        no_rm: this.$route.query.no_rm,
        folio_id: this.$route.query.folio_id,
        reg_id: this.$route.query.registration_id,
        sub_unit_id: this.$route.query.sub_unit_id
      }
      var idcArray = []
      for (const item of this.listObat) {
        // const text = item.text.split('/')
        // const icd = text[1]
        const icd =
          '<b>Nama Obat : </b><span class="text-danger">' +
          item.obat_name +
          ' - </span><b>Dosis : </b><span class="text-danger">' +
          item.dosis +
          '</span></br>'
        idcArray.push(icd)
      }
      const html = '<br> <b>Ingin Membuat Resep Dengan Item Di Atas?</b>'

      // console.log(data)
      // eslint-disable-next-line no-undef
      Swal.fire({
        title: `Perhatian! Apakah Anda Yakin Ingin Membuat Resep Racikan Dengan No.RM ${this.$route.query.no_rm} Dengan  Nama Pasien ${this.data.demografi.nama_pasien} ?`,
        text: 'Ingin Membuat Resep Racika Racikan Ini',
        icon: 'warning',
        width: 980,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya, Buat Resep!',
        cancelButtonText: 'Tidak Jadi Batal',
        html: idcArray.join(' ') + html
      }).then((result) => {
        if (result.value) {
          const data = {
            identitas: firebasePasien,
            data: this.listObat,
            dataRacikan: this.banyakRacikan,
            pegawai: this.pegawai
          }
          // eslint-disable-next-line no-undef
          $.post(
            baseUrlV2 + 'pasien/simpan-resep-obat-racikan',
            data,
            (response) => {
              // eslint-disable-next-line no-unused-vars
              const { con, msg, results } = response
              if (con) {
                successMsg(msg)
                this.$root.$emit('COPYRESEPRACIKAN', this.listObat) // like this
                // this.listObat = []
                // this.banyakRacikan = []
              } else {
                errorMsg(msg + ' ' + JSON.parse(results))
              }
            },
            'JSON'
          )
        } else {
          errorMsg('Nggak Jadi Deh !!!')
        }
      })
    }
  }
}
</script>
<style>
.borderless td,
.borderless th {
  border: none;
}
</style>
