<template>
  <div class="m-4">
    <v-row justify="center">
      <v-col cols="12" lg="12" sm="12" md="12">
        <v-progress-linear
          v-show="progress"
          color="primary"
          indeterminate
          rounded
          height="6"
        />
        <v-card color="teal darken-1" dark>
          <v-card-text>
            <v-btn @click="showDialogList= true">List Transfer</v-btn>
            <v-row class="mt-1 p-2 mb-0">
              <v-col
                v-for="(v, i) in master.demografi"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                class="mt-1 mb-1 pt-2 pb-1"
                :key="i"
              >
                <!--                {{v}}-->
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.demografi[i] = e)"
                  :value="data.demografi[i]"
                  vclass="c-text-field"
                  :data="v.data"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-divider class="mt-3 mb-3" />
        <div class="font-weight-bold mb-3">Form Pindah Ruangan</div>
        <v-row class="mt-0 mb-0">
          <v-col
            v-for="(v, i) in master.form0"
            :md="v.col ? v.col : 12"
            :lg="v.col ? v.col : 12"
            :sm="v.col ? v.col : 12"
            cols="12"
            class="mt-0 mb-0 pt-0 pb-3"
            :key="i"
          >
            <smart-widget
              :comp="v.widget"
              :sync-value="(e) => (data.form0[i] = e)"
              :value="data.form0[i]"
              vclass="c-text-field"
              :data="v.data"
              :disabled="v.disabled"
              :property="v.property"
              :label="v.label ? v.label : i.replaceAll('_', ' ')"
              :rules="[rules.required]"
            />
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <div class="font-weight-bold mb-3 mt-2">I. RINGKASAN RAWAT PASIEN</div>
        <v-row class="mt-0 mb-0">
          <v-col
            v-for="(v, i) in master.form1"
            :md="v.col ? v.col : 12"
            :lg="v.col ? v.col : 12"
            :sm="v.col ? v.col : 12"
            cols="12"
            class="mt-0 mb-0 pt-0 pb-3"
            :key="i"
          >
            <smart-widget
              :comp="v.widget"
              :sync-value="(e) => (data.form1[i] = e)"
              :value="data.form1[i]"
              vclass="c-text-field"
              :data="v.data"
              :property="v.property"
              :label="v.label ? v.label : i.replaceAll('_', ' ')"
              :rules="[rules.required]"
            />
          </v-col>
        </v-row>
        <v-divider class="mt-3 mb-3" />
        <div class="font-weight-bold mb-3">
          II. PEMERIKSAAN PENUNJANG YANG SUDAH DILAKUKAN
        </div>
        <v-row class="mt-0 mb-0">
          <v-col
            v-for="(v, i) in master.form2"
            :md="v.col ? v.col : 12"
            :lg="v.col ? v.col : 12"
            :sm="v.col ? v.col : 12"
            cols="12"
            class="mt-0 mb-0 pt-0 pb-3"
            :key="i"
          >
            <smart-widget
              :comp="v.widget"
              :sync-value="(e) => (data.form2[i] = e)"
              :value="data.form2[i]"
              vclass="c-text-field"
              :data="v.data"
              :property="v.property"
              :label="v.label ? v.label : i.replaceAll('_', ' ')"
              :rules="[rules.required]"
            />
          </v-col>
        </v-row>
        <v-divider class="mt-3 mb-3" />
        <div class="font-weight-bold mb-3">III. DIAGNOSIS</div>
        <v-row class="mt-0 mb-0">
          <v-col
            v-for="(v, i) in master.form3"
            :md="v.col ? v.col : 12"
            :lg="v.col ? v.col : 12"
            :sm="v.col ? v.col : 12"
            cols="12"
            class="mt-0 mb-0 pt-0 pb-3"
            :key="i"
          >
            <smart-widget
              :comp="v.widget"
              :sync-value="(e) => (data.form3[i] = e)"
              :value="data.form3[i]"
              vclass="c-text-field"
              :data="v.data"
              :property="v.property"
              :label="v.label ? v.label : i.replaceAll('_', ' ')"
              :rules="[rules.required]"
            />
          </v-col>
        </v-row>
        <v-divider class="mt-3 mb-3" />
        <div class="font-weight-bold mb-3">IV. INDIKASI TRANSFER</div>
        <v-row class="mt-0 mb-0">
          <v-col
            v-for="(v, i) in master.form4"
            :md="v.col ? v.col : 12"
            :lg="v.col ? v.col : 12"
            :sm="v.col ? v.col : 12"
            cols="12"
            class="mt-0 mb-0 pt-0 pb-3"
            :key="i"
          >
            <smart-widget
              :comp="v.widget"
              :sync-value="(e) => (data.form4[i] = e)"
              :value="data.form4[i]"
              vclass="c-text-field"
              :data="v.data"
              :property="v.property"
              :label="v.label ? v.label : i.replaceAll('_', ' ')"
              :rules="[rules.required]"
            />
          </v-col>
        </v-row>
        <v-divider class="mt-3 mb-3" />
        <div class="font-weight-bold mb-3">
          V. TINDAKAN MEDIS YANG SUDAH DILAKUKAN
        </div>
        <v-row class="mt-0 mb-0">
          <v-col
            v-for="(v, i) in master.form5"
            :md="v.col ? v.col : 12"
            :lg="v.col ? v.col : 12"
            :sm="v.col ? v.col : 12"
            cols="12"
            class="mt-0 mb-0 pt-0 pb-3"
            :key="i"
          >
            <smart-widget
              :comp="v.widget"
              :sync-value="(e) => (data.form5[i] = e)"
              :value="data.form5[i]"
              vclass="c-text-field"
              :data="v.data"
              :property="v.property"
              :label="v.label ? v.label : i.replaceAll('_', ' ')"
              :rules="[rules.required]"
            />
          </v-col>
        </v-row>
        <v-divider class="mt-3 mb-3" />
        <div class="font-weight-bold mb-3">VI. PEMBERIAN TERAPI</div>
        <v-row class="mt-0 mb-0">
          <v-col
            v-for="(v, i) in master.form6"
            :md="v.col ? v.col : 12"
            :lg="v.col ? v.col : 12"
            :sm="v.col ? v.col : 12"
            cols="12"
            class="mt-0 mb-0 pt-0 pb-3"
            :key="i"
          >
            <smart-widget
              :comp="v.widget"
              :sync-value="(e) => (data.form6[i] = e)"
              :value="data.form6[i]"
              vclass="c-text-field"
              :data="v.data"
              :property="v.property"
              :label="v.label ? v.label : i.replaceAll('_', ' ')"
              :rules="[rules.required]"
            />
          </v-col>
        </v-row>
        <v-divider class="mt-3 mb-3" />
        <div class="font-weight-bold mb-3">VII. KONDISI PASIEN</div>
        <div class="font-weight-bold mb-3">SEBELUM TRANSFER</div>
        <v-row class="mt-0 mb-0">
          <v-col
            v-for="(v, i) in master.form7"
            :md="v.col ? v.col : 12"
            :lg="v.col ? v.col : 12"
            :sm="v.col ? v.col : 12"
            cols="12"
            class="mt-0 mb-0 pt-0 pb-3"
            :key="i"
          >
            <smart-widget
              :comp="v.widget"
              :sync-value="(e) => (data.form7[i] = e)"
              :value="data.form7[i]"
              vclass="c-text-field"
              :data="v.data"
              :property="v.property"
              :label="v.label ? v.label : i.replaceAll('_', ' ')"
              :rules="[rules.required]"
            />
          </v-col>
        </v-row>
        <div class="font-weight-bold mb-3">SETELAH TRANSFER</div>
        <v-row class="mt-0 mb-0">
          <v-col
            v-for="(v, i) in master.form8"
            :md="v.col ? v.col : 12"
            :lg="v.col ? v.col : 12"
            :sm="v.col ? v.col : 12"
            cols="12"
            class="mt-0 mb-0 pt-0 pb-3"
            :key="i"
          >
            <smart-widget
              :comp="v.widget"
              :sync-value="(e) => (data.form8[i] = e)"
              :value="data.form8[i]"
              vclass="c-text-field"
              :data="v.data"
              :property="v.property"
              :label="v.label ? v.label : i.replaceAll('_', ' ')"
              :rules="[rules.required]"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <div class="py-2"></div>
    <a href="#test4" id="test4"></a>
    <div class="form-group">
      <div
        style="z-index: 999999"
        class="float-group btn-group-vertical"
        role="group"
        aria-label="Vertical button group"
      >
        <v-btn
          @click="postSave"
          color="primary"
          large
          v-if="showButton"
          :disabled="disabled"
          class="btn-block"
        >
          Simpan Form
        </v-btn>
        &nbsp;
      </div>
    </div>
    <dialog-confirm
      :show-dialog="dialogShow"
      :negative-button="dialogActionNBtn"
      :positive-button="dialogActionPBtn"
      :disabled-negative-btn="dialogDisableNBtn"
      :disabled-positive-btn="dialogDisablePBtn"
      :progress="dialogProgress"
      :title="dialogTitle"
      :message="dialogMessage"
    />
    <v-dialog v-model="showDialogTransaferIgd" max-width="980">
      <v-card>
        <v-card-title class="headline"> Lembar Transafer </v-card-title>
        <v-card-text>
          <div id="hasil"></div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showDialogEdit" persistent max-width="850">
      <v-card>
        <v-card-title>Isi Formulir Transfer</v-card-title>
        <v-card-text>
          <!-- {{ itemResults }} -->
          <v-row>
            <v-col cols="12" sm="4" md="4">
              <v-text-field
                label="Nama Pasien"
                placeholder="Nama Pasien"
                outlined
                v-model="data.demografi.nama_pasien"
                readonly
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-text-field
                label="No. Rekam Medik"
                placeholder="No. Rekam Medik"
                outlined
                v-model="data.demografi.no_rekam_medis"
                readonly
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-text-field
                label="Tanggal Lahir"
                placeholder="Tanggal Lahir"
                outlined
                v-model="data.demografi.tanggal_lahir"
                readonly
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <div class="font-weight-bold mb-3">SEBELUM TRANSFER</div>
          <v-row class="mt-0 mb-0">
            <v-col
              v-for="(v, i) in master.form7"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="v.col ? v.col : 12"
              cols="12"
              class="mt-0 mb-0 pt-0 pb-3"
              :key="i"
            >
              <smart-widget
                :comp="v.widget"
                :sync-value="(e) => (data.form7[i] = e)"
                :value="data.form7[i]"
                vclass="c-text-field"
                :data="v.data"
                disabled
                :property="v.property"
                :label="v.label ? v.label : i.replaceAll('_', ' ')"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
          <v-divider class="mt-1 mb-1" />
          <div class="font-weight-bold mb-3">SETELAH TRANSFER</div>
          <v-row class="mt-0 mb-0">
            <v-col
              v-for="(v, i) in master.form8"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="v.col ? v.col : 12"
              cols="12"
              class="mt-0 mb-0 pt-0 pb-3"
              :key="i"
            >
              <smart-widget
                :comp="v.widget"
                :sync-value="(e) => (data.form8[i] = e)"
                :value="data.form8[i]"
                vclass="c-text-field"
                :data="v.data"
                :property="v.property"
                :label="v.label ? v.label : i.replaceAll('_', ' ')"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" dark @click="tutupmodalEdit()">
            Tutup
          </v-btn>
          <v-btn color="green darken-1" dark @click="simpan()">
            Simpan Formulir Transfer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showDialogEditTransfer" persistent max-width="1250">
      <v-card>
        <v-card-title>Isi Formulir Transfer</v-card-title>
        <v-card-text>
        <v-divider class="mt-3 mb-3" />
          <v-divider class="mt-3 mb-3" />
          <div class="font-weight-bold mb-3">Form Pindah Ruangan</div>
          <v-row class="mt-0 mb-0">
            <v-col
              v-for="(v, i) in master.form0"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="v.col ? v.col : 12"
              cols="12"
              class="mt-0 mb-0 pt-0 pb-3"
              :key="i"
            >
              <smart-widget
                :comp="v.widget"
                :sync-value="(e) => (data.form0[i] = e)"
                :value="data.form0[i]"
                vclass="c-text-field"
                :data="v.data"
                :disabled="v.disabled"
                :property="v.property"
                :label="v.label ? v.label : i.replaceAll('_', ' ')"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <div class="font-weight-bold mb-3 mt-2">
            I. RINGKASAN RAWAT PASIEN
          </div>
          <v-row class="mt-0 mb-0">
            <v-col
              v-for="(v, i) in master.form1"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="v.col ? v.col : 12"
              cols="12"
              class="mt-0 mb-0 pt-0 pb-3"
              :key="i"
            >
              <smart-widget
                :comp="v.widget"
                :sync-value="(e) => (data.form1[i] = e)"
                :value="data.form1[i]"
                vclass="c-text-field"
                :data="v.data"
                :property="v.property"
                :label="v.label ? v.label : i.replaceAll('_', ' ')"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
          <v-divider class="mt-3 mb-3" />
          <div class="font-weight-bold mb-3">
            II. PEMERIKSAAN PENUNJANG YANG SUDAH DILAKUKAN
          </div>
          <v-row class="mt-0 mb-0">
            <v-col
              v-for="(v, i) in master.form2"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="v.col ? v.col : 12"
              cols="12"
              class="mt-0 mb-0 pt-0 pb-3"
              :key="i"
            >
              <smart-widget
                :comp="v.widget"
                :sync-value="(e) => (data.form2[i] = e)"
                :value="data.form2[i]"
                vclass="c-text-field"
                :data="v.data"
                :property="v.property"
                :label="v.label ? v.label : i.replaceAll('_', ' ')"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
          <v-divider class="mt-3 mb-3" />
          <div class="font-weight-bold mb-3">III. DIAGNOSIS</div>
          <v-row class="mt-0 mb-0">
            <v-col
              v-for="(v, i) in master.form3"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="v.col ? v.col : 12"
              cols="12"
              class="mt-0 mb-0 pt-0 pb-3"
              :key="i"
            >
              <smart-widget
                :comp="v.widget"
                :sync-value="(e) => (data.form3[i] = e)"
                :value="data.form3[i]"
                vclass="c-text-field"
                :data="v.data"
                :property="v.property"
                :label="v.label ? v.label : i.replaceAll('_', ' ')"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
          <v-divider class="mt-3 mb-3" />
          <div class="font-weight-bold mb-3">IV. INDIKASI TRANSFER</div>
          <v-row class="mt-0 mb-0">
            <v-col
              v-for="(v, i) in master.form4"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="v.col ? v.col : 12"
              cols="12"
              class="mt-0 mb-0 pt-0 pb-3"
              :key="i"
            >
              <smart-widget
                :comp="v.widget"
                :sync-value="(e) => (data.form4[i] = e)"
                :value="data.form4[i]"
                vclass="c-text-field"
                :data="v.data"
                :property="v.property"
                :label="v.label ? v.label : i.replaceAll('_', ' ')"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
          <v-divider class="mt-3 mb-3" />
          <div class="font-weight-bold mb-3">
            V. TINDAKAN MEDIS YANG SUDAH DILAKUKAN
          </div>
          <v-row class="mt-0 mb-0">
            <v-col
              v-for="(v, i) in master.form5"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="v.col ? v.col : 12"
              cols="12"
              class="mt-0 mb-0 pt-0 pb-3"
              :key="i"
            >
              <smart-widget
                :comp="v.widget"
                :sync-value="(e) => (data.form5[i] = e)"
                :value="data.form5[i]"
                vclass="c-text-field"
                :data="v.data"
                :property="v.property"
                :label="v.label ? v.label : i.replaceAll('_', ' ')"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
          <v-divider class="mt-3 mb-3" />
          <div class="font-weight-bold mb-3">VI. PEMBERIAN TERAPI</div>
          <v-row class="mt-0 mb-0">
            <v-col
              v-for="(v, i) in master.form6"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="v.col ? v.col : 12"
              cols="12"
              class="mt-0 mb-0 pt-0 pb-3"
              :key="i"
            >
              <smart-widget
                :comp="v.widget"
                :sync-value="(e) => (data.form6[i] = e)"
                :value="data.form6[i]"
                vclass="c-text-field"
                :data="v.data"
                :property="v.property"
                :label="v.label ? v.label : i.replaceAll('_', ' ')"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
          <v-divider class="mt-3 mb-3" />
          <div class="font-weight-bold mb-3">VII. KONDISI PASIEN</div>
          <div class="font-weight-bold mb-3">SEBELUM TRANSFER</div>
          <v-row class="mt-0 mb-0">
            <v-col
              v-for="(v, i) in master.form7"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="v.col ? v.col : 12"
              cols="12"
              class="mt-0 mb-0 pt-0 pb-3"
              :key="i"
            >
              <smart-widget
                :comp="v.widget"
                :sync-value="(e) => (data.form7[i] = e)"
                :value="data.form7[i]"
                vclass="c-text-field"
                :data="v.data"
                :property="v.property"
                :label="v.label ? v.label : i.replaceAll('_', ' ')"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
          <div class="font-weight-bold mb-3">SETELAH TRANSFER</div>
          <v-row class="mt-0 mb-0">
            <v-col
              v-for="(v, i) in master.form8"
              :md="v.col ? v.col : 12"
              :lg="v.col ? v.col : 12"
              :sm="v.col ? v.col : 12"
              cols="12"
              class="mt-0 mb-0 pt-0 pb-3"
              :key="i"
            >
              <smart-widget
                :comp="v.widget"
                :sync-value="(e) => (data.form8[i] = e)"
                :value="data.form8[i]"
                vclass="c-text-field"
                :data="v.data"
                :property="v.property"
                :label="v.label ? v.label : i.replaceAll('_', ' ')"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" dark @click="tutupmodalEdit2()">
            Tutup
          </v-btn>
          <v-btn color="green darken-1" dark @click="simpanEdit()">
            Simpan Formulir Transfer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showDialogList" max-width="550">
      <v-col cols="12" md="12" sm="12" lg="12">
        <v-card>
          <v-card-title>Riwayat Form Tranfser Pasien</v-card-title>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="items"
              :search="search + ' ' + search2"
              :server-items-length="total"
              :loading="loading"
              :items-per-page="limit"
              height="350pt"
              dense
              fixed-header
              class="elevation-1"
              :disable-sort="true"
              @update:page="fetchData"
              @update:options="updatePagination"
            >
              <template #[`item.id_form_transafer`]="{ item }">
                <p class="ma-1">
                  <b>Dokter DPJP :</b> {{ item.dpjp?.text }}<br />
                  <b>Jam Pindah :</b> {{ item?.jam_pindah }}<br />
                  <b>Tanggal Pindah :</b>
                  {{ item.tgl_pindah | moment("DD MMMM YYYY") }}
                </p>
                <br />
                <div class="mt-1">
                  <v-btn
                    medium
                    dark
                    color="orange darken-4"
                    @click="edit(item)"
                    class="btn-block ma-1"
                    >OUT &nbsp;<b>{{ item.dari_ruangan }}</b>
                    <v-icon right>mdi-arrow-right-bold</v-icon>
                  </v-btn>
                  <v-btn
                    medium
                    dark
                    class="btn-block ma-1"
                    color="green white--text"
                    @click="edit(item)"
                  >
                    <b>{{ item.keruangan }}</b>
                    <v-icon right>mdi-arrow-left-bold</v-icon> &nbsp; Masuk </v-btn
                  ><v-divider inset></v-divider>
                  <v-btn
                    color="info"
                    @click="isiFormulirSetelah(item.id_form_transafer, item)"
                    class="btn-block m-1"
                    dark
                    medium
                    >Isi Form Setelah Transfer</v-btn
                  >
                  <v-btn
                    color="orange darken-1"
                    @click="editFormTransfer(item.id_form_transafer, item)"
                    class="btn-block m-1"
                    dark
                    medium
                    >Edit Form Transfer</v-btn
                  >

                  <v-btn
                    color="primary"
                    @click="viewFormTransfer(item.id_form_transafer)"
                    class="btn-block m-1"
                    medium
                    dark
                    >Cetak &nbsp;<v-icon small>mdi-cloud-print</v-icon></v-btn
                  >
                  <v-btn
                    color="red darken-4"
                    @click="hapusFormTransfer(item.id_form_transafer)"
                    class="btn-block m-1"
                    medium
                    dark
                    >Delete &nbsp;<v-icon small>mdi-delete</v-icon></v-btn
                  >
                </div>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-dialog>
    <!-- <div v-html="jsonPrettier(data)"></div> -->
    <a href="#test3" id="test3"></a>
  </div>
</template>

<script>
import SmartWidget from '@/components/SmartWidget.vue'
import Master from '../../data/transfer-pasien'
import Dialog from '@/components/Dialog'
import {
  // eslint-disable-next-line no-unused-vars
  apiKedua,
  // eslint-disable-next-line no-unused-vars
  successMsg,
  // eslint-disable-next-line no-unused-vars
  baseUrl,
  // eslint-disable-next-line no-unused-vars
  baseUrlV2,
  // eslint-disable-next-line no-unused-vars
  errorMsg,
  // eslint-disable-next-line no-unused-vars
  setBtnLoading,
  // eslint-disable-next-line no-unused-vars
  resetBtnLoading
} from '../../plugins/supports'

export default {
  components: {
    DialogConfirm: Dialog,
    SmartWidget
  },
  data   () {
    return {
      progress: false,
      master: Master,
      disabled: false,
      itemResults: '',
      showDialogList: '',
      showDialogEdit: false,
      showDialogEditTransfer: false,
      showDialogTransaferIgd: false,
      idEdit: '',
      showButton: true,

      user: JSON.parse(localStorage.getItem('user')),
      item_ke: '',
      data: {
        demografi: {
          nama_pasien: '',
          no_rekam_medis: '',
          tanggal_lahir: '',
          cara_bayar: '',
          ruangan: '',
          kamar_kelas: '',
          jenis_kelamin: '',
          folio_id: '',
          sub_unit_id: '',
          reg_id: ''
        },
        form0: {
          tanggal_pindah: '',
          dari: '',
          jam_pindah: '',
          dpjp: '',
          ke: '',
          nomor_kamar: ''
        },
        form1: {
          tanggal_lahir: '',
          pemeriksaan_fisik: '',
          riwayat_penyakit: '',
          kesadaran: '',
          kesadaran_gcs: '',
          kesadaran_e: '',
          kesadaran_m: '',
          kesadaran_v: '',
          tanda_vital: '',
          tanda_vital_td: '',
          tanda_vital_suhu: '',
          tanda_vital_nadi: '',
          tanda_vital_rr: '',
          pukul: ''
        },
        form2: {
          pemeriksaan_penunjang: ''
        },
        form3: {
          diagnosis: ''
        },
        form4: {
          indikasi_transfer: ''
        },
        form5: {
          tindakan_medis_sudah: ''
        },
        form6: {
          terapi: ''
        },
        form7: {
          keadaan_umum: '',
          kesadaran: '',
          kesadaran_gcs: '',
          kesadaran_e: '',
          kesadaran_m: '',
          kesadaran_v: '',
          tanda_vital: '',
          tanda_vital_td: '',
          tanda_vital_suhu: '',
          tanda_vital_nadi: '',
          tanda_vital_rr: '',
          catatan_penting: ''
        },
        form8: {
          keadaan_umum: '',
          kesadaran: '',
          kesadaran_gcs: '',
          kesadaran_e: '',
          kesadaran_m: '',
          kesadaran_v: '',
          tanda_vital: '',
          tanda_vital_td: '',
          tanda_vital_suhu: '',
          tanda_vital_nadi: '',
          tanda_vital_rr: '',
          catatan_penting: ''
        }
      },

      headers: [
        // Define your table headers here
        {
          text: 'LIST FORMULIR TRANSFER',
          value: 'id_form_transafer',
          // width: '270px',
          align: 'center'
        }
        // {
        //   text: 'RIWAYAT RINGKAS PASIEN',
        //   value: 'pemeriksaan',
        //   width: '520px',
        //   align: 'left'
        // },
        // {
        //   text: 'INDIKASI TRANSFER',
        //   value: 'pemeriksaan',
        //   width: '520px',
        //   align: 'left'
        // }
      ],
      items: [],
      search: '',
      loading5: false,
      search2: '',
      total: 0,
      page: 1,
      limit: 30,
      loading: false,
      rules: {
        required: (value) => {
          // return !isEmpty(value) || 'Tidak Boleh Kosong'
          return true
        },
        dialogShow: false,
        dialogMessage: 'Simpan Form Sekarang?',
        dialogProgress: false,
        dialogDisableNBtn: false,
        dialogDisablePBtn: false
      },
      dialogShowSoap: false,
      dialogTitleSoap: 'SOAP',
      historySubjektif: '',
      historyObjektif: '',
      historyAsessmen: '',
      planningAsessmen: '',
      dialogActionNBtnSoap: () => {
        this.dialogShowSoap = false
      },
      dialogActionPBtnSoap: () => {},

      dialogDisableNBtnSoap: false,
      dialogDisableNBtnKirimResume: false,

      dialogActionNBtnLKirimResume: () => {
        this.dialogResume = false
      },
      dialogDisablePBtnSoap: false,

      dialogShow: false,
      dialogTitle: 'Simpan',
      dialogMessage: 'Simpan Form Sekarang?',
      dialogProgress: false,
      dialogDisableNBtn: false,
      dialogDisablePBtn: false,
      pemakai: '',
      dialogActionNBtn: () => {
        this.dialogShow = false
      },

      dialogActionPBtn: () => this.postSave()
    }
  },
  created   () {
    if (this.$route.query.no_rm != null) {
      this.getDataPasien(this.$route.query.folio_id)
      this.fetchData()
      this.pemakai = JSON.parse(localStorage.getItem('user'))

      this.getAsesmenIgd()
    }
  },
  methods: {
    tutupmodalEdit () {
      this.showDialogEdit = false
      this.showButton = true
    },
    tutupmodalEdit2 () {
      this.showDialogEditTransfer = false
      this.showButton = true
    },
    isiFormulirSetelah   (id, item) {
      this.showDialogEdit = true
      this.showButton = false
      // this.itemResults = item
      setTimeout(() => {
        this.idEdit = item.id_form_transafer
        this.data.form7 = item.pemeriksaan.form7
        this.data.form8 = item.pemeriksaan.form8
      }, 500)

      // this.getDataPasien(this.$route.query.folio_id)
    },
    hapusFormTransfer   (id) {
      var createdby = this.pemakai.employee_name

      // eslint-disable-next-line no-undef
      Swal.fire({
        title: 'Perhatian! Apakah Anda Yakin?',
        text: 'Ingin Menghapus Form Transfer Ini.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya, Hapus',
        cancelButtonText: 'Tidak Jadi Batal',
        html: ''
      }).then((result) => {
        if (result.value) {
          // eslint-disable-next-line no-undef
          $.get(
            baseUrl + 'rawat-inap/hapus-transfer-by-id',
            { id: id, by: createdby },
            (res) => {
              // eslint-disable-next-line no-unused-vars
              const { con, msg, results } = res
              if (con) {
                successMsg(msg)
                setTimeout(() => {
                  this.fetchData()
                }, 1000)
              } else {
                errorMsg(msg + '-' + JSON.stringify(results))
              }
            },
            'JSON'
          )
        }
      })
    },
    viewFormTransfer   (folioId) {
      this.showDialogTransaferIgd = true
      console.log(folioId)
      // eslint-disable-next-line no-undef
      $.get(baseUrlV2 + 'rme/view-transfer', { folio_id: folioId }, (res) => {
        // eslint-disable-next-line no-undef
        $('#hasil').html(res)
      })
    },
    edit   () {},
    postSave   () {
      // console.log(this.data.form0.ke)
      if (this.data.form0.tanggal_pindah === '') {
        errorMsg('Tanggal Pindah Tidak Boleh Kosong')
        return true
      }
      if (this.data.form0.jam_pindah === '') {
        errorMsg('Jam Pindah Tidak Boleh Kosong')
        return true
      }
      if (this.data.form0.dpjp === '') {
        errorMsg('DPJP Tidak Boleh Kosong')
        return true
      }
      // if (this.data.form0.ke === '') {
      //   errorMsg('Ruangan Tidak Boleh Kosong')
      //   return true
      // }

      this.progress = true
      this.disabled = true

      console.log(this.user)
      // // console.log(this.data)
      // eslint-disable-next-line no-unused-vars
      var data = {
        rm: this.$route.query.no_rm,
        folio_id: this.$route.query.folio_id,
        regis_id: this.$route.query.registration_id,
        sub_unit: this.$route.query.sub_unit_id,
        // eslint-disable-next-line no-undef
        data: LZString.compressToBase64(JSON.stringify(this.data)),
        tgl_pindah: this.data.form0.tanggal_pindah,
        jam_pindah: this.data.form0.jam_pindah,
        // eslint-disable-next-line no-undef
        dpjp: LZString.compressToBase64(JSON.stringify(this.data.form0.dpjp)),
        dari: this.data.form0.dari,
        ke: this.data.form0.ke,
        type: 'IGD',
        created: this.user.employee_name
        // dari: this.
      }
      // console.log(data)
      // eslint-disable-next-line no-undef
      $.post(
        baseUrl + 'rawat-inap/simpan-form-transfer-pasien',
        data,
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            this.progress = false
            this.disabled = false
            successMsg(msg)

            this.fetchData()

            // this.data = ''
          } else {
            this.progress = false
            this.disabled = false
            errorMsg(msg)

            // this.data = ''
          }
        },
        'JSON'
      )
    },
    addPro   () {
      this.data.procedurs.push({
        previous: '',
        expiration: ''
      })
    },
    deletePro   (counter) {
      this.data.procedurs.splice(counter, 1)
    },
    simpan   () {
      const data = {
        form: this.data.form8,
        idEdit: this.idEdit
      }

      // eslint-disable-next-line no-undef
      $.post(
        baseUrl + 'rawat-inap/edit-form-transfer',
        data,
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res

          if (con) {
            successMsg(msg)
            this.fetchData()
          } else {
            successMsg(msg)
          }
        },
        'JSON'
      )

      console.log(data)
    },
    appendTechnique   (satu, dua) {
      // var a = 'how you are'
      if (satu.indexOf(dua) > -1) {
        // jika sudah ada / contained
        return satu
      }
      if (satu !== '' && satu !== dua) {
        // jika tidak kosong dan tidak inputan yang sama dgn skrng
        // alert('sadads')
        return dua + '\n' + satu
      } else {
        return dua
      }
    },

    getAsesmenIgd   () {
      // eslint-disable-next-line no-unused-vars
      const folioId = this.$route.query.folio_id
      // eslint-disable-next-line no-undef
      $.get(
        baseUrl + 'igd/get-data-asesmen',
        { folio_id: folioId },
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            // this.disablebuttonSimpan = false

            // eslint-disable-next-line no-undef, no-unused-vars
            var data = LZString.decompressFromBase64(results.pemeriksaan)
            var dataDec = JSON.parse(data)
            // console.log('DATA YANG DIENKRIPSI ' + dataDec)
            // eslint-disable-next-line no-unused-vars
            var dataKondisi = this.data.form6.terapi
            var dataRiwayat = this.data.form1.riwayat_penyakit
            var dataObjektif = this.data.form1.objektif
            var dataKesadaraan = this.data.form1.kesadaran
            var dataGCS = this.data.form1.kesadaran_gcs
            var dataTD = this.data.form1.tanda_vital_td
            var dataSuhu = this.data.form1.tanda_vital_suhu
            var dataDiagnosa = this.data.form3.diagnosis
            var dataTindakan = this.data.form5.tindakan_medis_sudah
            // console.log('DATA' + dataTindakan)

            const isianDokter = dataDec.isian_dokter
            const tandaVital = dataDec.tanda_vital

            // console.log(tandaVital)
            // eslint-disable-next-line no-unused-vars
            const planning = isianDokter.planning
            const riwayatpenyakit = isianDokter.subjektif.replace(']', '')
            const riwayatpenyakitSr = riwayatpenyakit.replace('[', '')

            const fisik1 = isianDokter.objektif.replace(']', '')
            const fisik2 = fisik1.replace('[', '')
            const aseskesadaraan = tandaVital.kesadaraan
            const asesGCS = tandaVital.GCS
            const asesTandaVital = tandaVital.TD
            const asesSuhu = tandaVital.suhu
            const asesDiagnosaUtama = isianDokter.diagnosa_utama
            const asesTindakan = isianDokter.tindakan_prosedur
            const fisik = isianDokter.objektif
            const rencana = isianDokter.rencana

            console.log(fisik)
            // console.log('DIAGNOSA ' + asesTindakan)
            // eslint-disable-next-line no-unused-vars
            const planningRacikan = isianDokter.planning_racikan

            // console.log(planningRacikan)
            // console.log(planning)

            // TERAPI YANG DIBERIKAN
            if (typeof dataKondisi !== 'undefined') {
              var brackets = dataKondisi.match(/\[[^\]]*]/g)
              console.log(brackets + 'style')

              const appendKeTerapi =
                '[' + planning + ' - ' + planningRacikan + ']'
              console.log(appendKeTerapi)

              this.item_ke = null
              if (brackets !== null) {
                brackets.forEach((item, index) => {
                  if (item.includes('[')) {
                    this.item_ke = index
                  }
                })
                if (this.item_ke != null) {
                  this.data.form6.terapi = this.data.form6.terapi.replace(
                    brackets[this.item_ke],
                    appendKeTerapi
                  )
                } else {
                  this.data.form6.terapi = this.appendTechnique(
                    this.data.form6.terapi,
                    appendKeTerapi
                  )
                }
              } else {
                this.data.form6.terapi = this.appendTechnique(
                  this.data.form6.terapi,
                  appendKeTerapi
                )
              }
            } else {
              const appendKeTerapi =
                '[' + planning + ' - ' + planningRacikan + ']'
              this.data.form1.riwayat_penyakit = appendKeTerapi
            }

            // RIWAYAT PENYAKIT
            if (typeof dataRiwayat !== 'undefined') {
              var brackets2 = dataRiwayat.match(/\[[^\]]*]/g)
              console.log(brackets2 + 'style')

              const appendKeRiwayat = '[' + riwayatpenyakitSr + ']'

              this.item_ke = null
              if (brackets2 !== null) {
                brackets2.forEach((item, index) => {
                  if (item.includes('[')) {
                    this.item_ke = index
                  }
                })
                if (this.item_ke != null) {
                  this.data.form1.riwayat_penyakit =
                    this.data.form1.riwayat_penyakit.replace(
                      brackets2[this.item_ke],
                      appendKeRiwayat
                    )
                } else {
                  this.data.form1.riwayat_penyakit = this.appendTechnique(
                    this.data.form1.riwayat_penyakit,
                    appendKeRiwayat
                  )
                }
              } else {
                this.data.form1.riwayat_penyakit = this.appendTechnique(
                  this.data.form1.riwayat_penyakit,
                  appendKeRiwayat
                )
              }
            } else {
              const appendRiwayat = '[' + riwayatpenyakitSr + ']'
              this.data.form1.riwayat_penyakit = appendRiwayat
            }

            // INDIKASI TRANSFER
            if (typeof rencana !== 'undefined') {
              var bracketsIndikasi = rencana.match(/\[[^\]]*]/g)
              console.log(bracketsIndikasi + 'style')

              const appendIndikasi = '[' + rencana + ']'

              this.item_ke = null
              if (bracketsIndikasi !== null) {
                bracketsIndikasi.forEach((item, index) => {
                  if (item.includes('[')) {
                    this.item_ke = index
                  }
                })
                if (this.item_ke != null) {
                  this.data.form4.indikasi_transfer =
                    this.data.form4.indikasi_transfer.replace(
                      brackets2[this.item_ke],
                      appendIndikasi
                    )
                } else {
                  this.data.form4.indikasi_transfer = this.appendTechnique(
                    this.data.form4.indikasi_transfer,
                    appendIndikasi
                  )
                }
              } else {
                this.data.form4.indikasi_transfer = this.appendTechnique(
                  this.data.form4.indikasi_transfer,
                  appendIndikasi
                )
              }
            } else {
              const appendIndisikasis = '[' + rencana + ']'
              this.data.form4.indikasi_transfer = appendIndisikasis
            }

            if (typeof dataObjektif !== 'undefined') {
              var bracketsPemeriksaanFisik = dataObjektif.match(/\[[^\]]*]/g)
              console.log(bracketsPemeriksaanFisik + 'style')

              const appendFisik = '[' + fisik2 + ']'

              this.item_ke = null
              if (bracketsPemeriksaanFisik !== null) {
                bracketsPemeriksaanFisik.forEach((item, index) => {
                  if (item.includes('[')) {
                    this.item_ke = index
                  }
                })
                if (this.item_ke != null) {
                  this.data.form1.pemeriksaan_fisik =
                    this.data.form1.pemeriksaan_fisik.replace(
                      brackets2[this.item_ke],
                      appendFisik
                    )
                } else {
                  this.data.form1.pemeriksaan_fisik = this.appendTechnique(
                    this.data.form1.pemeriksaan_fisik,
                    appendFisik
                  )
                }
              } else {
                this.data.form1.pemeriksaan_fisik = this.appendTechnique(
                  this.data.form1.pemeriksaan_fisik,
                  appendFisik
                )
              }
            } else {
              const appendRiwayat = '[' + fisik2 + ']'
              this.data.form1.pemeriksaan_fisik = appendRiwayat
            }

            // KESADARAN
            if (typeof dataKesadaraan !== 'undefined') {
              var brackets3 = dataKesadaraan.match(/\[[^\]]*]/g)
              const appendKeKesadaran = '[' + aseskesadaraan + ']'

              this.item_ke = null
              if (brackets3 !== null) {
                brackets3.forEach((item, index) => {
                  if (item.includes('[')) {
                    this.item_ke = index
                  }
                })
                if (this.item_ke != null) {
                  this.data.form1.kesadaran = this.data.form1.kesadaran.replace(
                    brackets3[this.item_ke],
                    appendKeKesadaran
                  )
                } else {
                  this.data.form1.dataKesadaraan = this.appendTechnique(
                    this.data.form1.kesadaran,
                    appendKeKesadaran
                  )
                }
              } else {
                this.data.form1.kesadaran = this.appendTechnique(
                  this.data.form1.kesadaran,
                  appendKeKesadaran
                )
              }
            } else {
              const appendKesedaran = '[' + aseskesadaraan + ']'
              this.data.form1.kesadaran = appendKesedaran
            }

            // GCS
            if (typeof dataGCS !== 'undefined') {
              var brackets4 = dataGCS.match(/\[[^\]]*]/g)
              const appendKeGCS = '[' + asesGCS + ']'

              this.item_ke = null
              if (brackets4 !== null) {
                brackets4.forEach((item, index) => {
                  if (item.includes('[')) {
                    this.item_ke = index
                  }
                })
                if (this.item_ke != null) {
                  this.data.form1.kesadaran_gcs =
                    this.data.form1.kesadaran_gcs.replace(
                      brackets4[this.item_ke],
                      appendKeGCS
                    )
                } else {
                  this.data.form1.kesadaran_gcs = this.appendTechnique(
                    this.data.form1.kesadaran_gcs,
                    appendKeGCS
                  )
                }
              } else {
                this.data.form1.kesadaran_gcs = this.appendTechnique(
                  this.data.form1.kesadaran_gcs,
                  appendKeGCS
                )
              }
            } else {
              const appendKesedaran = '[' + asesGCS + ']'
              this.data.form1.kesadaran_gcs = appendKesedaran
            }

            // TANDA VITAL
            if (typeof dataTD !== 'undefined') {
              var brackets5 = dataTD.match(/\[[^\]]*]/g)
              const appenKeTandaVital = '[' + asesTandaVital + ']'

              this.item_ke = null
              if (brackets5 !== null) {
                brackets5.forEach((item, index) => {
                  if (item.includes('[')) {
                    this.item_ke = index
                  }
                })
                if (this.item_ke != null) {
                  this.data.form1.tanda_vital_td =
                    this.data.form1.tanda_vital_td.replace(
                      brackets5[this.item_ke],
                      appenKeTandaVital
                    )
                } else {
                  this.data.form1.tanda_vital_td = this.appendTechnique(
                    this.data.form1.tanda_vital_td,
                    appenKeTandaVital
                  )
                }
              } else {
                this.data.form1.tanda_vital_td = this.appendTechnique(
                  this.data.form1.tanda_vital_td,
                  appenKeTandaVital
                )
              }
            } else {
              const appenTandaVital = '[' + asesTandaVital + ']'
              this.data.form1.tanda_vital_td = appenTandaVital
            }

            // SUHU
            if (typeof dataSuhu !== 'undefined') {
              var brackets6 = dataSuhu.match(/\[[^\]]*]/g)
              const appenKeSuhu = '[' + asesSuhu + ']'

              this.item_ke = null
              if (brackets6 !== null) {
                brackets6.forEach((item, index) => {
                  if (item.includes('[')) {
                    this.item_ke = index
                  }
                })
                if (this.item_ke != null) {
                  this.data.form1.tanda_vital_suhu =
                    this.data.form1.tanda_vital_suhu.replace(
                      brackets6[this.item_ke],
                      appenKeSuhu
                    )
                } else {
                  this.data.form1.tanda_vital_suhu = this.appendTechnique(
                    this.data.form1.tanda_vital_suhu,
                    appenKeSuhu
                  )
                }
              } else {
                this.data.form1.tanda_vital_suhu = this.appendTechnique(
                  this.data.form1.tanda_vital_suhu,
                  appenKeSuhu
                )
              }
            } else {
              const appenSuhu = '[' + asesSuhu + ']'
              this.data.form1.tanda_vital_suhu = appenSuhu
            }

            // DIAGNOSA UTAMA
            if (typeof dataDiagnosa !== 'undefined') {
              var brackets7 = dataDiagnosa.match(/\[[^\]]*]/g)
              const appenKeDiagnosaUtama = '[' + asesDiagnosaUtama + ']'

              this.item_ke = null
              if (brackets7 !== null) {
                brackets7.forEach((item, index) => {
                  if (item.includes('[')) {
                    this.item_ke = index
                  }
                })
                if (this.item_ke != null) {
                  this.data.form3.diagnosis = this.data.form3.diagnosis.replace(
                    brackets7[this.item_ke],
                    appenKeDiagnosaUtama
                  )
                } else {
                  this.data.form3.diagnosis = this.appendTechnique(
                    this.data.form3.diagnosis,
                    appenKeDiagnosaUtama
                  )
                }
              } else {
                this.data.form3.diagnosis = this.appendTechnique(
                  this.data.form3.diagnosis,
                  appenKeDiagnosaUtama
                )
              }
            } else {
              const appendDiagnosaUtama = '[' + asesDiagnosaUtama + ']'
              this.data.form3.diagnosis = appendDiagnosaUtama
            }

            // TINDAKAN
            if (typeof dataTindakan !== 'undefined') {
              var brackets8 = dataDiagnosa.match(/\[[^\]]*]/g)
              const appenKeTindakan = '[' + asesTindakan + ']'

              this.item_ke = null
              if (brackets8 !== null) {
                brackets8.forEach((item, index) => {
                  if (item.includes('[')) {
                    this.item_ke = index
                  }
                })
                if (this.item_ke != null) {
                  this.data.form5.tindakan_medis_sudah =
                    this.data.form5.tindakan_medis_sudah.replace(
                      brackets8[this.item_ke],
                      appenKeTindakan
                    )
                } else {
                  this.data.form5.tindakan_medis_sudah = this.appendTechnique(
                    this.data.form5.tindakan_medis_sudah,
                    appenKeTindakan
                  )
                }
              } else {
                this.data.form5.tindakan_medis_sudah = this.appendTechnique(
                  this.data.form5.tindakan_medis_sudah,
                  appenKeTindakan
                )
              }
            } else {
              const appendTindakan = '[' + asesTindakan + ']'
              this.data.form5.tindakan_medis_sudah = appendTindakan
            }
          } else {
            // this.disablebuttonSimpan = false
            // errorMsg(msg)
          }
        },
        'JSON'
      )
    },
    getDataPasien   (folioId) {
      // eslint-disable-next-line no-undef
      $.get(
        baseUrl + 'rawat-inap/get-detail-pasien',
        { folio_id: folioId },
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            console.log(results.ruangan)
            this.data.form0.dari = results.ruangan

            this.data.demografi = results

            this.data.demografi.jenis_kelamin = {
              value: results.jenis_kelamin,
              text: results.jenis_kelamin
            }
          } else {
          }
        },
        'JSON'
      )
    },

    fetchData   () {
      // console.log(this.search2)
      this.loading = true
      const url =
        baseUrl +
        `rawat-inap/get-form-transfer?no_rm=${this.$route.query.no_rm}&page=${this.page}&limit=${this.limit}&search=${this.search}&search2=${this.search2}`
      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          var array = []
          data.items.forEach((obj) => {
            // eslint-disable-next-line no-undef
            const pemeriksaan = LZString.decompressFromBase64(obj.pemeriksaan)
            const pemeriksaanCom = JSON.parse(pemeriksaan)
            // eslint-disable-next-line no-undef
            const dpjp = LZString.decompressFromBase64(obj.dpjp)
            const dpjpCom = JSON.parse(dpjp)
            array.push({
              id_form_transafer: obj.id_form_transafer,
              registration_id: obj.registration_id,
              folio_id: obj.folio_id,
              jam_pindah: obj.jam_pindah,
              tgl_pindah: obj.tgl_pindah,
              keruangan: obj.keruangan,
              dari_ruangan: obj.dari_ruangan,
              pemeriksaan: pemeriksaanCom,
              dpjp: dpjpCom
            })
          })

          console.log(array)
          this.items = array
          this.total = data.total
          this.loading = false
        })
        .catch((error) => {
          console.error('Error fetching data:', error)
          this.loading = false
        })
    },
    simpanEdit  () {
      const data = {
        form: this.data,
        idEdit: this.idEdit
      }

      console.log(data)

      // eslint-disable-next-line no-undef
      $.post(
        baseUrl + 'rawat-inap/edit-form',
        data,
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res

          if (con) {
            successMsg(msg)
            this.fetchData()
          } else {
            successMsg(msg)
          }
        },
        'JSON'
      )

      // console.log(data)
    },
    editFormTransfer  (results, item) {
      // console.log(results, item)
      this.showDialogEditTransfer = true
      // this.showDialogEdit = false
      this.showButton = false

      // this.itemResults = item
      setTimeout(() => {
        this.idEdit = item.id_form_transafer
        this.getDataPasien(this.$route.query.folio_id)
        // this.data.demografi = item.pemeriksaan.demografi
        // console.log(item.pemeriksaan.demografi)
        this.data.form0 = item.pemeriksaan.form0
        this.data.form1 = item.pemeriksaan.form1
        this.data.form2 = item.pemeriksaan.form2
        this.data.form3 = item.pemeriksaan.form3
        this.data.form4 = item.pemeriksaan.form4
        this.data.form5 = item.pemeriksaan.form5
        this.data.form6 = item.pemeriksaan.form6
        this.data.form7 = item.pemeriksaan.form7
        this.data.form8 = item.pemeriksaan.form8
      }, 500)
    },
    updatePagination   (options) {
      this.limit = options.itemsPerPage
      this.page = options.page
      this.fetchData()
    }
  },
  mounted   () {
    this.$root.$on('setdatatransfer', (item) => {
      if (this.$route.query.no_rm != null) {
        this.getAsesmenIgd()
      }
    })
  }
}
</script>

<style>
.float-group {
  position: fixed;
  bottom: 10%;
  right: 40px;
  color: #fff;
  text-align: center;
  z-index: 100;
}

.float {
  position: fixed;
  bottom: 160px;
  right: 40px;
  color: #fff;
  text-align: center;
  z-index: 100;
}
</style>
