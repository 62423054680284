export default {
  // methods: {
  //   hitungImt () {
  //     // alert('asdasda')
  //     // const tinggi = this.data.fisik.status_gizi.tinggi_badan
  //     // const berat = this.data.fisik.status_gizi.berat_badan

  //     // console.log(tinggi)
  //     // console.log(berat)

  //     // var tb = parseFloat(tinggi / 100)
  //     // var bb = berat
  //     // var imt = bb / (tb * tb)

  //     // this.data.fisik.status_gizi.imt = imt.toString()
  //     // console.log(imt)
  //   }
  // },
  demografi: {
    nama_pasien: {
      widget: 'wtext',
      data: null,
      col: 3,
      label: 'Nama Pasien'
    },
    no_rekam_medis: {
      widget: 'wtext',
      data: null,
      col: 2,
      label: 'No. RM'
    },
    tanggal_lahir: {
      widget: 'wdate',
      data: null,
      col: 3,
      label: 'Tanggal Lahir'
    },
    cara_bayar: {
      label: 'Cara Bayar',
      widget: 'wtext',
      data: null,
      col: 4
    },
    folio_id: {
      widget: 'wtext',
      data: null,
      col: 2,
      label: 'No Folio'
    },
    sub_unit_id: {
      widget: 'wtext',
      data: null,
      col: 2,
      label: 'Sub Unit Dokter'
    },
    reg_id: {
      widget: 'wtext',
      data: null,
      label: 'No Reg',
      col: 2
    },
    umur: {
      widget: 'wtext',
      data: null,
      col: 6,
      label: 'Umur'
    },
    jenis_kelamin: {
      widget: 'wradio',
      data: [
        { text: 'Laki-Laki', value: 'Laki-Laki' },
        { text: 'Perempuan', value: 'Perempuan' }
      ],
      col: 6
    }
  },
  pemeriksaan: {
    input_pendaftaran: [
      // {
      //   tanggal: {
      //     label: 'Tanggal',
      //     widget: 'wdate',
      //     col: 6,
      //     data: null
      //   },
      //   jam: {
      //     widget: 'wtext',
      //     col: 6,
      //     data: null,
      //     label: 'Jam'
      //   },
      //   pekerjaan: {
      //     widget: 'wradio',
      //     label: 'Pekerjaan',
      //     data: [
      //       {
      //         text: 'TNI/POLRI',
      //         value: 'TNI/POLRI'
      //       },
      //       {
      //         text: 'PNS',
      //         value: 'PNS'
      //       },
      //       {
      //         text: 'SWASTA',
      //         value: 'SWASTA'
      //       },
      //       {
      //         text: 'IRT',
      //         value: 'IRT'
      //       },
      //       {
      //         text: 'lain-lain',
      //         value: null,
      //         field: 'auto'
      //       }
      //     ]
      //   },
      //   penghasilan: {
      //     widget: 'wradio',
      //     data: [
      //       {
      //         text: 'tidak ada',
      //         value: 'tidak ada'
      //       },
      //       {
      //         text: '< 1 juta',
      //         value: '< 1 juta'
      //       },
      //       {
      //         text: '1 - 2,9 juta',
      //         value: '1 - 2,9 juta'
      //       },
      //       {
      //         text: '3-4,9 juta',
      //         value: '3-4,9 juta'
      //       },
      //       {
      //         text: '5-9,9 juta',
      //         value: '5-9,9 juta'
      //       },
      //       {
      //         text: '10-14,9 juta',
      //         value: '10-14,9 juta'
      //       },
      //       {
      //         text: '>15 juta',
      //         value: '>15 juta'
      //       }
      //     ]
      //   },
      //   bahasa: {
      //     widget: 'wradio',
      //     data: [
      //       {
      //         text: 'indonesia',
      //         value: 'indonesia'
      //       },
      //       {
      //         text: 'asing',
      //         value: null,
      //         field: 'auto'
      //       },
      //       {
      //         text: 'daerah',
      //         value: 'daerah'
      //       },
      //       {
      //         text: 'penerjemah',
      //         value: 'penerjemah'
      //       }
      //     ]
      //   }
      // },
      // {
      //   agama: {
      //     widget: 'wradio',
      //     data: [
      //       {
      //         text: 'Islam',
      //         value: 'Islam'
      //       },
      //       {
      //         text: 'Katolik',
      //         value: 'Katolik'
      //       },
      //       {
      //         text: 'Kristen',
      //         value: 'Kristen'
      //       },
      //       {
      //         text: 'Hindu',
      //         value: 'Hindu'
      //       },
      //       {
      //         text: 'Budha',
      //         value: 'Budha'
      //       },
      //       {
      //         text: 'lain-lain',
      //         value: null,
      //         field: 'auto'
      //       }
      //     ]
      //   },
      //   pendidikan: {
      //     widget: 'wradio',
      //     data: [
      //       {
      //         text: 'SD',
      //         value: 'SD'
      //       },
      //       {
      //         text: 'SMP',
      //         value: 'SMP'
      //       },
      //       {
      //         text: 'SMA',
      //         value: 'SMA'
      //       },
      //       {
      //         text: 'akademi',
      //         value: 'akademi'
      //       },
      //       {
      //         text: 'sarjana',
      //         value: 'sarjana'
      //       },
      //       {
      //         text: 'lain-lain',
      //         value: null,
      //         field: 'auto'
      //       }
      //     ]
      //   },
      //   klinik: {
      //     widget: 'wradio',
      //     data: [
      //       {
      //         text: 'umum',
      //         value: 'umum'
      //       },
      //       {
      //         text: 'bedah',
      //         value: 'bedah'
      //       },
      //       {
      //         text: 'anak',
      //         value: 'anak'
      //       },
      //       {
      //         text: 'jiwa',
      //         value: 'jiwa'
      //       },
      //       {
      //         text: 'tht',
      //         value: 'tht'
      //       },
      //       {
      //         text: 'syaraf',
      //         value: 'syaraf'
      //       },
      //       {
      //         text: 'mata',
      //         value: 'mata'
      //       },
      //       {
      //         text: 'gigi',
      //         value: 'gigi'
      //       },
      //       {
      //         text: 'penyakit dalam',
      //         value: 'penyakit dalam'
      //       },
      //       {
      //         text: 'kebidanan/kandungan',
      //         value: 'kebidanan/kandungan'
      //       },
      //       {
      //         text: 'kulit dan kelamin',
      //         value: 'kulit dan kelamin'
      //       },
      //       {
      //         text: 'lain-lain',
      //         value: null,
      //         field: 'auto'
      //       }
      //     ]
      //   }
      // }
    ],
    input_perawat: {
      group_1: {
        tanggal: {
          label: 'Tanggal',
          widget: 'wdate',
          data: null,
          col: 6
        },
        jam: {
          label: 'Jam',
          widget: 'wtext',
          data: null,
          col: 6
        },
        subjektif: {
          widget: 'wtextarea',
          data: null,
          col: 12,
          label: 'Subjektif (Perawat)'

        },
        riwayat_penyakit_sebelumnya: {
          widget: 'wtextarea',
          data: null,
          col: 12,
          label: 'Riwayat Penyakit Sebelumnya'

        },
        riwayat_konsumsi_obat_sebelumnya: {
          widget: 'wtextarea',
          label: 'Riwayat Konsumsi Obat Sebelumnya',
          data: null,
          col: 12
        }
      },
      group_2: {
        riwayat_alergi: {
          label: 'Tes bro',
          widget: 'wcheckbox',
          data: [
            {
              text: 'tidak ada alergi',
              value: 'tidak ada alergi'
            },
            {
              text: 'alergi obat',
              value: null,
              field: 'auto',
              float: false
            },
            {
              text: 'alergi makanan',
              value: null,
              field: 'auto',
              float: false
            },
            {
              text: 'alergi lainnya',
              value: null,
              field: 'auto',
              float: false
            },
            {
              text: 'tanda alergi dipasang (warna merah)',
              value: 'tanda alergi dipasang (warna merah)'
            },
            {
              text: 'tidak diketahui',
              value: 'tidak diketahui'
            }
          ]
        }
      },
      group_3: {
        TD: {
          widget: 'wtext',
          data: null,
          col: 12,
          property: { attrs: { suffix: '/ mmHg' } }
        },
        suhu_axila: {
          widget: 'wtext',
          data: null,
          label: 'Suhu Axila',
          col: 6,
          property: { attrs: { suffix: '°C', type: 'number' } }
        },
        gdp: {
          widget: 'wtext',
          data: null,
          label: 'GDS',
          col: 6,
          property: { attrs: { suffix: 'mg/dL', type: 'number' } }
        },
        suhu_recital: {
          widget: 'wtext',
          data: null,
          label: 'Suhu Recital',
          col: 6,
          property: { attrs: { suffix: '°C', type: 'number' } }
        },
        nadi: {
          widget: 'wtext',
          data: null,
          col: 6,
          label: 'Nadi',
          property: { attrs: { suffix: 'x / mnt', type: 'number' } }
        },
        RR: {
          widget: 'wtext',
          data: null,
          col: 6,
          property: { attrs: { suffix: 'x / mnt', type: 'number' } }
        }
      },
      group_4: {
        provokatif: {
          col: 6,
          widget: 'wradio',
          label: 'Provokatif',
          data: [
            {
              text: 'ruda paksa',
              value: 'ruda paksa',
              float: true
            },
            {
              text: 'benturan',
              value: 'benturan',
              float: true
            },
            {
              text: 'sayatan',
              value: 'sayatan',
              float: true
            },
            {
              text: 'dll',
              value: null,
              field: 'auto'
            }
          ]
        },
        quality: {
          widget: 'wradio',
          label: 'Quality',
          col: 6,
          data: [
            {
              text: 'tertusuk',
              value: 'tertusuk',
              float: true
            },
            {
              text: 'tertekan tertindih',
              value: 'tertekan tertindih',
              float: true
            },
            {
              text: 'diiris iris',
              value: 'diiris iris',
              float: true
            },
            {
              text: 'dll',
              value: null,
              field: 'auto'
            }
          ]
        },
        region: {
          widget: 'wtext',
          data: null,
          label: 'Region',
          col: 6
        },
        menjalar: {
          widget: 'wradio',
          label: 'Menjalar',
          col: 6,
          data: [
            {
              text: 'Tidak',
              value: 'Tidak'
            },
            {
              text: 'Ya',
              value: '',
              field: 'auto'
            }
          ]
        },
        time: {
          label: 'Time',
          widget: 'wradio',
          col: 6,
          data: [
            {
              text: 'jarang',
              value: 'jarang'
            },
            {
              text: 'hilang timbul',
              value: 'hilang timbul'
            },
            {
              text: 'terus menerus',
              value: 'terus menerus'
            }
          ]
        }
      },
      group_5: {
        widget: 'wtext',
        property: { attrs: { type: 'number' } },
        data: [
          {
            text: 'wajah',
            value: 0,
            dataCols: [
              'tersenyum/tidak ada ekspresi khusus',
              'terkadang menangis/menarik diri',
              'sering menggetarkan dagu dan mengatupkan'
            ]
          },
          {
            text: 'kaki',
            value: 0,
            dataCols: [
              'gerakan normal/relaksasi',
              'tidak tenang/tegang',
              'kaki menendang/menarik'
            ]
          },
          {
            text: 'aktifitas',
            value: 0,
            dataCols: [
              'tidur, posisi normal, mudah bergerak',
              'gerakan menggeliat, berguling, kaku',
              'melengkungkan punggung/kaku/menghentak'
            ]
          },
          {
            text: 'menangis',
            value: 0,
            dataCols: [
              'tidak menangis (bangun/tidur)',
              'menggerang, merengek',
              'menangis terus menerus, terisak, menjerit'
            ]
          },
          {
            text: 'bersuara',
            value: 0,
            dataCols: [
              'bersuara normal, tenang',
              'tenang bila dipeluk, digendong/diajak bicara',
              'sulit untuk ditenangkan'
            ]
          },
          {
            text: 'total skor',
            value: 0
          }
        ]
      },
      group_6: {
        keadaan_umum: {
          col: 6,
          widget: 'wradio',
          data: [
            {
              text: 'Baik',
              value: 'Baik',
              float: true
            },
            {
              text: 'Sedang',
              value: 'Sedang',
              float: true
            },
            {
              text: 'Buruk',
              value: 'Buruk',
              float: true
            }
          ]
        }
      },
      group_7: {
        tinggi_badan: {
          widget: 'wtext',
          data: null,
          label: 'Tinggi Badan',
          col: 3,
          property: {
            attrs: { suffix: 'CM', type: 'number' },
            events: {
              // change: this.hitungImt,
              // input: this.hitungImt,
              // keyup: this.hitungImt
            }
          }
        },
        berat_badan: {
          widget: 'wtext',
          data: null,
          label: 'Berat Badan',
          col: 3,
          property: {
            attrs: { suffix: 'Kg', type: 'number' }
          }
        },
        lingkar_lengan: {
          widget: 'wtext',
          data: null,
          label: 'Lingkar Lengan',
          col: 3,
          property: {
            attrs: { suffix: 'CM', type: 'number' }
          }
        },
        imt: {
          widget: 'wtext',
          data: null,
          label: 'IMT',
          col: 3,
          property: { attrs: { suffix: 'IMT', type: 'number' } }
        }
      },
      group_9: {
        widget: 'wtext',
        property: { attrs: { type: 'number' } },
        data: [
          {
            text: 'a',
            value: 0,
            dataCols: [
              'Cara berjalan pasien (salah satu atau Iebih): <br>1. Tidak seimbang/sempoyongan/limbung <br>2. Jalan dengan menggunakan alat bantu/bantuan orang lain'
            ]
          },
          {
            text: 'b',
            value: 0,
            dataCols: [
              'Menopang saat akan duduk : tampak memegang pinggiran kursi atau meja/ benda lain sebagai penopang saat akan duduk'
            ]
          }
        ]
      },
      group_10: {
        status_berat_badan: {
          col: 6,
          widget: 'wradio',
          data: [
            {
              text: 'BB Kurang',
              value: 'BB Kurang',
              float: true
            },
            {
              text: 'BB Normal',
              value: 'BB Normal',
              float: true
            },
            {
              text: 'BB Lebih',
              value: 'BB Lebih',
              float: true
            }
          ]
        },
        status_fungsional: {
          widget: 'wradio',
          col: 6,
          data: [
            {
              text: 'mandiri',
              value: 'mandiri',
              float: true
            },
            {
              text: 'perlu bantuan',
              value: null,
              field: 'auto'
            },
            {
              text: 'ketergantungan',
              value: 'ketergantungan',
              float: true
            }
          ]
        },
        status_psikologis: {
          widget: 'wradio',
          col: 6,
          data: [
            {
              text: 'tenang',
              value: 'tenang',
              float: true
            },
            {
              text: 'cemas',
              value: 'cemas',
              float: true
            },
            {
              text: 'sedih',
              value: 'sedih',
              float: true
            },
            {
              text: 'depresi',
              value: 'depresi',
              float: true
            },
            {
              text: 'marah',
              value: 'marah',
              float: true
            },
            {
              text: 'hiperaktif',
              value: 'hiperaktif',
              float: true
            },
            {
              text: 'mengganggu sekitar',
              value: 'mengganggu sekitar',
              float: true
            },
            {
              text: 'lain-lain',
              value: null,
              float: true,
              field: 'auto'
            }
          ]
        },
        status_mental: {
          widget: 'wradio',
          col: 6,
          property: { group: { row: false } },
          data: [
            {
              text: 'sadar dan orientasi penuh',
              value: 'sadar dan orientasi penuh'
            },
            {
              text: 'ada kelainan perilaku sebutkan',
              value: null,
              field: 'auto'
            },
            {
              text: 'ada perilaku kekerasan yang dialami pasien sebelumnya sebutkan',
              value: null,
              field: 'auto'
            }
          ]
        },
        status_sosial: {
          widget: 'wradio',
          col: 6,
          label: 'Status Sosial: Hubungan pasien dengan keluarga',
          // property: { group: { row: false } },
          data: [
            {
              text: 'Baik',
              value: 'Baik'
            },
            {
              text: 'Kurang Baik',
              value: 'Kurang Baik'
            }
          ]
        },
        suku_budaya: {
          widget: 'wtext',
          col: 6,
          property: { label: 'Suku Budaya / Nilai Kepercayaan' },
          value: null
        }
      },
      group_11: {
        masalah_keperawatan1: {
          widget: 'wcheckbox',
          col: 6,
          data: [
            {
              text: 'gangguan perfusi jaringan cerebral',
              value: 'gangguan perfusi jaringan cerebral'
            },
            {
              text: 'gangguan perfusi jaringan kardiovaskular',
              value: 'gangguan perfusi jaringan kardiovaskular'
            },
            {
              text: 'gangguan keseimbangan cairan dan elektrolit',
              value: 'gangguan keseimbangan cairan dan elektrolit'
            },
            {
              text: 'resiko syok hipovolemik',
              value: 'resiko syok hipovolemik'
            },
            {
              text: 'gangguan pemenuhan kebutuhan nutrisi',
              value: 'gangguan pemenuhan kebutuhan nutrisi'
            },
            {
              text: 'nyeri',
              value: 'nyeri'
            },
            {
              text: 'peningkatan suhu tubuh',
              value: 'peningkatan suhu tubuh'
            },
            {
              text: 'gangguan integritas kulit',
              value: 'gangguan integritas kulit'
            },
            {
              text: 'gangguan pola eliminasi bab',
              value: 'gangguan pola eliminasi bab'
            }
          ]
        },
        masalah_keperawatan2: {
          widget: 'wcheckbox',
          col: 6,
          data: [
            {
              text: 'gangguan pola eliminasi bak',
              value: 'gangguan pola eliminasi bak'
            },
            {
              text: 'resiko infeksi',
              value: 'resiko infeksi'
            },
            {
              text: 'resiko cedera',
              value: 'resiko cedera'
            },
            {
              text: 'gangguan mobilitas fisik',
              value: 'gangguan mobilitas fisik'
            },
            {
              text: 'intoleransi aktifitas',
              value: 'intoleransi aktifitas'
            },
            {
              text: 'gangguan komunikasi verbal nonverbal',
              value: 'gangguan komunikasi verbal nonverbal'
            },
            {
              text: 'resiko pendarahan',
              value: 'resiko pendarahan'
            },
            {
              text: 'lain-lain',
              value: null,
              field: 'auto'
            }
          ]
        }
      },
      group_12: {
        rencana_keperawatan1: {
          widget: 'wcheckbox',
          col: 6,
          property: { grouping: true, numbering: true, startNum: 1 },
          data: [
            {
              text: 'TD',
              value: 'TD',
              group: 'observasi',
              float: true
            },
            {
              text: 'nadi',
              value: 'nadi',
              group: 'observasi',
              float: true
            },
            {
              text: 'suhu',
              value: 'suhu',
              group: 'observasi',
              float: true
            },
            {
              text: 'turgor',
              value: 'turgor',
              group: 'observasi',
              float: true
            },
            {
              text: 'balance cairan',
              value: 'balance cairan',
              group: 'observasi',
              float: true
            },
            {
              text: 'warna kulit',
              value: 'warna kulit',
              group: 'observasi',
              float: true
            },
            {
              text: 'perfusi jaringan',
              value: 'perfusi jaringan',
              group: 'observasi',
              float: true
            },
            {
              text: 'kedaran',
              value: 'kedaran',
              group: 'observasi',
              float: true
            },
            {
              text: 'gcs',
              value: 'gcs',
              group: 'observasi',
              float: true
            },
            {
              text: 'produksi sputum',
              value: 'produksi sputum',
              group: 'observasi'
            },
            {
              text: 'lakukan auskultasi suara nafas',
              value: 'lakukan auskultasi suara nafas'
            },
            {
              text: 'batasi aktifitas fisik',
              value: 'batasi aktifitas fisik'
            },
            {
              text: 'berikan informasi dukungan untuk mengurangi kecemasan',
              value: 'berikan informasi dukungan untuk mengurangi kecemasan'
            },
            {
              text: 'ajarkan cara batuk efektif',
              value: 'ajarkan cara batuk efektif'
            },
            {
              text: 'cegah pasien melukai diri sendiri dan orang lain',
              value: 'cegah pasien melukai diri sendiri dan orang lain'
            },
            {
              text: 'pemberian terapi',
              value: 'pemberian terapi',
              group: 'kolaborasi dengan tim medis Dalam',
              float: true
            },
            {
              text: 'pemeriksaan penunjang lab ro',
              value: 'pemeriksaan penunjang lab ro',
              group: 'kolaborasi dengan tim medis Dalam'
            }
          ]
        },
        rencana_keperawatan2: {
          widget: 'wcheckbox',
          col: 6,
          property: { grouping: true, numbering: true, startNum: 8 },
          data: [
            {
              text: 'kateter',
              value: 'kateter',
              group: 'lakukan pemasangan',
              float: true
            },
            {
              text: 'gibs',
              value: 'gibs',
              group: 'lakukan pemasangan',
              float: true
            },
            {
              text: 'ekg',
              value: 'ekg',
              group: 'lakukan pemasangan',
              float: true
            },
            {
              text: 'iv line',
              value: 'iv line',
              group: 'lakukan pemasangan'
            },
            {
              text: 'kaji tingkat nyeri',
              value: 'kaji tingkat nyeri'
            },
            {
              text: 'lakukan perawatan luka',
              value: 'lakukan perawatan luka'
            },
            {
              text: 'longgarkan pakaian atau beri selimut',
              value: 'longgarkan pakaian atau beri selimut'
            },
            {
              text: 'lakukan nebulizer',
              value: 'lakukan nebulizer'
            },
            {
              text: 'lakukan fisioterapi nafas',
              value: 'lakukan fisioterapi nafas'
            },
            {
              text: 'berikan health education kepada pasien dan keluarga',
              value: 'berikan health education kepada pasien dan keluarga'
            },
            {
              text: 'lain-lain',
              value: null,
              field: 'auto'
            }
          ]
        }
      },
      group_16: {
        sambungan_hal_2: {
          status_sosial: false,
          suku_budaya: '',
          perencanaan_pulang: [
            {
              text: 'umur 65 tahun',
              value: 'umur 65 tahun',
              group: 'kriteria discharge planning'
            },
            {
              text: 'keterbatasan mobalitas',
              value: 'keterbatasan mobalitas',
              group: 'kriteria discharge planning'
            },
            {
              text: 'perawatan atau pengobatan lanjutan',
              value: 'perawatan atau pengobatan lanjutan',
              group: 'kriteria discharge planning'
            },
            {
              text: 'bantuan untuk melakukan aktifitas sehari hari',
              value: 'bantuan untuk melakukan aktifitas sehari hari',
              group: 'kriteria discharge planning'
            },
            {
              text: 'perawatan diri mandi bab bak',
              value: 'perawatan diri mandi bab bak',
              group: 'kriteria perencanaan'
            },
            {
              text: 'pemantauan diet',
              value: 'pemantauan diet',
              group: 'kriteria perencanaan'
            },
            {
              text: 'latihan fisik lanjutan',
              value: 'latihan fisik lanjutan',
              group: 'kriteria perencanaan'
            },
            {
              text: 'bantuan medis perawatan dirumah',
              value: 'bantuan medis perawatan dirumah',
              group: 'kriteria perencanaan'
            },
            {
              text: 'pemantauan pemberian obat',
              value: 'pemantauan pemberian obat',
              group: 'kriteria perencanaan'
            },
            {
              text: 'perawatan luka',
              value: 'perawatan luka',
              group: 'kriteria perencanaan'
            },
            {
              text: 'pendampingan tenaga khusus dirumah',
              value: 'pendampingan tenaga khusus dirumah',
              group: 'kriteria perencanaan'
            },
            {
              text: 'bantuan untuk melakukan aktifitas fisik',
              value: 'bantuan untuk melakukan aktifitas fisik',
              group: 'kriteria perencanaan'
            }
          ]
        }
      }
    }
  },
  isian_dokter: {
    jam_isian_dokter: {
      widget: 'wtext',
      data: null,
      col: 12,
      label: 'Jam',
      property: { attrs: { suffix: 'WIB', type: 'text' } }
    },
    subjektif: {
      widget: 'wtextarea',
      data: null,
      col: 12,
      label: 'Subjektif (Anamnesa)'
    },
    objektif: {
      widget: 'wtextarea',
      data: null,
      col: 12,
      label: 'Objektif (Pemeriksaan Fisik)'
    },
    pemeriksaan_penunjang: {
      label:
        'Pemeriksaan Penunjang : (EKG/Laboratorium/Rontgen/CT-Scan/Lainnya)',
      widget: 'wradio',
      col: 12,
      data: [
        {
          text: 'Dilakukan',
          value: 'Dilakukan'
        },
        {
          text: 'Tidak Dilakukan',
          value: 'Tidak Dilakukan'
        }
      ]
    },
    diagnosa_utama: {
      label: 'Diagnosa Utama',
      widget: 'wtextarea',
      col: 5
    },
    kode_icd_diagnosa_utama: {
      label: 'Kode ICD Utama',
      widget: 'wautocomplate',
      data: [],
      col: 7
    },
    diagnosa_sekunder: {
      label: 'Diagnosa Sekunder',
      widget: 'wtextarea',

      col: 5
    },
    kode_icd_diagnosa_sekunder: {
      label: 'Kode ICD Sekunder',
      widget: 'wautocomplate',
      data: [],
      col: 7
    },

    tindakan_prosedur: {
      label: 'Tindakan',
      widget: 'wtextarea',
      col: 5
    },
    tindakan: {
      label: 'Tindakan Dokter',
      widget: 'wautocomplatetindakan',
      data: [],
      col: 7
    },
    noted: {
      label: 'Noted',
      widget: 'wtextarea',
      col: 12
    },
    planning: {
      widget: 'wtextarea',
      data: null,
      col: 12,
      label: 'Terapy Non Racikan'
    },
    planning_racikan: {
      widget: 'wtextarea',
      data: null,
      col: 12,
      label: 'Terapy Racikan'
    },

    // asessmen_diagnosa_kerja: {
    //   widget: 'wtextarea',
    //   data: null,
    //   col: 12,
    //   label: 'Asessmen (Diagnosa Kerja)'
    // },

    cara_pulang: {
      widget: 'wradio',
      col: 12,
      label: 'Cara Pulang',
      data: [
        { text: 'Diijinkan Dokter', value: 'Diijinkan Dokter' },
        { text: 'Dirawat', value: 'Dirawat' },
        {
          text: 'Pulang Permintaan Sendiri',
          value: 'Pulang Permintaan Sendiri'
        },
        { text: 'Di Rujuk Ke', value: '', field: 'auto' },
        { text: 'Meninggal', value: 'Meninggal' }
      ]
    },
    instruksi_tindak_lanjut: {
      widget: 'wradio',
      col: 6,
      label: 'Intruksi Tindak Lanjut',
      data: [
        {
          text: 'Kontrol Ke RS Syafira, Poliklinik:',
          value: '',
          field: 'auto'
        },
        {
          text: 'Kembali Ke Faskes TK I Yang Merujuk',
          value: 'Kembali Ke Faskes TK I Yang Merujuk'
        },
        { text: 'Tidak Perlu Merujuk', value: 'Tidak Perlu Merujuk' }
      ]
    },
    tanggal_lahir: {
      widget: 'wdatenormal',
      data: null,
      col: 6,
      label: 'Tanggal Kontrol'
    }
  },
  kebutuhan_edukasi: {
    hambatan: {
      label: 'Terdapat hambatan dalam pembelajaran',
      widget: 'wradio',
      col: 6,
      data: [
        {
          text: 'Ya',
          value: 'Ya'
        },
        {
          text: 'Tidak',
          value: 'Tidak'
        }
      ]
    },
    sebutkan_hambatan: {
      label:
        'Jika Iya, Sebuthkan Hambatannya (bisa dilingkari lebih dari satu)',
      widget: 'wcheckbox',
      col: 12,
      data: [
        {
          text: 'Pendengaran',
          value: 'Pendengaran'
        },
        {
          text: 'Penglihatan',
          value: 'Penglihatan'
        },
        {
          text: 'Kognitif',
          value: 'Kognitif'
        },
        {
          text: 'Fisik',
          value: 'Fisik'
        },
        {
          text: 'Budaya',
          value: 'Budaya'
        },
        {
          text: 'Agama',
          value: 'Agama'
        },
        {
          text: 'Emosi',
          value: 'Emosi'
        },
        {
          text: 'Bahasa',
          value: 'Bahasa'
        },
        {
          text: 'Lainnya',
          value: null,
          field: 'auto'
        }
      ]
    },
    dibutuhkan_penerjemah: {
      label: 'Dibutuhkan penerjemah',
      widget: 'wradio',
      col: 6,
      data: [
        {
          text: 'Tidak',
          value: 'Tidak',
          float: true
        },
        {
          text: 'Ya',
          value: '',
          field: 'auto'
        }
      ]
    },
    kebutuhan_pembelajaran_pasien: {
      label:
        'Kebutuhan Pembelajaran Pasien (pilih topik pembelajaran pada kotak yang tersedia)',
      widget: 'wcheckbox',
      col: 12,
      data: [
        {
          text: 'Diagnosa & Manajemen',
          value: 'Diagnosa & Manajemen'
        },
        {
          text: 'Obat-obatan',
          value: 'Obat-obatan'
        },
        {
          text: 'Perawatan Luka',
          value: 'Perawatan Luka'
        },
        {
          text: 'Rehabilitasi',
          value: 'Rehabilitasi'
        },
        {
          text: 'Manajemen Nyeri',
          value: 'Manajemen Nyeri'
        },
        {
          text: 'Diet dan nutrisi',
          value: 'Diet dan nutrisi'
        },
        {
          text: 'Lain-lainnya',
          value: null,
          field: 'auto'
        }
      ]
    },
    rencana_tindak_lanjut: {
      label: 'Rencana Tindak Lanjut',
      widget: 'wradio',
      col: 12,
      data: [
        {
          text: 'Pulang',
          value: 'Pulang'
        },
        {
          text: 'Rawat Inap',
          value: 'Rawat Inap'
        },
        {
          text: 'Pulang Atas Permintaan Sendiri',
          value: 'Pulang Atas Permintaan Sendiri'
        },

        {
          text: 'Di Rujuk Ke',
          value: null,
          field: 'auto'
        }
      ]
    }
  },
  kriteria_perencanaan_pulang: {
    kriteria_perencanaan_pulang: {
      label: 'Tes bro',
      widget: 'wcheckbox',
      data: [
        {
          text: 'Perawatan Diri (mandi, BAB, BAK)',
          value: 'Perawatan Diri (mandi, BAB, BAK)'
        },
        {
          text: 'Pemantauan DIet',
          value: 'Pemantauan DIet'
        },
        {
          text: 'Latihan Fisik Lanjutan',
          value: 'Latihan Fisik Lanjutan'
        },
        {
          text: 'Bantuan Medis atau Perawatan di rumah (home care)',
          value: 'Bantuan Medis atau Perawatan di rumah (home care)'
        },
        {
          text: 'Pemantaun Pemberian Obat',
          value: 'Pemantaun Pemberian Obat'
        },
        {
          text: 'Perawatan Luka',
          value: 'Perawatan Luka'
        },
        {
          text: 'Pendampingan Tenaga Khusus Dirumah',
          value: 'Pendampingan Tenaga Khusus Dirumah'
        },
        {
          text: 'Bantuan Untuk Melakukan Aktifitas Fisik (Kursi Roda, Alat Bantu Jalan)',
          value:
            'Bantuan Untuk Melakukan Aktifitas Fisik (Kursi Roda, Alat Bantu Jalan)'
        }
      ]
    }
  },
  kriteria_discharge_planning: {
    umur_diatas_65_tahun: {
      widget: 'wradio',
      label: 'Umur > 65 Tahun',
      col: 6,
      data: [
        {
          text: 'Ya',
          value: 'Ya'
        },
        {
          text: 'Tidak',
          value: 'Tidak'
        }
      ]
    },
    keterbatasan_mobilitas: {
      widget: 'wradio',
      label: 'Keterbatasan Mobilitas',
      col: 6,
      data: [
        {
          text: 'Ya',
          value: 'Ya'
        },
        {
          text: 'Tidak',
          value: 'Tidak'
        }
      ]
    },
    perawatan_atau_pengobatan_lanjutan: {
      widget: 'wradio',
      label: 'Perawatan Atau Pengobatan Lanjutan',
      col: 6,
      data: [
        {
          text: 'Ya',
          value: 'Ya'
        },
        {
          text: 'Tidak',
          value: 'Tidak'
        }
      ]
    },
    bantuan_untuk_melakukan_aktifitas_sehari_sehari: {
      widget: 'wradio',
      label: 'Bantuan Untuk Melakukan Aktifitas Sehari Sehari',
      col: 6,
      data: [
        {
          text: 'Ya',
          value: 'Ya'
        },
        {
          text: 'Tidak',
          value: 'Tidak'
        }
      ]
    }
  }
}
