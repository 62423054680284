<template>
  <v-container fluid class="mb-5 mt-1">
    <v-row>
      <v-col cols="12" lg="12" md="12" sm="12">
        <v-card color="teal darken-1" dark>

          <v-card-text>
            <v-btn @click="showDialogLembarKonsultasi = true"
              >List Lembar Konsultasi</v-btn
            >

            <v-row class="mt-2 mb-0">
              <v-col
                v-for="(v, i) in master.demografi"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                class="mt-0 mb-1 pt-0 pb-3"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.demografi[i] = e)"
                  :value="data.demografi[i]"
                  vclass="c-text-field"
                  :data="v.data"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <div class="mt-2" v-if="data.demografi.notes">
          <v-alert shaped prominent type="error">
            {{ data.demografi.notes }}
          </v-alert>
        </div>
        <v-card>
          <v-card-title>Form Lembar Konsultasi</v-card-title>
          <v-card-text>
            <v-row class="mt-2 mb-0">
              <v-col
                v-for="(v, i) in master.inputan_dokter"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                class="mt-0 mb-0 pt-0 pb-3"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.inputan_dokter[i] = e)"
                  :value="data.inputan_dokter[i]"
                  vclass="c-text-field"
                  :data="v.data"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col cols="12" lg="12" md="12" sm="12">
                <v-btn
                  @click="postSave"
                  color="primary"
                  medium
                  v-if="!!regid"
                  class="btn-block"
                >
                  Simpan Form
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <hr />
      </v-col>
      <v-dialog v-model="showDialogLembarKonsultasi" max-width="650">
        <v-card>
          <v-card-text>
            <v-col cols="12" lg="12" md="12" sm="12">
              <nav
                aria-label="Page navigation example"
                class="justify-content-center"
              >
                <ul class="pagination">
                  <li class="page-item">
                    <a class="page-link" @click="initPermintaanPreviosPage"
                      >Sebelumnya</a
                    >
                  </li>
                  <li class="page-item">
                    <a
                      class="page-link"
                      v-html="permintaan_pagination_container"
                    ></a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" @click="initPermintaanNextPage"
                      >Selanjutnya</a
                    >
                  </li>
                </ul>
              </nav>
              <div
                class="list-group"
                v-for="(itemList, i) of listKonsultasi"
                :key="i"
              >
                <!-- {{itemList}} -->
                <div
                  class="list-group-item list-group-item-action flex-column align-items-start mb-2"
                >
                  <div class="d-flex w-100 justify-content-between">
                    <h6 class="mb-0">
                      <v-icon left medium>mdi-doctor</v-icon
                      >{{
                        itemList.namaDokter.text ? itemList.namaDokter.text : ""
                      }}
                      &nbsp;<v-btn
                        @click="hapusLembarKonsultasi(itemList)"
                        fab
                        color="red darken-2"
                        dark
                        x-small
                        ><v-icon>mdi-delete</v-icon></v-btn
                      >
                    </h6>
                  </div>
                  <small
                    >Tanggal Dikonsultasikan :
                    <b
                      ><u>{{ itemList.tanggal }}</u></b
                    ></small
                  >
                  <p>
                    Konsultasi Yang Diminta :
                    <b
                      ><u>{{ itemList.konsultasiYangDiminta }}</u></b
                    >
                  </p>
                  <v-divider></v-divider>

                  <p class="mb-2">
                    <span class="red--text"
                      ><v-icon left small>mdi-file</v-icon> Diagnosis</span
                    >
                    : {{ itemList.diagnosis }}<br />
                    <span class="red--text"
                      ><v-icon left small>mdi-history</v-icon> Riwayat Klinik
                      Singkat</span
                    >
                    : {{ itemList.riwayat }}
                  </p>
                  <v-divider />

                  <div class="mt-1">
                    <v-textarea
                      outlined
                      v-model="itemList.jawaban"
                      @blur="simpanjawaban(itemList)"
                      label="Jawaban Konsultasi"
                    ></v-textarea>

                    <v-row centered>
                      <v-col cols="12" lg="6" md="6" sm="6">
                        <v-btn
                          color="cyan lighten-1"
                          @click="cetaknya(itemList, '')"
                          medium
                          class="btn-block"
                          dark
                          >Lihat Lembar Konsultasi</v-btn
                        >
                      </v-col>
                      <v-col
                        v-if="
                          pegawai.is_dokter &&
                          pegawai?.sub_unit_id_dokter != itemList.namaDokter.id
                        "
                        cols="12"
                        lg="6"
                        md="6"
                        sm="6"
                      >
                        <v-btn
                          @click="kirimkedokter(itemList)"
                          color="green darken-1"
                          dark
                          class="btn-block"
                          medium
                          >Kirim Ke Dokter Konsulen</v-btn
                        >
                      </v-col>
                    </v-row>
                  </div>
                </div>
              </div>
            </v-col>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import SmartWidget from '@/components/SmartWidget.vue'
import Master from '../../data/lembar-konsultasi-igd'
import { mapActions } from 'vuex'
import Dialog from '@/components/Dialog'
import {
  apiKedua,
  // eslint-disable-next-line no-unused-vars
  successMsg,
  // eslint-disable-next-line no-unused-vars
  baseUrl,
  // eslint-disable-next-line no-unused-vars
  baseUrlV2,
  // eslint-disable-next-line no-unused-vars
  baseUrlEmr,
  // eslint-disable-next-line no-unused-vars
  errorMsg
} from '../../plugins/supports'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    SmartWidget,
    // eslint-disable-next-line vue/no-unused-components
    DialogConfirm: Dialog
  },
  data () {
    return {
      url_emr: 'http://emr.rs-syafira.com/',
      menukanan: [
        {
          text: 'Form Lembar Konsultasi',
          level: 3,
          to: '#test0'
        },
        {
          text: 'History Konsultasi',
          level: 4,
          to: '#test1'
        }
      ],
      searchKonsultasi: '',
      permintaans_page: 1,
      showDialogLembarKonsultasi: false,
      regid: this.$route.query.registration_id,
      kirimpesan: 'Assalammualaikum',
      permintaans_per_page: 9,
      permintaan_pagination_container: '',
      permintaans_total: '',
      isActive: false,
      items: ['Rawat Bersama', 'Ahli Rawat', 'Konsul'],
      master: Master,
      user: JSON.parse(localStorage.getItem('user')),
      namaDokter: '',
      listKonsultasi: [],
      pegawai: '',
      sub: this.$route.query.sub_unit_id,
      data: {
        demografi: {
          nama_pasien: '',
          no_rekam_medis: '',
          tanggal_lahir: '',
          jenis_kelamin: '',
          cara_bayar: '',
          reg_id: '',
          folio_id: ''
        },
        inputan_dokter: {
          tanggal: '',
          jam: '',
          kepada: '',
          poli: '',
          riwayat: '',
          diagnosis: '',
          konsultasi: '',
          jawaban: ''
        }
      },
      rules: {
        required: (value) => {
          // return !isEmpty(value) || 'Tidak Boleh Kosong'
          return true
        }
      },
      dialogShow: false,
      dialogTitle: 'Simpan',
      dialogMessage: 'Simpan Form Sekarang?',
      dialogProgress: false,
      dialogDisableNBtn: false,
      dialogDisablePBtn: false,
      dialogActionNBtn: () => {
        this.dialogShow = false
      },
      dialogActionPBtn: () => this.postSave()
    }
  },
  mounted () {
    // this.getDataListKonsultasi()
  },
  created () {
    if (!this.$route.query.no_rm) {
      errorMsg('PILIH DAHULU PASIENNYA')
      this.$router.push({
        name: 'DataPasienRawatInap'
      })
    }
    // this.set_contents(this.menukanan)
    if (this.$route.query.no_rm != null) {
      this.pegawai = JSON.parse(localStorage.getItem('user'))

      this.getDataPasien(this.$route.query.no_rm)
      // this.getDataLembarKonsultasi(this.folioYangDipakai)
      // this.getDokter()
      this.getDataListKonsultasi()
      this.time()
    }
  },
  methods: {
    cekKonsul (RESULTS) {
      console.log(RESULTS.namaDokter.id + 'konsul')
      if (RESULTS?.namaDokter?.id === this.pegawai.sub_unit_id_dokter) {
        return false
      } else {
        return true
      }
    },
    hapusLembarKonsultasi (id) {
      console.log(id)

      // eslint-disable-next-line no-undef
      Swal.fire({
        title: 'Perhatian! Apakah Anda Yakin?',
        text: 'Ingin Menghapus Lembar Konsultasi Ini.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya, Hapus',
        cancelButtonText: 'Tidak Jadi Batal',
        html: ''
      }).then((result) => {
        if (result.value) {
          // eslint-disable-next-line no-undef
          $.get(
            baseUrl + 'igd/hapus-lembar-konsul-by-id',
            {
              id: id.id_lembar_konsultasi,
              by: this.pegawai.employee_name
            },
            (res) => {
              // eslint-disable-next-line no-unused-vars
              const { con, msg, results } = res
              if (con) {
                successMsg(msg)
                this.getDataListKonsultasi()
              } else {
                errorMsg(msg)
              }
            },
            'json'
          )
        }
      })
    },
    // ...mapMutations(['set_contents']),
    activeClass () {},
    ...mapActions(['postFormLembarKonsultasi']),
    getDataListKonsultasi () {
      // eslint-disable-next-line no-undef
      $.post(
        baseUrl + 'igd/list-konsultasi',
        {
          rm: this.$route.query.no_rm,
          pg: this.permintaans_page,
          ppg: this.permintaans_per_page,
          pls: this.searchKonsultasi
        },
        (response) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = response
          const pagination = results.pagination
          this.permintaan_pagination_container = pagination
          this.permintaans_total = results.total_pages
          this.listKonsultasi = results.list
          // console.log(response)
        },
        'JSON'
      )
    },
    simpanjawaban (RESULTS) {
      console.log(RESULTS)

      // eslint-disable-next-line no-undef
      $.get(
        baseUrl + 'igd/simpan-jawaban-konsultasi',
        {
          id_lembar_konsultasi: RESULTS.id_lembar_konsultasi,
          jawaban: RESULTS.jawaban
        },
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            successMsg(msg)
          } else {
            errorMsg(msg)
          }
        },
        'json'
      )
    },
    kirimkedokter (value) {
      // eslint-disable-next-line no-undef
      Swal.fire({
        title: '',
        text: 'Apakah Anda Yakin Ingin Mengirim Lembar Konsultasi Ini Ke Dokter Konsulen?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya, Kirim',
        cancelButtonText: 'Tidak Jadi Batal',
        html: ''
      }).then((result) => {
        if (result.value) {
          // eslint-disable-next-line no-undef
          $.get(
            baseUrl +
              'igd/kirim-lembar-konsultasi?id_lembar_konsultasi=' +
              value.id_lembar_konsultasi +
              '&id_pembuat=' +
              this.pegawai.employee_id,
            (res) => {
              // eslint-disable-next-line no-unused-vars
              const { con, msg, results } = res
              if (con) {
                successMsg(msg)
              } else {
                errorMsg(msg)
              }
            },
            'JSON'
          )
        }
      })
    },
    simpanJawabanKonsultasi (item) {
      // this.data.inputan_dokter = item
      for (var i = 0; i < this.listKonsultasi.length; i++) {
        if (this.listKonsultasi[i].namaDokter.id === item.namaDokter.id) {
          // console.log(item)

          //   console.log(item)
          // eslint-disable-next-line no-unused-vars
          // item.inputan_dokter.jawaban = item.jawaban
          const data = {
            data: item,
            update_by: this.user.employee_id
          }
          // eslint-disable-next-line no-undef
          $.post(
            baseUrlV2 + 'poliklinik/simpan-konsultasi',
            data,
            (response) => {
              const { con, msg, results } = response
              if (con) {
                successMsg(msg)
              } else {
                errorMsg(msg + ' ' + JSON.stringify(results))
              }
            },
            'JSON'
          )
          //   console.log(data + 'HASI;LNYA')
        }
      }
    },
    getDataPasien (v) {
      apiKedua
        .get('emr/detail-pasien?rm=' + v, {})
        .then((response) => {
          this.dataPatient = response.data.results
          this.data.demografi = this.dataPatient
          this.data.demografi.jenis_kelamin = {
            value: this.dataPatient.jenis_kelamin,
            text: this.dataPatient.jenis_kelamin
          }
          this.data.demografi.folio_id = this.$route.query.folio_id
          this.data.demografi.reg_id = this.$route.query.registration_id
          this.data.demografi.sub_unit_id = this.$route.query.sub_unit_id

          this.patient_name = response.data.results.nama_pasien
          const date = new Date(response.data.results.tanggal_lahir)

          const day = date.toLocaleString('default', { day: '2-digit' })
          const month = date.toLocaleString('default', { month: 'short' })
          const year = date.toLocaleString('default', { year: 'numeric' })
          // console.log(response.data.results.tanggal_lahir)
          const tglLahir = day + '/' + month + '/' + year
          this.data.demografi.umur = this.umur(tglLahir)
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    umur (tglLahir) {
      var dob = new Date(tglLahir)
      var dobYear = dob.getYear()
      var dobMonth = dob.getMonth()
      var dobDate = dob.getDate()
      var yearAge = ''
      // get the current date from the system
      var now = new Date()
      // extract the year, month, and date from current date
      var currentYear = now.getYear()
      var currentMonth = now.getMonth()
      var currentDate = now.getDate()

      // declare a variable to collect the age in year, month, and days
      var age = {}
      var ageString = ''

      // get years
      yearAge = currentYear - dobYear

      // get months
      if (currentMonth >= dobMonth) {
        // get months when current month is greater
        var monthAge = currentMonth - dobMonth
      } else {
        yearAge--
        // eslint-disable-next-line no-redeclare
        var monthAge = 12 + currentMonth - dobMonth
      }

      // get days
      if (currentDate >= dobDate) {
        // get days when the current date is greater
        var dateAge = currentDate - dobDate
      } else {
        monthAge--
        // eslint-disable-next-line no-redeclare
        var dateAge = 31 + currentDate - dobDate

        if (monthAge < 0) {
          monthAge = 11
          yearAge--
        }
      }
      // group the age in a single variable
      age = {
        years: yearAge,
        months: monthAge,
        days: dateAge
      }

      if (age.years > 0 && age.months > 0 && age.days > 0) {
        ageString =
          age.years +
          ' Tahun, ' +
          age.months +
          ' Bulan, dan ' +
          age.days +
          ' Hari.'
      } else if (age.years === 0 && age.months === 0 && age.days > 0) {
        ageString = ' ' + age.days + ' Hari'
        // eslint-disable-next-line brace-style
      }
      // when current month and date is same as birth date and month
      else if (age.years > 0 && age.months === 0 && age.days === 0) {
        ageString = age.years + ' Tahun. Happy Birthday!!'
      } else if (age.years > 0 && age.months > 0 && age.days === 0) {
        ageString = age.years + ' Tahun dan ' + age.months + ' Bulan.'
      } else if (age.years === 0 && age.months > 0 && age.days > 0) {
        ageString = age.months + ' Bulan dan ' + age.days + ' Hari.'
      } else if (age.years > 0 && age.months === 0 && age.days > 0) {
        ageString = age.years + ' Tahun, dan' + age.days + ' Hari.'
      } else if (age.years === 0 && age.months > 0 && age.days === 0) {
        ageString = age.months + ' Bulan.'
        // eslint-disable-next-line brace-style
      }
      // when current date is same as dob(date of birth)
      else {
        ageString =
          age.years +
          ' Tahun, ' +
          age.months +
          ' Bulan, dan ' +
          age.days +
          ' Hari.'
      }

      // console.log(ageString)
      return ageString
    },
    cetaknya (item, view) {
      // console.log(item)
      window
        .open(
          baseUrlEmr +
            `laporan-medis/cetak-lembar-konsultasi?folio_id=${item.folio_id}&id_lembar=${item.id_lembar_konsultasi}`,
          '_blank'
        )
        .focus()
    },
    myFilter () {
      this.isActive = !this.isActive
      // some code to filter users
    },
    postSave () {
      if (!this.data.inputan_dokter.kepada) {
        errorMsg('Mohon Diisi Nama Dokter Yang Akan Dikonsulkan')
        return
      }
      if (!this.data.inputan_dokter.diagnosis) {
        errorMsg('Mohon Diisi Diagnosis Yang Akan Dikonsulkan')
        return
      }
      if (!this.data.inputan_dokter.riwayat) {
        errorMsg('Mohon Diisi Riwayat yang dikonsulkan')
        return
      }
      if (!this.data.inputan_dokter.konsultasi) {
        errorMsg('Mohon Diisi Konsultasi yang diminta')
        return
      }

      console.info(this.data.inputan_dokter.kepada.text)
      var dokter = this.data.inputan_dokter.kepada.text
      // eslint-disable-next-line no-undef
      Swal.fire({
        title: '',
        text: 'Ingin Menyimpan Lembar Konsultasi Ini.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya, Simpan Resume',
        cancelButtonText: 'Tidak Jadi Batal',
        html: `<p>Perhatian! Apakah Anda Yakin Pasien Atas Nama ${this.data.demografi.nama_pasien} Tanggal Lahir ${this.data.demografi.tanggal_lahir} <b>No. RM ${this.data.demografi.no_rekam_medis}</b> <b>Di Konsultasikan Ke Dokter ${dokter}</b></p>`
      }).then((result) => {
        if (result.value) {
          const dataForm = {
            // eslint-disable-next-line no-undef
            data: LZString.compressToBase64(JSON.stringify(this.data)),
            created_by: this.user.employee_id,
            no_rm: this.$route.query.no_rm,
            nama: this.user.employee_name,
            employee_id: this.user.employee_id,
            folio_id: this.$route.query.folio_id,
            registration_id: this.$route.query.registration_id,
            sub_unit_id: this.$route.query.sub_unit_id
          }
          // console.log(dataForm)

          // eslint-disable-next-line no-undef
          $.post(
            baseUrl + 'igd/simpan-lembar-konsultasi',
            dataForm,
            (res) => {
              // console.log(res)
              // eslint-disable-next-line no-unused-vars
              const { con, msg, results } = res

              if (con) {
                this.disablebuttonSimpan = false
                this.color = 'primary'
                successMsg(msg)
                setTimeout(() => {
                  // this.sip()
                  this.getDataListKonsultasi()
                }, 100)
                this.data.inputan_dokter = {
                  tanggal: '',
                  jam: '',
                  kepada: '',
                  poli: '',
                  riwayat: '',
                  diagnosis: '',
                  konsultasi: '',
                  jawaban: ''
                }
              } else {
                this.disablebuttonSimpan = false
                this.color = 'primary'
                errorMsg(msg)
              }
            },
            'JSON'
          )
          // resetBtnLoading(btn, html)
        } else {
          this.disablebuttonSimpan = false
          this.color = 'primary'
          // resetBtnLoading(btn, html)
          // errorMsg('Nggak Jadi Deh !!!')
        }
      })
    },
    getDataLembarKonsultasi (v) {
      apiKedua
        .get(
          'emr/simpan-lembar-konsultasi?norm=' +
            this.$route.query.no_rm +
            '&folio_id=' +
            this.$route.query.folio_id +
            '&reg_id=' +
            this.$route.query.registration_id
        )
        .then((res) => {
          console.log(res)
          const response = res.data.results
          if (response) {
            this.data.inputan_dokter = response.inputan_dokter
          } else {
          }
          // this.data.input_soap = response.input_soap
        })
    },
    time () {},
    getAssemen (v) {
      // console.table(v)
      apiKedua
        .get(
          'emr/simpan-asesmen-keperawatan?norm=' +
            this.$route.query.no_rm +
            '&folio_id=' +
            this.$route.query.folio_id +
            '&reg_id=' +
            this.$route.query.registration_id
        )
        .then((res) => {
          // console.log(res)
          const response = res.data.results
          const con = res.data.con

          if (con) {
            this.data.inputan_dokter.diagnosis = this.appendTechnique(
              this.data.inputan_dokter.diagnosis,
              response.data.isian_dokter.asessmen_diagnosa_kerja
            )
          } else {
            // alert('data')
          }
          console.log(response)
        })
    },
    getDokter () {
      apiKedua.get('emr/get-dokter').then((res) => {
        const response = res.data.results
        const con = res.data.con
        // console.log(response)
        if (con) {
          this.master.inputan_dokter.kepada.data = response
        }
      })
    },
    fillFromAsesmen () {
      this.getAssemen(this.folioYangDipakai)
    },
    appendTechnique (satu, dua) {
      // var a = 'how you are'
      if (satu.indexOf(dua) > -1) {
        // jika sudah ada / contained
        return satu
      }
      if (satu !== '' && satu !== dua) {
        // jika tidak kosong dan tidak inputan yang sama dgn skrng
        // alert('sadads')
        return dua + '\n' + satu // append
      } else {
        return dua
      }
    },
    cariKonsultasi () {
      console.log(this.searchKonsultasi)
    },
    initPermintaanPreviosPage () {
      var currentPage = parseInt(this.permintaans_page)
      if (currentPage > 1) {
        currentPage = currentPage - 1
      } else {
        currentPage = 1
      }
      this.permintaans_page = currentPage
      this.getDataListKonsultasi()
    },
    initPermintaanNextPage () {
      let no = 1
      var currentPage = parseInt(this.permintaans_page)
      var totalPages = parseInt(this.permintaans_total)

      console.log(currentPage)
      console.log(totalPages)
      no = currentPage + 1

      if (currentPage === totalPages) {
        no = currentPage
      }
      // $('#permintaans_page').val(no)
      this.permintaans_page = no
      this.getDataListKonsultasi()
    }
  }
}
</script>

<style scoped>
.float-group {
  position: fixed;
  bottom: 160px;
  right: 40px;
  color: #fff;
  text-align: center;
  z-index: 100;
}

.float {
  position: fixed;
  bottom: 160px;
  right: 40px;
  color: #fff;
  text-align: center;
  z-index: 100;
}
</style>
