<template>
  <v-row justify="center">
    <v-dialog v-model="showDialog" :persistent="true" max-width="780">
      <v-card>
        <v-card-title class="headline"> Form USG </v-card-title>
        <v-card-text class="mt-3">
          <div>
            <textarea
              placeholder="Hasil Bacaan"
              v-model="content"
              class="form-control"
              rows="10"
            ></textarea>
          <v-divider class="mt-3 mb-6" />
          <h3><b>Hasil Bacaaan</b></h3>
          <v-divider class="mt-3 mb-6" />
          <div class="mt-2 m-1" v-html="rawHtml"></div>
          <v-btn color="green" @click="cetakprint" class="mt-2" dark>Cetak Hasil USG</v-btn>

          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            v-if="negativeButton"
            color="green darken-1"
            text
            :disabled="disabledNegativeBtn"
            @click="negativeButton"
          >
            Tutup
          </v-btn>
          <v-btn color="primary darken-2" @click="simpan" text>
            Simpan USG</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import Editor from 'vue-tinymce-editor'
import 'tinymce/tinymce'

import {
  // eslint-disable-next-line no-unused-vars
  apiKedua,
  // eslint-disable-next-line no-unused-vars
  successMsg,
  // eslint-disable-next-line no-unused-vars
  baseUrl,
  // eslint-disable-next-line no-unused-vars
  baseUrlV2,
  // eslint-disable-next-line no-unused-vars
  errorMsg,
  baseUrlEmr
} from '../plugins/supports'
export default {
  components: {
    // Editor: Editor
  },
  name: 'Dialog',
  props: {
    showDialog: {
      type: Boolean,
      default: false,
      required: false
    },
    persistentDialog: {
      type: Boolean,
      default: true,
      required: false
    },
    positiveBtnLabel: {
      type: String,
      default: 'Ya',
      required: false
    },
    negativeBtnLabel: {
      type: String,
      default: 'Tidak',
      required: false
    },
    positiveButton: {
      type: [Function, Boolean],
      default: () => {},
      required: false
    },
    negativeButton: {
      type: [Function, Boolean],
      default: () => {},
      required: false
    },
    disabledNegativeBtn: {
      type: Boolean,
      default: false,
      required: false
    },
    disabledPositiveBtn: {
      type: Boolean,
      default: false,
      required: false
    },
    title: {
      type: String,
      default: null,
      required: false
    },
    message: {
      type: String,
      default: '',
      required: false
    },

    itemUse: {
      require: false,
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      content: '',
      tinymceConfig: {
        height: 500
        // Other TinyMCE configuration options
      },
      editorId: 'my-editor', // Unique ID for the editor

      config: {},
      noHp: '',
      items: [],
      searchDokter: '',
      noNik: '',
      valid: true,
      isReadonly: false,
      isLoading: false,
      showDialogAcc: false,
      NamaPasien: '',
      selected: ['John'],
      NoRm: '',
      formUsg: {
        hasilUsg: ''
      },
      rawHtml: '',
      resultsUSG: '',
      progress: false,
      disabled: false,
      pegawai: '',
      listLogs: [],
      url_emr: 'http://emr.rs-syafira.com/'
    }
  },
  created () {
    setTimeout(() => {
      console.log(this.itemUse)
      this.noHp = this.itemUse.demografi.handphone
      this.NamaPasien = this.itemUse.demografi.nama_pasien
      this.NoRm = this.itemUse.demografi.no_rekam_medis
      this.noNik = this.itemUse.demografi.nik
      this.pegawai = JSON.parse(localStorage.getItem('user'))
    }, 1000)

    // console.log('DATA DATA =>>> ' + this.itemUse.demografi.handphone)
  },
  mounted () {
    // Initialize TinyMCE editor
    // Editor.init({
    //   selector: this.$refs.editorContainer,
    //   height: 400
    //   // Other TinyMCE configuration options
    // })
  },

  methods: {
    cetakprint () {
      window
        .open(
          baseUrlEmr + 'laporan-medis/cetak-hasil-usg-medis?folio_id=' + this.$route.query.folio_id,
          '_blank'
        )
        .focus()
    },
    simpan () {
      // eslint-disable-next-line no-unused-vars
      var data = {
        noRm: this.NoRm,
        pegawai: this.pegawai.employee_id,
        folio_id: this.$route.query.folio_id,
        registration_id: this.$route.query.registration_id,
        sub_unit_id: this.$route.query.sub_unit_id,
        namaPegawai: this.pegawai.employee_name,
        hasilUsg: this.content
      }
      console.log(data)

      if (!this.content) {
        errorMsg('Hasil USG Tidak Boleh Kosong')
        return true
      }

      // eslint-disable-next-line no-undef
      $.post(baseUrl + 'emr/simpan-hasil-usg', data, (res) => {
        // eslint-disable-next-line no-unused-vars
        const { con, msg, results } = res
        if (con) {
          successMsg(msg)
          this.getHasilUsg()
        } else {
          errorMsg(msg)
        }
      }, 'JSON')
    },
    getHasilUsg () {
      // eslint-disable-next-line camelcase, no-unused-vars
      const registration_id = this.$route.query.registration_id
      // eslint-disable-next-line no-undef
      $.get(baseUrl + 'emr/get-hasil-usg', { reg_id: registration_id }, (res) => {
        // eslint-disable-next-line no-unused-vars
        const { con, msg, results } = res
        if (con) {
          const regex = /(<([^>]+)>)/ig

          this.content = results.pemeriksaan.replace(regex, '')
          this.rawHtml = results.pemeriksaan

          successMsg(msg)
        } else {
          errorMsg(msg)
        }
      }, 'JSON')
    }
  },
  watch: {}
}
</script>

<style scoped>
/* Style adjustments if needed */
.ck.ck-toolbar {
  display: block !important;
}
</style>
