export default {
  demografi: {
    nama_pasien: {
      label: 'Nama Pasien',
      widget: 'wtext',
      data: null,
      col: 3
    },
    no_rekam_medis: {
      label: 'No Rekam Medis',
      widget: 'wtext',
      data: null,
      col: 3
    },
    tanggal_lahir: {
      label: 'Tangga Lahir',
      widget: 'wdate',
      data: null,
      col: 3
    },
    cara_bayar: {
      label: 'Cara Bayar',
      widget: 'wtext',
      data: [],
      col: 3
    },
    ruangan: {
      widget: 'wtext',
      label: 'Ruangan',
      // data: [
      //   { text: 'Laki-Laki', value: 'laki-laki' },
      //   { text: 'Wanita', value: 'wanita' }
      // ],
      data: null,
      col: 3
    },
    kamar_kelas: {
      widget: 'wtext',
      data: null,
      label: 'Kamar Kelas',
      col: 3
    },
    jenis_kelamin: {
      widget: 'wradio',
      data: [
        { text: 'Laki-Laki', value: 'laki-laki' },
        { text: 'Wanita', value: 'wanita' }
      ],
      col: 6
    },
    folio_id: {
      widget: 'wtext',
      data: null,
      col: 4,
      label: 'No Folio'
    },
    sub_unit_id: {
      widget: 'wtext',
      data: null,
      col: 4,
      label: 'Sub Unit Dokter'
    },
    reg_id: {
      widget: 'wtext',
      data: null,
      label: 'No Reg',
      col: 4
    }
  },
  form0: {
    tanggal_pindah: {
      label: 'Tanggal Pindah',
      widget: 'wdatenormal',
      col: 12,
      data: []
    },
    jam_pindah: {
      label: 'Jam Pindah',
      widget: 'wtime',
      col: 6,
      data: []
    },
    dpjp: {
      label: 'DPJP 1',
      widget: 'wautocomplatesingle',
      col: 6,
      data: []
    },
    dpjp1: {
      label: 'DPJP 2',
      widget: 'wautocomplatesingle',
      col: 6,
      data: []
    },
    dpjp2: {
      label: 'DPJP 3',
      widget: 'wautocomplatesingle',
      col: 6,
      data: []
    },
    dpjp3: {
      label: 'DPJP 4',
      widget: 'wautocomplatesingle',
      col: 6,
      data: []
    },
    dpjp4: {
      label: 'DPJP 5',
      widget: 'wautocomplatesingle',
      col: 6,
      data: []
    },
    dari: {
      label: 'Dari Ruangan',
      widget: 'wtext',
      col: 6,
      data: [],
      disabled: true

    },
    ke: {
      label: 'Ke Ruangan',
      widget: 'wselect',
      col: 4,
      data: [
        { text: 'Melati', value: 'Melati' },
        { text: 'Mawar', value: 'Mawar' },
        { text: 'VK', value: 'VK' },
        { text: 'Asoka', value: 'Asoka' },
        { text: 'ICU', value: 'ICU' },
        { text: 'Cemara', value: 'Cemara' },
        { text: 'Sakura', value: 'Sakura' },
        { text: 'Ruang Bayi', value: 'Ruang Bayi' },
        { text: 'Flamboyan', value: 'Flamboyan' },
        { text: 'Rosela', value: 'Rosela' },
        { text: 'Crysan', value: 'Crysan' },
        { text: 'NICU', value: 'NICU' },
        { text: 'Kamar Operasi', value: 'Kamar Operasi' },
        { text: 'ICU', value: 'ICU' },
        { text: 'PICU', value: 'PICU' },
        { text: 'HCU', value: 'HCU' },
        { text: 'ICCU', value: 'ICCU' },
        { text: 'Ruang Observasi', value: 'Ruang Observasi' },
        { text: 'Kelas I', value: 'Kelas I' },
        { text: 'Kelas II', value: 'Kelas II' },
        { text: 'Kelas III', value: 'Kelas III' },
        { text: 'SCN I', value: 'SCN I' },
        { text: 'SCN II', value: 'SCN II' },
        { text: 'Endoscopy', value: 'Endoscopy' },
        { text: 'Hemodialisa', value: 'Hemodialisa' }
      ]
    },
    nomor_kamar: {
      label: 'Nomor Kamar',
      widget: 'wtext',
      col: 2,
      data: []
    }
  },
  form1: {
    riwayat_penyakit: {
      label: 'Riwayat Penyakit',
      widget: 'wtextarearow',
      col: 12,
      data: []
    },
    pemeriksaan_fisik: {
      label: 'Pemeriksaan Fisik',
      widget: 'wtextarearow',
      col: 12,
      data: []
    },
    kesadaran: {
      label: 'Kesadaran',
      widget: 'wtext',
      col: 4,
      data: []
    },
    kesadaran_gcs: {
      label: 'GCS',
      widget: 'wtext',
      col: 2,
      data: null
    },
    kesadaran_e: {
      label: 'E',
      widget: 'wtext',
      col: 2,
      data: null
    },
    kesadaran_m: {
      label: 'M',
      widget: 'wtext',
      col: 2,
      data: null
    },
    kesadaran_v: {
      label: 'V',
      widget: 'wtext',
      col: 2,
      data: null
    },
    tanda_vital: {
      label: 'Tanda Vital',
      widget: 'wlabelkiri',
      col: 2,
      data: []
    },
    tanda_vital_td: {
      label: 'TD',
      widget: 'wtext',
      col: 2,
      data: null
      // property: { attrs: { suffix: 'mmHG', type: 'text' } }
    },
    tanda_vital_suhu: {
      label: 'Suhu',
      widget: 'wtext',
      col: 2,
      data: null,
      property: { attrs: { suffix: 'ºC', type: 'text' } }
    },
    tanda_vital_nadi: {
      label: 'Nadi',
      widget: 'wtext',
      col: 2,
      data: null,
      property: { attrs: { suffix: 'x/mnt', type: 'text' } }
    },
    tanda_vital_rr: {
      label: 'RR',
      widget: 'wtext',
      col: 2,
      data: null,
      property: { attrs: { suffix: 'x/mnt', type: 'text' } }
    }
  },
  form2: {
    pemeriksaan_penunjang: {
      label: ' ',
      widget: 'wtextarearow',
      col: 12,
      data: []
    }
  },
  form3: {
    diagnosis: {
      label: ' ',
      widget: 'wtextarearow',
      col: 12,
      data: []
    }
  },
  form4: {
    indikasi_transfer: {
      label: ' ',
      widget: 'wtextarearow',
      col: 12,
      data: []
    }
  },
  form5: {
    tindakan_medis_sudah: {
      label: ' ',
      widget: 'wtextarearow',
      col: 12,
      data: []
    }
  },
  form6: {
    terapi: {
      label: ' ',
      widget: 'wtextarearow',
      col: 12,
      data: []
    }
    // obat_injeksi: {
    //   label: 'Obat Injeksi',
    //   widget: 'wtextarea',
    //   col: 12,
    //   data: []
    // },
    // obat_oral: {
    //   label: 'Obat Oral',
    //   widget: 'wtextarea',
    //   col: 12,
    //   data: []
    // },
    // lain_lain: {
    //   label: 'Lain-lain',
    //   widget: 'wtextarea',
    //   col: 12,
    //   data: []
    // }
  },
  form7: {
    keadaan_umum: {
      label: 'Keadaan Umum',
      widget: 'wtextarea',
      col: 12,
      data: []
    },
    kesadaran: {
      label: 'Kesadaran',
      widget: 'wtext',
      col: 4,
      data: []
    },
    kesadaran_gcs: {
      label: 'GCS',
      widget: 'wtext',
      col: 2,
      data: null
    },
    kesadaran_e: {
      label: 'E',
      widget: 'wtext',
      col: 2,
      data: null
    },
    kesadaran_m: {
      label: 'M',
      widget: 'wtext',
      col: 2,
      data: null
    },
    kesadaran_v: {
      label: 'V',
      widget: 'wtext',
      col: 2,
      data: null
    },
    tanda_vital: {
      label: 'Tanda Vital',
      widget: 'wlabelkiri',
      col: 2,
      data: []
    },
    tanda_vital_td: {
      label: 'TD',
      widget: 'wtext',
      col: 2,
      data: null,
      property: { attrs: { suffix: 'mmHG', type: 'text' } }
    },
    tanda_vital_suhu: {
      label: 'Suhu',
      widget: 'wtext',
      col: 2,
      data: null,
      property: { attrs: { suffix: 'ºC', type: 'text' } }
    },
    tanda_vital_nadi: {
      label: 'Nadi',
      widget: 'wtext',
      col: 2,
      data: null,
      property: { attrs: { suffix: 'x/mnt', type: 'text' } }
    },
    tanda_vital_rr: {
      label: 'RR',
      widget: 'wtext',
      col: 2,
      data: null,
      property: { attrs: { suffix: 'x/mnt', type: 'text' } }
    },
    catatan_penting: {
      label: 'Catatan Penting',
      widget: 'wtextarea',
      col: 12,
      data: []
    }
  },
  form8: {
    keadaan_umum: {
      label: 'Keadaan Umum',
      widget: 'wtextarea',
      col: 12,
      data: []
    },
    kesadaran: {
      label: 'Kesadaran',
      widget: 'wtext',
      col: 4,
      data: []
    },
    kesadaran_gcs: {
      label: 'GCS',
      widget: 'wtext',
      col: 2,
      data: null
    },
    kesadaran_e: {
      label: 'E',
      widget: 'wtext',
      col: 2,
      data: null
    },
    kesadaran_m: {
      label: 'M',
      widget: 'wtext',
      col: 2,
      data: null
    },
    kesadaran_v: {
      label: 'V',
      widget: 'wtext',
      col: 2,
      data: null
    },
    tanda_vital: {
      label: 'Tanda Vital',
      widget: 'wlabelkiri',
      col: 2,
      data: []
    },
    tanda_vital_td: {
      label: 'TD',
      widget: 'wtext',
      col: 2,
      data: null,
      property: { attrs: { suffix: 'mmHG', type: 'text' } }
    },
    tanda_vital_suhu: {
      label: 'Suhu',
      widget: 'wtext',
      col: 2,
      data: null,
      property: { attrs: { suffix: 'ºC', type: 'text' } }
    },
    tanda_vital_nadi: {
      label: 'Nadi',
      widget: 'wtext',
      col: 2,
      data: null,
      property: { attrs: { suffix: 'x/mnt', type: 'text' } }
    },
    tanda_vital_rr: {
      label: 'RR',
      widget: 'wtext',
      col: 2,
      data: null,
      property: { attrs: { suffix: 'x/mnt', type: 'text' } }
    },
    catatan_penting: {
      label: 'Catatan Penting',
      widget: 'wtextarea',
      col: 12,
      data: []
    }
  }
}
