<template>
  <v-row justify="center">
    <v-dialog v-model="showDialog" :persistent="true" max-width="1280">
      <v-card>
        <v-card-title class="headline">
          ICARE
          <v-btn
            v-if="negativeButton"
            color="red darken-1"
            class="mb-2 mt-2 btn-block"
            dark
            :disabled="disabledNegativeBtn"
            @click="negativeButton"
          >
            Tutup
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div id="hasil-icare">
            <!-- {{ url }} -->

            <p>
              <b>
                i-C a r e J K N (Informasi Pencarian Riwayat Pelayanan
                Kesehatan)
              </b>
              merupakan Fitur yang memberikan kemudahan bagi Dokter dalam
              mengetahui data riwayat pelayanan kesehatan pasien peserta JKN
              antar fasilitas kesehatan yang telah bekerjasama dengan BPJS
              Kesehatan.
            </p>
            <iframe
              :src="url"
              frameborder="0"
              style="overflow: hidden; height: 900px; width: 100%"
              height="900px"
              width="100%"
            ></iframe>
          </div>
        </v-card-text>
        <v-card-actions class="headline">
          <v-spacer />
          <v-btn
            v-if="negativeButton"
            color="green darken-1"
            text
            :disabled="disabledNegativeBtn"
            @click="negativeButton"
          >
            Tutup
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import {
  // eslint-disable-next-line no-unused-vars
  apiKedua,
  // eslint-disable-next-line no-unused-vars
  successMsg,
  // eslint-disable-next-line no-unused-vars
  baseUrl,
  // eslint-disable-next-line no-unused-vars
  baseUrlV2,
  // eslint-disable-next-line no-unused-vars
  errorMsg
} from '../plugins/supports'
export default {
  name: 'Dialog',
  props: {
    showDialog: {
      type: Boolean,
      default: false,
      required: false
    },
    persistentDialog: {
      type: Boolean,
      default: true,
      required: false
    },
    positiveBtnLabel: {
      type: String,
      default: 'Ya',
      required: false
    },
    negativeBtnLabel: {
      type: String,
      default: 'Tidak',
      required: false
    },
    positiveButton: {
      type: [Function, Boolean],
      default: () => {},
      required: false
    },
    negativeButton: {
      type: [Function, Boolean],
      default: () => {},
      required: false
    },
    disabledNegativeBtn: {
      type: Boolean,
      default: false,
      required: false
    },
    disabledPositiveBtn: {
      type: Boolean,
      default: false,
      required: false
    },
    title: {
      type: String,
      default: null,
      required: false
    },
    message: {
      type: String,
      default: '',
      required: false
    },

    itemUse: {
      require: false,
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      noHp: '',
      items: [],
      searchDokter: '',
      noNik: '',
      valid: true,
      isReadonly: false,
      isLoading: false,
      showDialogAcc: false,
      NamaPasien: '',
      selected: ['John'],
      NoRm: '',
      url: '',
      SEP: '',
      rulesSep: [
        (v) =>
          !!v || 'No.SEP Tidak Boleh Kosong, Pastikan Inputan SEPnya Benar!'
      ],

      isSep: false,
      isBridging: false,
      radioGroup: 1,
      row: 'nonBridging',
      pesan:
        'Assalammulaikum, Salam Sejahtera. Berikut Kami Kirim Resume Medis Rawat Jalan',
      progress: false,
      disabled: false,
      pegawai: '',
      listLogs: [],
      url_emr: 'http://emr.rs-syafira.com/'
    }
  },
  created () {
    setTimeout(() => {
      console.log(this.itemUse)
      // this.noHp = this.itemUse.demografi.handphone
      // this.NamaPasien = this.itemUse.demografi.nama_pasien
      // this.NoRm = this.itemUse.demografi.no_rekam_medis
      // this.noNik = this.itemUse.demografi.nik
      // this.pegawai = JSON.parse(localStorage.getItem('user'))
      // this.listLog()
    }, 1000)

    // console.log('DATA DATA =>>> ' + this.itemUse.demografi.handphone)
  },
  mounted () {
    // setTimeout(() => {
    //   this.cariRiwayatIcare()
    // }, 1000)
  },
  methods: {
    cariRiwayatIcare () {
      // eslint-disable-next-line no-undef
      $.get(
        baseUrlV2 + `i-care/index?folio_id=${this.$route?.query?.folio_id}`,
        (response) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = response
          if (con) {
            // eslint-disable-next-line no-undef
            // $('#hasil-icare').html(results.url)
            this.url = results.url

            // this.items = results
          } else {
            errorMsg(msg)
            // console.log('DATA TIDAK DITEMUKAN')
          }
        },
        'JSON'
      )
      this.$forceUpdate()
    }
  },
  watch: {}
}
</script>

<style scoped></style>
