<template>
  <v-row justify="center">
    <v-dialog v-model="showDialog" :persistent="true" max-width="780">
      <v-card>
        <v-card-title class="headline">
          Kirim Surat Kontrol Ke Pasien
        </v-card-title>
        <v-card-text>
          <v-tabs v-model="tab" centered>
            <v-tab>Surat Kontrol</v-tab>
            <v-tab>Rujukan Internal/Rujukan Antar RS/Asuransi</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <div class="red--text mb-2 mt-1">
                Perhatian, Untuk Surat Kontrol Diinput Wajib Memilih SEP Yang
                Dicetak Pada <b>Hari H Layanan</b>. Jika Pasien Sesudah
                <b>POST Rawat Inap</b> (Sesudah Dirawat inap)
                <b
                  >Maka SEP Yang Dipilih SEP Sebelum Rawat Inap Yaitu SEP Rawat
                  Jalannya</b
                >.
              </div>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-row class="mt-2">
                  <v-col cols="12" sm="4" md="4" lg="4">
                    <v-select
                      v-model="SEP"
                      :items="items"
                      return-object
                      outlined
                      :rules="rulesSep"
                      label="Pilih SEP"
                      dense
                    >
                      <template v-slot:item="{ item }">
                        <b>Jenis Layanan:&nbsp;</b>
                        <span v-html="item.jenisPelayanan"></span>&nbsp;
                        <b>No.SEP:</b>&nbsp;- {{ item.text }}&nbsp;<b
                          >Tanggal SEP</b
                        >:&nbsp;<span
                          class="red--text"
                          v-html="item.tgl_sep"
                        ></span
                        >&nbsp;<b>Poli</b>:&nbsp;<span
                          v-html="item.poli"
                        ></span>
                        &nbsp;<b>No.Rujukan</b>:&nbsp;<span
                          v-html="item.noRujukan"
                        ></span>
                      </template>
                    </v-select>
                    <v-text-field
                      v-model="noNik"
                      outlined
                      dense
                      disabled
                      label="No.NIK"
                    ></v-text-field>
                    <v-btn class="btn-block" color="green" dark medium
                      >Sesuaikan Data Peserta
                    </v-btn>
                  </v-col>
                  <v-col cols="12" sm="8" md="8" lg="8">
                    <v-card>
                      <v-card-text>
                        {{ SEP }}
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="4" md="4" lg="4">
                    <v-text-field
                      v-model="noHp"
                      outlined
                      dense
                      label="No. Hp Pasien"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="4" lg="4">
                    <v-text-field
                      v-model="NamaPasien"
                      label="Nama Pasien"
                      disabled
                      dense
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="4" lg="4">
                    <v-text-field
                      v-model="NoRm"
                      disabled
                      dense
                      label="No. Rekam Medis"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12" lg="12">
                    <v-textarea
                      name="input-7-1"
                      label="Default Pesan Ke Pasien"
                      v-model="pesan"
                      outlined
                      rows="1"
                      hint="Hint text"
                    ></v-textarea>
                  </v-col>

                  <div
                    v-if="
                      itemUse.wa && itemUse.inputan_dokter.tanggal_lahir != '-'
                    "
                  >
                    <v-col cols="12" sm="12" md="12">
                      Kontrol Ulang Ke RS Syafira : Ke
                      <b> {{ itemUse.wa ? itemUse.wa.unit_name : "" }}</b>
                      <b>
                        {{ itemUse.wa ? itemUse.wa.text : "" }} Pada Tanggal
                        {{
                          itemUse.inputan_dokter.tanggal_lahir
                            | moment("DD/MM/YYYY")
                        }}</b
                      >

                      <v-btn
                        rounded
                        color="primary"
                        @click="showDialogForm"
                        class="mt-2 btn-block"
                        dark
                      >
                        Simpan Surat Kontrol
                      </v-btn>
                      <v-progress-linear
                        v-show="progress"
                        color="primary"
                        indeterminate
                        rounded
                        height="6"
                      />
                      <v-btn
                        rounded
                        color="red"
                        @click="sendWaKePasien"
                        class="mt-2 btn-block"
                        dark
                        :disabled="disabled"
                      >
                        Kirim Resume Ke Pasien
                      </v-btn>
                    </v-col>
                  </div>
                  <div v-else>
                    <v-col cols="12" sm="12" md="12">
                      <b class="primary--text">
                        Tanggal Kontrol dan Dokter Tujuan Tidak Di Isi!</b
                      >
                    </v-col>
                  </div>
                  <v-col
                    cols="12"
                    sm="12"
                    lg="12"
                    md="12"
                    v-if="listLogs.length > 0"
                  >
                    <p><b>List Log</b></p>
                    <ul
                      class="list-group"
                      v-for="(li, indexLog) in listLogs"
                      :key="indexLog"
                    >
                      <li class="list-group-item mt-1">
                        {{ li?.request?.message }}
                        {{ li }}
                      </li>
                    </ul>
                  </v-col>
                </v-row>
              </v-form>
            </v-tab-item>
            <v-tab-item>
              <div class="mt-5">
                <v-form ref="formRujukan" v-model="validForm" lazy-validation>
                  <v-text-field
                    label="Tanggal Rujukan Internal"
                    outlined
                    hide-details="auto"
                    @change="rubahTanggal"
                    v-model="tglRujukanInternal"
                    :disabled="readonly"
                    type="date"
                  />
                  <v-select
                    :items="jenisKunjunangan"
                    class="mt-3"
                    :rules="rulesJeniskunjungan"
                    label="Jenis Kunjungan"
                    outlined
                    :disabled="readonly"
                    v-model="jenis"
                  ></v-select>

                  <v-textarea
                    label="Alasan Rujukan"
                    outlined
                    hide-details="auto"
                    :disabled="readonly"
                    v-model="alasanRujukan"
                    :rules="rulesAlasanRujukan"
                  />
                  <div
                    v-if="
                      itemUse.wa && itemUse.inputan_dokter.tanggal_lahir != '-'
                    "
                  >
                    <v-col cols="12" sm="12" md="12">
                      Rujukan Internal Ke RS Syafira : Ke
                      <!-- <b> {{ itemUse.wa ? itemUse.wa.unit_name : "" }}</b> -->
                      <b>
                        {{ itemUse.wa ? itemUse.wa.text : "" }} Pada Tanggal
                        {{
                          itemUse.inputan_dokter.tanggal_lahir
                            | moment("DD/MM/YYYY")
                        }}</b
                      >
                    </v-col>
                  </div>
                  <v-btn
                    color="primary"
                    @click="simpanRujukanInternal"
                    class="mt-1 btn-block"
                    :disabled="readonly"
                    large
                    >DAFTARKAN PASIEN
                  </v-btn>

                  <!-- <div v-if="readonly" class="mt-2">
                    {{ logAntrianOnline }}
                  </div> -->
                </v-form>
              </div>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            v-if="negativeButton"
            color="green darken-1"
            text
            :disabled="disabledNegativeBtn"
            @click="negativeButton"
          >
            Tutup
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showDialogAcc" max-width="450">
      <v-card>
        <v-card-title class="subtitle-1">
          <p>Perhatikan, Jangan Yang Lain Diperhatikan!! FOKUS</p>
        </v-card-title>
        <v-card-text>
          <v-progress-linear
            v-show="progress"
            color="primary"
            indeterminate
            rounded
            height="6"
          />
          <div v-if="!!itemUse.wa && !!itemUse.inputan_dokter.tanggal_lahir">
            <v-row>
              <v-col cols="12" sm="12" md="12">
                Kontrol Ulang Ke RS Syafira : Ke
                {{ itemUse.wa ? itemUse.wa.unit_name : "" }}
                <b>
                  {{ itemUse.wa ? itemUse.wa.text : "" }} Pada Tanggal
                  {{
                    itemUse.inputan_dokter.tanggal_lahir | moment("DD/MM/YYYY")
                  }}</b
                >
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="green darken-1" @click="showDialogAcc = false" text>
            Tidak
          </v-btn>
          <v-btn color="red darken-1" @click="KirimResume" text>
            Ya, Simpan
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import {
  // eslint-disable-next-line no-unused-vars
  apiKedua,
  // eslint-disable-next-line no-unused-vars
  successMsg,
  // eslint-disable-next-line no-unused-vars
  baseUrl,
  // eslint-disable-next-line no-unused-vars
  baseUrlV2,
  // eslint-disable-next-line no-unused-vars
  errorMsg
} from '../plugins/supports'

export default {
  name: 'Dialog',
  props: {
    showDialog: {
      type: Boolean,
      default: false,
      required: false
    },
    persistentDialog: {
      type: Boolean,
      default: true,
      required: false
    },
    positiveBtnLabel: {
      type: String,
      default: 'Ya',
      required: false
    },
    negativeBtnLabel: {
      type: String,
      default: 'Tidak',
      required: false
    },
    positiveButton: {
      type: [Function, Boolean],
      default: () => {},
      required: false
    },
    negativeButton: {
      type: [Function, Boolean],
      default: () => {},
      required: false
    },
    disabledNegativeBtn: {
      type: Boolean,
      default: false,
      required: false
    },
    disabledPositiveBtn: {
      type: Boolean,
      default: false,
      required: false
    },
    title: {
      type: String,
      default: null,
      required: false
    },
    message: {
      type: String,
      default: '',
      required: false
    },

    itemUse: {
      require: false,
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      noHp: '',
      items: [],
      searchDokter: '',
      readonly: false,
      noNik: '',
      tglRujukanInternal: '',
      jenisKunjunangan: [
        {
          id: 'FISIOTERAPI LANJUTAN',
          text: 'FISIOTERAPI LANJUTAN'
        },
        {
          id: 'JAM POLI TELAH BERAKHIR',
          text: 'JAM POLI TELAH BERAKHIR'
        },

        {
          id: 'RUJUKAN INTERNAL',
          text: 'RUJUKAN INTERNAL'
        },
        {
          id: 'RUJUKAN ANTAR RS',
          text: 'RUJUKAN ANTAR RS'
        }
      ],
      valid: true,
      validForm: true,
      isReadonly: false,
      isLoading: false,
      showDialogAcc: false,
      NamaPasien: '',
      alasanRujukan: '',
      selected: ['John'],
      NoRm: '',
      logAntrianOnline: '',
      jenis: '',
      tab: '',
      SEP: '',
      rulesSep: [
        (v) =>
          !!v || 'No.SEP Tidak Boleh Kosong, Pastikan Inputan SEPnya Benar!'
      ],
      rulesJeniskunjungan: [
        (v) => !!v || 'Jenis Kunjungan Tidak Boleh Kosong!'
      ],
      rulesAlasanRujukan: [(v) => !!v || 'Alasan Rujukan Tidak Boleh Kosong!'],

      isSep: false,
      isBridging: false,
      radioGroup: 1,
      row: 'nonBridging',
      pesan:
        'Assalammulaikum, Salam Sejahtera. Berikut Kami Kirim Resume Medis Rawat Jalan',
      progress: false,
      disabled: false,
      pegawai: '',
      listLogs: [],
      url_emr: 'http://emr.rs-syafira.com/'
    }
  },
  created () {
    setTimeout(() => {
      console.log(this.itemUse)
      this.noHp = this.itemUse.demografi.handphone
      this.NamaPasien = this.itemUse.demografi.nama_pasien
      this.NoRm = this.itemUse.demografi.no_rekam_medis
      this.noNik = this.itemUse.demografi.nik
      this.pegawai = JSON.parse(localStorage.getItem('user'))
      // this.listLog()
      // this.getDataLogInternal()
    }, 1000)

    // console.log('DATA DATA =>>> ' + this.itemUse.demografi.handphone)
  },
  mounted () {
    setTimeout(() => {
      this.cariSep()
    }, 1000)
  },
  methods: {
    getDataLogInternal () {
      // eslint-disable-next-line no-undef
      $.get(
        baseUrlV2 +
          'bpjs-v2/get-data-rujukan-internal?reg_id=' +
          this.$route.query.registration_id,
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res

          if (con) {
            this.alasanRujukan = results.alasan_rujukan
            this.tglRujukanInternal = results.tgl_rujukan
            this.jenis = { id: results.jenis, text: results.jenis }
            this.readonly = true
            this.logAntrianOnline = results
            // this.tglRujukanInternal = { id: results.jenis, text: results.jenis }
          } else {
            this.readonly = false
          }
        },
        'JSON'
      )
    },
    showDialogForm () {
      if (this.$refs.form.validate()) {
        // eslint-disable-next-line no-undef
        Swal.fire({
          title: 'Perhatian! Apakah Anda Yakin?',
          text: 'Ingin Menyimpan Surat Kontrol Ini?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ya, Simpan Surat Kontrol',
          cancelButtonText: 'Tidak Jadi Batal',
          html: ''
        }).then((result) => {
          if (result.value) {
            const data = {
              noHp: this.noHp,
              NoRm: this.NoRm,
              nik: this.noNik,
              folio_id: this.$route.query.folio_id,
              registration_id: this.$route.query.registration_id,
              sub_unit_id: this.$route.query.sub_unit_id,
              noSep: this.SEP,
              dataDetail: this.itemUse,
              pegawai: this.pegawai.employee_name
            }
            //   console.log(data)

            // eslint-disable-next-line no-undef
            $.post(
              baseUrlV2 + 'bpjs-v2/insert-v2',
              data,
              (response) => {
                // eslint-disable-next-line no-unused-vars
                const { con, msg, results } = response
                if (con) {
                  this.progress = false
                  successMsg(msg)
                  this.listLog()
                } else {
                  this.progress = false
                  this.listLog()
                  errorMsg(msg)
                }
                this.showDialogAcc = false

                //   console.log(response)
              },
              'JSON'
            )
            // resetBtnLoading(btn, html)
          } else {
            // resetBtnLoading(btn, html)
            errorMsg('Nggak Jadi Deh !!!')
          }
        })
      }
    },
    rubahTanggal () {
      // console.log(this.itemUse.inputan_dokter.tanggal_lahir)
      // console.log(this.tglRujukanInternal)
      this.itemUse.inputan_dokter.tanggal_lahir = this.tglRujukanInternal
    },
    KirimResume () {
      this.progress = true
      //   this.showDialogAcc = true
      // eslint-disable-next-line no-unused-vars
    },
    sendWaKePasien () {
      this.disabled = true
      // eslint-disable-next-line no-unused-vars
      //   const handphone = '085215664033'
      const handphone = this.noHp
      // eslint-disable-next-line no-unused-vars
      const employeeid = this.pegawai.employee_id
      // eslint-disable-next-line no-unused-vars
      const url =
        this.url_emr +
        'cetak-with-pdf-emr-other/cetak-resume-medis-base64-contoh?view=base64_pdf&reg_id=' +
        this.$route.query.registration_id +
        '&folio_id=' +
        this.$route.query.folio_id
      // eslint-disable-next-line no-unused-vars
      const result = window.btoa(url)

      const params = {
        employee_id: employeeid,
        no_wa: handphone,
        pesan: this.pesan,
        url: result
      }
      const query = new URLSearchParams(params).toString()

      const fixurl =
        baseUrl +
        `cronjob-background-printing/send-wa-medis-by-employee-mapping?${query}`
      console.log(fixurl)
      // eslint-disable-next-line no-undef
      $.get(
        fixurl,
        (response) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = response
          if (con) {
            this.progress = false
            this.disabled = false
            successMsg(msg)
            this.listLog()
          } else {
            this.disabled = false

            this.progress = false

            errorMsg(msg)
          }
          this.showDialogAcc = false
        },
        'JSON'
      )
    },
    listLog () {
      // eslint-disable-next-line no-undef
      $.get(
        baseUrlV2 +
          'bpjs-v2/list-log?reg_id=' +
          this.$route.query.registration_id,
        (response) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = response
          if (con) {
            this.listLogs = results
          } else {
            this.listLogs = []
          }
        },
        'JSON'
      )
    },
    cariSep () {
      // eslint-disable-next-line no-undef
      $.get(
        baseUrlV2 + 'bpjs-v2/list-sep?no_rm=' + this.NoRm,
        (response) => {
          const { con, results } = response
          if (con) {
            this.items = results
          } else {
            console.log('DATA TIDAK DITEMUKAN')
          }
        },
        'JSON'
      )
      this.$forceUpdate()
    },
    simpanRujukanInternal () {
      console.log(this.itemUse)
      // eslint-disable-next-line no-unused-vars
      const subUnitId = this.$route.query.sub_unit_id
      // eslint-disable-next-line no-unused-vars
      const itemUse = this.itemUse.wa
      const jenis = this.jenis
      // eslint-disable-next-line no-unused-vars, camelcase
      const no_rekam_medis = this.itemUse.demografi.no_rekam_medis
      // eslint-disable-next-line camelcase, no-unused-vars
      const tgl_kontrol = this.itemUse.inputan_dokter.tanggal_lahir
      // eslint-disable-next-line camelcase, no-unused-vars
      const registration_id = this.$route.query.registration_id

      // eslint-disable-next-line no-unused-vars
      const url = {
        subUnitId: subUnitId,
        itemUse: itemUse,
        tgl_kontrol: tgl_kontrol,
        jenis: jenis,
        no_rekam_medis: no_rekam_medis,
        registration_id: registration_id,
        alasanRujukan: this.alasanRujukan,
        employee_name: this.pegawai.employee_name
      }
      if (this.$refs.formRujukan.validate()) {
        // eslint-disable-next-line no-undef
        Swal.fire({
          title: 'Perhatian! Apakah Anda Yakin?',
          text: 'Ingin Menyimpan Surat Rujukan Internal Ini?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ya, Simpan Rujukan Internal',
          cancelButtonText: 'Tidak Jadi Batal',
          html: ''
        }).then((result) => {
          if (result.value) {
            // eslint-disable-next-line no-undef
            $.post(
              baseUrlV2 + 'bpjs-v2/simpan-rujukan-internal',
              url,
              (res) => {
                // eslint-disable-next-line no-unused-vars
                const { con, msg, results } = res

                if (con) {
                  successMsg(msg)
                  this.getDataLogInternal()
                } else {
                  errorMsg(msg)
                }
              },
              'JSON'
            )
            // resetBtnLoading(btn, html)
          } else {
            // resetBtnLoading(btn, html)
            errorMsg('Nggak Jadi Deh !!!')
          }
        })
      }

      // if (this.itemUse.wa.id === subUnitId) {
      //   successMsg('Poliklinik Tujuan Sama Tidak Bisa ')
      // }
      // console.log(subUnitId)
    }
  },
  watch: {}
}
</script>

<style scoped></style>
