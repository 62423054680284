// eslint-disable-next-line no-unused-vars
const data = [
  'Sesudah Makan',
  'Sesudah Makan Pagi dan Malam',
  'Sesudah Makan Pagi, Siang dan Malam',
  'Sesudah Makan Jam 7.00 dan 19.00',
  'Sesudah Makan Jam 7.00 , 15.00, 23.00',
  'Sesudah Makan Jam 7.00 ,13.00,01.00 ',
  'Sebelum Makan Jam 7.00 dan 19.00 ',
  'Sebelum Makan Jam 7.00 , 15.00, 23.00',
  'Sebelum Makan Jam 7.00 ,13.00,01.00',
  'Sebelum Makan',
  'Sebelum Makan Pagi dan Malam',
  'Sebelum Makan Pagi, Siang dan Malam',
  'Jam 7.00 dan 19.00',
  'Jam 7.00 , 15.00, 23.00',
  'Jam 7.00 ,13.00,01.00',
  'Pagi',
  'Siang',
  'Malam',
  'Kedua Mata',
  'Mata Yang Sakit',
  'Mata Kiri',
  'Mata Kanan',
  'Kedua Lubang Hidung',
  'Lubang Hidung Kiri',
  'Lubang Hidung Kanan',
  'Kedua Lubang Telinga',
  'Telinga Kiri',
  'Telinga Kanan',
  'IV',
  'Melalui Vagina',
  'Dioleskan Tipis',
  'Hisap',
  'OK',
  'Jam 7.00 dan 19.00 di Minum Sampai Habis',
  'Jam 7.00 , 15.00, 23.00 di Minum Sampai Habis',
  'Sesudah Makan Pagii',
  'Sesudah Makan malam'
]

export default data
